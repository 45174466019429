
import React from 'react'

function Dropdown({ visible, children, parent, isResolved, isRejected }) {
  return (
    <div>
      <div role="presentation">{parent}</div>
      <div
        className="contains"
        style={{
          backgroundColor: '#fff',
          width: '40.5%',
          position: 'fixed',
          height: !isResolved && !isRejected ? '300px' : '60px',
          borderRadius: '10px',
          boxShadow:
            '0 1px 2px rgba(0, 0, 0, 0.002), 0 1px 1px rgba(0, 0, 0, 0.14)',
          display: visible ? null : 'none',
          top: '68px',
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Dropdown
