import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useRole from '../hooks/useRole'
import routes from '../routes/route_contants'
import Loading from './Loading'
import colors from '../config/colors'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: `url(${BackgroundImg})`,
    backgroundColor: colors.primary,
    backgroundRepeat: 'no-repeat',
    // backgroundColor:
    //   theme.palette.type === 'light'
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100px',
  },
}))

function SignInSide() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
  const { role } = useRole()
  const history = useHistory()
  const classes = useStyles()
  useEffect(() => {
    if (isAuthenticated) {
      if (role?.superuser) history.push(routes.INVITATION)
      if (role?.admin) history.push(routes.TEAM_MEMBERS_LIST)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      if (role?.superuser) history.push(routes.INVITATION)
      if (role?.admin) history.push(routes.TEAM_MEMBERS_LIST)
    }
  }, [isAuthenticated])

  if (isLoading) return <Loading fullScreen />

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <div className={classes.content}>
        <div className={classes.paper}>
          <img
            src={`${process.env.PUBLIC_URL}/images/bg.png`}
            alt="CargoCheck"
          ></img>
          {/* <Typography component="h1" variant="h5">
            CargoCheck
          </Typography> */}
          <form className={classes.form} noValidate>
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              color="primary"
              // style={{ color: 'white' }}
              className={classes.submit}
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
          </form>
        </div>
      </div>
    </Grid>
  )
}

export default SignInSide
