import { useEffect, useState } from "react";
import Validation from "../validations/Validation";

/**
 *
 * @param {object} init initial form field values
 * @param {object} validationSchema Validation Schema
 * @param {function} callback Submit handler function, receives values after submission
 */
const useForm = (init, validationSchema, callback) => {
  const [values, setValues] = useState(init)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values)
      setIsSubmitting(false)
    }
  
  }, [errors, isSubmitting])

  const handleOnChange = ({ name, value }) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors(Validation.validate(values, validationSchema))
    setIsSubmitting(true)
  }

  const validate = () => {
    const err = Validation.validate(values, validationSchema)
    setErrors(err)
    return err
  }

  const resetForm = () => {
    setValues(init)
  }

  return {
    values,
    errors,
    setErrors,
    validate,
    setValues,
    isSubmitting,
    resetForm,
    handleSubmit,
    handleOnChange,
  }
}

export default useForm
