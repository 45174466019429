import { Box, MenuItem, Select, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { PaletteColors } from '../../../config/colors'
import { deafultFunc, defaultArray, defaultString } from '../../../theme/config'

function SelectInput({
  value,
  onChange,
  label,
  noLabel,
  options,
  emptyLabel,
  error,
  SelectComponent,
  hideEmptyLabel,
  type,
  ...rest
}) {
  const handleChange = e => {
    onChange(e.target.value)
  }
  return (
    <Box {...rest} className="input-container">
      {!noLabel && <Box className="input-label">{label}</Box>}
      <SelectComponent
        {...rest}
        fullWidth
        label={label}
        value={value}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
      >
        {!hideEmptyLabel && options.length !== 0 && (
          <MenuItem value="" style={{ color: PaletteColors.textMedium }}>
            {emptyLabel || 'None'}
          </MenuItem>
        )}
        {options.length === 0 && type === 'contact' && (
          <MenuItem value="" style={{ color: PaletteColors.textMedium }}>
            No Operational Contacts defined yet, please add some
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label || option.value}
          </MenuItem>
        ))}
      </SelectComponent>
      {error && <div className="input-error">{error}</div>}
    </Box>
  )
}

SelectInput.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  SelectComponent: PropTypes.node,
  noLabel: PropTypes.bool,
}

SelectInput.defaultProps = {
  value: '',
  options: defaultArray,
  onChange: deafultFunc,
  label: defaultString,
  noLabel: false,
  SelectComponent: Select,
}

export default SelectInput
