import { fieldVisible, IAutoComplete, IText } from './utils'
import chemicalAPI from '../../../../api/chemical'
import productAPI from '../../../../api/product'
import contactsAPI from '../../../../api/contacts'
import locationAPI from '../../../../api/location'

function convertGrade(grade) {
  return grade
    ? grade
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`)
    : undefined
}

const step1 = [
  {
    key: 'Product information',
    visible: fieldVisible,
    children: [
      new IAutoComplete(
        'chemical',
        'Universal Product',
        '',
        ['required'],
        {
          src: chemicalAPI.newList,
          params: {},
        },
        option =>
          `${`${option.unNumber} - ${option.name}`} ${
            option.grade
              ? ` (${convertGrade(option.grade)}${
                  option.concentration ? `, ${option.concentration}%)` : ')'
                }`
              : option.concentration
              ? ` (${option.concentration}%)`
              : ''
          }`,
        'Product'
      ),
    ],
  },
  {
    key: 'Specific products',
    visible: fieldVisible,
    children: [
      new IAutoComplete(
        'products',
        'Company product',
        [],
        ['required'],
        {
          src: productAPI.list,
          params: {},
        },
        option =>
          option.unNumber
            ? `${option.unNumber} - ${option.name}`
            : `${option.name}`,
        'Product',
        true,
        false,
        false
      ),
    ],
  },
  {
    key: 'Location information',
    visible: fieldVisible,
    children: [
      new IAutoComplete(
        'location',
        'Facility location',
        '',
        ['required'],
        {
          src: locationAPI.list,
          params: {
            limit: 20,
            location_type: 'tenant_location',
          },
        },
        option => `${option.location_name}`,
        'Facility Location'
      ),
    ],
  },
  {
    key: 'Facility details',
    visible: fieldVisible,
    children: [
      new IText(
        'facilityPhoneNoForDriverAssistance',
        'Facility phone no. for driver assistance',
        '',
        ['required', 'min:1', 'max:12', 'dashedNumber']
      ),
    ],
  },
  {
    key: 'Primary Contact',
    visible: fieldVisible,
    children: [
      new IAutoComplete(
        'primaryContact',
        'Link Primary Contact',
        '',
        [],
        {
          src: contactsAPI.getContacts,
          params: {},
        },
        option => `${option?.name} ${option?.surname}`,
        'Contact'
      ),
    ],
  },
  // {
  //   key: 'SDS link',
  //   visible: () => false,
  //   children: [new IText('link_to_sds', 'Link to SDS', '')],
  // },
  {
    key: 'Loading and Unloading',
    visible: fieldVisible,
    children: [
      {
        type: 'check',
        initial: true,
        label: 'Loading tank',
        name: 'loadingTank',
      },
      {
        type: 'check',
        initial: false,
        label: 'Unloading tank',
        name: 'unloadingTank',
        showLabel: false,
      },
      {
        type: 'check',
        initial: true,
        label: 'Is Public',
        name: 'public',
        showLabel: false,
      },
    ],
  },
]

export default step1
