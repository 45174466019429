import { useState, useEffect } from 'react'

export default apiFunc => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [status, setStatus] = useState('idle')
  const [isPending, setIsPending] = useState(false)
  const [isResolved, setIsResolved] = useState(false)
  const [isRejected, setIsRejected] = useState(false)

  useEffect(() => {
    setIsPending(status === 'pending')
    setIsResolved(status === 'resolved')
    setIsRejected(status === 'rejected')
  }, [status])

  const request = async (...args) => {
    setStatus('pending')
    const response = await apiFunc(...args)

    if (!response?.ok) {
      setError(response?.data?.message)
      setStatus('rejected')
    }
    if (response?.ok) setStatus('resolved')
    setData(response?.data)
    return response
  }

  const reset = () => {
    setStatus('idle')
    setData(null)
    setError(null)
  }

  return {
    data,
    setData,
    error,
    status,
    request,
    reset,
    isPending,
    isResolved,
    isRejected,
  }
}
