import React from 'react'
import { fields } from '../../helpers/fields'
export default function FielsUpdating({ type }) {
  return (
    <div className="fieldParent">
      <br />
      <h1 className="text-secondary-gray">FIELDS UPDATING</h1>
      <br />
      <div className="fieldsWrapper">
        {fields[type].map((node, index) => (
          <div key={index}>
            {node.key}
            <hr style={{ margin: '2px 0px' }} />
            {node.value}
          </div>
        ))}
      </div>
    </div>
  )
}
