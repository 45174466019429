


import { Tab, Tabs, makeStyles, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import colors, { PaletteColors } from '../../../config/colors'
import step1 from './steps/step1'
import step2 from './steps/step2'
import step3 from './steps/step3'
import step4 from './steps/step4'
import step5 from './steps/step5'
import step6 from './steps/step6'

export const steps = [
  { label: 'Tank Details', data: step1 },
  { label: 'Storage Product Details', data: step2 },
  { label: 'Safety Details', data: step3 },
  { label: 'Tank Trailer Details - 1', data: step4 },
  { label: 'Tank Trailer Details - 2', data: step5 },
  { label: 'Special Instructions', data: step6 },
]

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 15,
    // background: PaletteColors.textMedium,
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
    // '&$selected': {
    //   color: PaletteColors.secondaryMain
    // },
  },
}))

const StepComponent = ({ index, label, error }) => (
  <Box display="flex" color={error && colors.danger}>
    <Box mr={2}>{index + 1}</Box> {label} {error && '*'}
  </Box>
)

function Steps({ onChange, current, errorSteps }) {
  const [currentStep, setCurrentStep] = useState(current)
  const classes = useStyles()
  useEffect(() => {
    onChange(currentStep)
  }, [currentStep])

  useEffect(() => {
    setCurrentStep(current)
  }, [current])

  return (
    <Tabs
      orientation="vertical"
      TabIndicatorProps={{ style: { display: 'none' } }}
      value={currentStep}
      onChange={(e, v) => setCurrentStep(v)}
    >
      {steps.map((tab, index) => (
        <Tab
          className={classes.tabs}
          wrapped={false}
          key={tab.label}
          label={
            <StepComponent
              isCurrentStep={currentStep === index}
              error={errorSteps[index]}
              index={index}
              label={tab.label}
            />
          }
        />
      ))}
    </Tabs>
  )
}

Steps.propTypes = {
  onChange: PropTypes.func,
}

Steps.defaultProps = {
  onChange: () => {},
}

export default Steps
