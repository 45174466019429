import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import routes from './route_contants'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      return history.push(routes.WELCOME)
    }
  }, [isAuthenticated, isLoading])

  return <Route {...rest} exact component={Component} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
}

ProtectedRoute.defaultProps = {
  location: {},
}

export default withAuthenticationRequired(ProtectedRoute)
