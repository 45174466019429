import themeColorsDefault from '../theme/themeColors.scss'

export default {
  primary: '#003366',
  secondary: '#3298ff',
  background: '#FFFFFF',
  black: '#000',
  white: '#fff',
  medium: '#636463',
  light: '#f8f4f4',
  dark: '#0c0c0c',
  danger: '#ff5252',
  cyan: '#66CCFF',
  grey: '#a6a6a6',
  orange: '#FF8637',
  success: '#A4CC2A',
}

export const PaletteColors = themeColorsDefault
