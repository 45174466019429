import React from "react";
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, Tab, Tabs } from '@material-ui/core'
import TabPanel from '../Reusable/TabPanel'
import Questions from './CreateChecklist/Questions'

const tabs = [
  {
    label: 'Questions',
    hash: 'questions',
  },
]

function flattenQuestions(data, version = '') {
  return _.flatMap(data, (item, index) => {
    const currentVersion = version + (index + 1)

    if (item.subQuestions.length === 0) {
      const newItem = _.omit(item, 'subQuestions')
      newItem.version = currentVersion
      return [newItem]
    } else {
      const subQuestions = flattenQuestions(
        item.subQuestions,
        `${currentVersion}.`
      )
      const newSubQuestions = subQuestions.map((subQuestion, subIndex) => {
        subQuestion.version = `${currentVersion}.${subIndex + 1}`
        return subQuestion
      })
      const newItem = _.omit(item, 'subQuestions')
      newItem.version = currentVersion
      return [newItem, ...newSubQuestions]
    }
  })
}

function QuestionAnswers({ methods, viewMode, questions }) {
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const flattenedData = flattenQuestions(questions)
  return (
    <>
      <Box mt={2}>
        <Tabs value={value} onChange={handleChange} indicatorColor="white">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              style={
                index === value
                  ? {
                      color: 'white',
                      backgroundColor: '#3298FF',
                      height: '100%',
                      borderRadius: '30px',
                    }
                  : {}
              }
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} overflow={'unset'}>
        <Questions methods={methods} viewMode={viewMode} />
      </TabPanel>
    </>
  )
}

QuestionAnswers.propTypes = {
  methods: PropTypes.object,
}

QuestionAnswers.defaultProps = {}

export default QuestionAnswers
