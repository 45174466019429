import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function TabPanel(props) {
  const { children, value, index, overflow } = props
  if (value !== index) {
    return null
  }

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        overflow: overflow || 'auto',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
}

export default TabPanel
