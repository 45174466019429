import { Box, Button, Tab, Tabs } from '@material-ui/core'
import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Container from '../Reusable/Container'
import SearchBar from '../Reusable/Widgets/SearchBar'
import Fixed from '../Reusable/Fixed'
import Tanks from './Tanks'
import Products from '../MyAccount/Products'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TabPanel from '../Reusable/TabPanel'
import routes from '../../routes/route_contants'
import { downloadZip } from '../../helpers/downloadZip'
import generateQrCode from '../../helpers/generateQRCode'
import { getLabel } from '../../helpers/downloadTankLabel'
import tankAPI from '../../api/tank'
import withPaginationLocal from '../../HOC/withPaginationLocal'
import productAPI from '../../api/product'

const TABS = [
  { label: 'Tanks', Component: Tanks, hash: '#tanks' },
  { label: 'Products', Component: Products, hash: '#products' },
]

function TankList() {
  const [currentTab, setCurrentTab] = useState(0)
  const [searchStr, setSearchStr] = useState(undefined)
  const history = useHistory()
  const buttonRef = useRef(null)
  const downloadableTanks = useRef([])

  const PaginatedTank = withPaginationLocal(tankAPI.list, {
    search: searchStr || '',
    limit: 12,
    tankType: '',
  })(Tanks)

  const PaginatedProducts = withPaginationLocal(productAPI.list, {
    search: searchStr || '',
    limit: 12,
  })(Products)

  const handleSearch = val => {
    if (val?.length >= 3) {
      setSearchStr(val)
    } else {
      if (val?.length === 0) {
        setSearchStr(undefined)
      }
    }
  }

  const handleRedirect = () => {
    history.push(currentTab === 0 ? routes.ADD_TANK : routes.PRODUCT_ADD)
  }

  const getTankLabel = (tank, templateNo) => {
    return new Promise((resolve, reject) => {
      const qrdetails = {
        tank_registration_no: tank.tankRegistrationNo,
        customer_no: tank.customer ? tank.customer.customer_no : undefined,
        // product_id: tank?.chemical?.chem_un || undefined,
      }
      generateQrCode(JSON.stringify(qrdetails)).then(res => {
        getLabel(res.outerHTML, tank, templateNo).then(base64 => {
          resolve({ tank, image: base64 })
        })
      })
    })
  }

  const generateQrCodeHandler = templateNo => {
    Promise.all(
      downloadableTanks.current.map(tank => getTankLabel(tank, templateNo))
    ).then(tankLabels => {
      const filesToBeDownloaded = tankLabels.map(({ tank, image }) => {
        return {
          name: `${tank?.tankRegistrationNo}-${
            tank.chemical ? tank.chemical.chem_un : tank?.products[0]?.unNumber
          } - ${
            tank.chemical ? tank.chemical.description : tank?.products[0]?.name
          }.png`,
          data: image.split(',')[1],
        }
      })
      downloadZip(filesToBeDownloaded, { folder: 'labels' })
    })
  }

  const handleSelectedRows = rows => {
    if (rows.length === 0) {
      buttonRef.current.classList.add('hidden')
    } else {
      buttonRef.current.classList.remove('hidden')
      downloadableTanks.current = rows
    }
  }

  useEffect(() => {
    const currentTab = TABS.findIndex(e => e.hash === window.location.hash)
    if (currentTab !== -1) setCurrentTab(currentTab)
  }, [window.location.hash])

  useEffect(() => {
    const currentTab = TABS.findIndex(e =>
      window.location.hash.startsWith(e.hash)
    )
    if (currentTab !== -1) setCurrentTab(currentTab)
  }, [])

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Tanks & Products" />
          <Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => generateQrCodeHandler(2)}
              ref={buttonRef}
              style={{ marginRight: '10px' }}
            >
              Download QR
            </Button>
            <Button
              onClick={handleRedirect}
              variant="contained"
              color="primary"
            >
              Add {TABS[currentTab].label.slice(0, -1)}
            </Button>
          </Box>
        </PageHeading>
        <Box mt={2}>
          <PageHeading alignItems="center">
            <Tabs
              value={currentTab}
              onChange={(e, v) => setCurrentTab(v)}
              indicatorColor="white"
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  onClick={() => history.push(tab.hash)}
                  style={
                    index === currentTab
                      ? {
                          color: 'white',
                          backgroundColor: '#3298FF',
                          height: '100%',
                          borderRadius: '30px',
                        }
                      : {}
                  }
                />
              ))}
            </Tabs>
            <Box display="flex">
              <SearchBar onChange={handleSearch} />
            </Box>
          </PageHeading>
        </Box>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <TabPanel value={currentTab} index={0}>
          <PaginatedTank
            selectable
            onSelectRows={handleSelectedRows}
            type="customer"
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <PaginatedProducts searchStr={searchStr} />
        </TabPanel>
      </Scrollable>
    </Container>
  )
}

export default TankList
