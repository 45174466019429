

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useForm as useReactForm } from 'react-hook-form'
import QuestionAnswers from '../CheckList/QuestionAnswers'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import useAPILocal from '../../hooks/useAPILocal'
import checklistAPI from '../../api/checklist'
import LoadingIndicator from '../Order/Tabs/Loading'
import { PaletteColors } from '../../config/colors'
import Container from '../Reusable/Container'
import routes from '../../routes/route_contants'

const fields = [
  { label: 'Name', value: e => e?.name },
  {
    label: 'States',
    value: e =>
      e?.states
        ?.map(item => item.replaceAll('_', ' '))
        ?.map(item => _.capitalize(item))
        ?.join(', '),
  },
  {
    label: 'Assigned To',
    value: e => e?.roles?.join(', '),
  },

  {
    label: 'For',
    value: e => e?.owner?.tenant_name,
  },
]
const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 14,
            color: PaletteColors.textMedium,
            wordBreak: 'break-all',
          }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function ChecklistPage() {
  const history = useHistory()
  const path = useParams()
  const [checklist, setChecklist] = useState()
  const [open, setOpen] = React.useState(false)
  const singleChecklist = useAPILocal(checklistAPI.singleChecklist)
  const deleteChecklist = useAPILocal(checklistAPI.deleteChecklist)

  const handleRedirect = () => {
    history.push({
      pathname: routes.CREATE_CHECKLIST,
      state: path.checklist_id,
    })
  }

  const sendPathString = arr => {
    const path =
      'questions' +
      arr.reduce((prev, curr, currentIndex) => {
        prev = _.cloneDeep(prev)
        prev += currentIndex === 0 ? `[${curr}]` : `.subQuestions[${curr}]`
        return prev
      }, '')
    return path
  }

  const renderCheckList = ({ index, indexArr, questions, reverse }) => {
    const pathString = sendPathString([...indexArr, index])
    const index_ = index
    if (_.get(questions, `${pathString}.answerType`) === 'YES_NO') {
      if (reverse) _.set(questions, `${pathString}.answers`, ['YES', 'NO'])
      else _.set(questions, `${pathString}.answers`, [])
    }

    if (_.get(questions, `${pathString}.subQuestions`).length > 0)
      renderCheckList({
        index: 0,
        indexArr: [...indexArr, index_],
        questions,
        reverse,
      })
  }
  const methods = useReactForm({
    defaultValues: {
      questions: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    singleChecklist.request(path.checklist_id).then(res => {
      setChecklist(res?.data)
      const question = { questions: _.cloneDeep(res?.data?.questions) }
      question.questions.forEach((item, index) => {
        renderCheckList({
          index,
          indexArr: [],
          questions: question,
          reverse: true,
        })
      })

      methods.reset(question)
    })
  }, [])

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title={checklist?.name} />
          <div>
            <Button
              variant="outlined"
              color="primary"
              style={{ color: 'red', borderColor: 'red', marginRight: '10px' }}
              onClick={() => setOpen(true)}
            >
              Delete
            </Button>
            {open && (
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to delete this checklist ?
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      deleteChecklist.request(path.checklist_id).then(() => {
                        history.push('/checklist')
                      })
                    }}
                    color="primary"
                  >
                    {deleteChecklist.isPending ? <LoadingIndicator /> : 'Yes'}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <Button variant="outlined" color="primary" onClick={handleRedirect}>
              Edit Checklist
            </Button>
          </div>
        </PageHeading>
      </Fixed>
      <Scrollable style={{ height: '100%' }} p={3}>
        <Box
          className="shipments-list"
          style={{
            paddingLeft: 30,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="tankbox">
            <div className="tankboxin">
              {singleChecklist.isPending ? (
                <LoadingIndicator m={4} />
              ) : (
                fields.map(field => (
                  <Box key={field.label}>
                    <Item
                      label={field.label}
                      value={field.value(singleChecklist.data)}
                    />
                  </Box>
                ))
              )}
            </div>
          </div>
        </Box>
        <QuestionAnswers
          methods={methods}
          viewMode
          questions={singleChecklist?.data?.questions}
        />
      </Scrollable>
    </Container>
  )
}

export default ChecklistPage
