import themeColors from './themeColors.scss'
import config from './config'

const componentsOverrides = () => {
  return {
    MuiCard: {
      root: {
        boxShadow: 'none',
        border: '1px solid rgba(188, 188, 188, 1)',
      },
    },

    MuiButton: {
      root: {
        textTransform: 'capitalize',
        borderRadius: '27px',
      },
    },

    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
          '&:hover': {
            // backgroundColor: `#EEF2F7`,
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: `#EEF2F7`,
        },
      },
    },

    MuiSelect: {
      root: {
        // border: `1px solid ${themeColors.borderColor1}`,
        backgroundColor: '#EEF2F7',
        transition: '.5s',
        padding: 10,
        '&:hover': {
          backgroundColor: themeColors.backgroundSecondary4,
        },
      },
      select: {
        borderRadius: '9px',
        '&:focus': {
          // backgroundColor: themeColors.backgroundSecondary4,
          // boxShadow: `0 0 0 1px ${themeColors.secondaryMain}`,
          borderRadius: '9px',
        },
      },
    },

    MuiTextField: {
      root: {
        border: `1px solid ${themeColors.borderColor1}`,
        backgroundColor: 'white',
        transition: '.5s',
        borderRadius: 9,
        padding: '4px 13px 4px 13px',
        '& input': {
          fontSize: 14,
        },
        '&:focus-within': {
          transition: '0.3s',
          boxShadow: `0 0 0 1px ${themeColors.secondaryMain}`,
        },
        '& .MuiInput-underline::after, .MuiInput-underline::before': {
          content: 'none',
        },
      },
    },

    MuiTabs: {
      root: {
        minHeight: 0,
      },
    },
    MuiTab: {
      root: {
        minHeight: 0,
        minWidth: 0,
        textTransform: 'capitalize',
        color: themeColors.secondaryMain,
        padding: '8px 15px 8px 15px',
        '&$selected': {
          color: themeColors.secondaryMain,
          fontWeight: 'bold',
        },
        '&:hover': {
          // boxShadow: `inset 0 0 2px ${themeColors.secondaryMain}`,
          color: themeColors.secondaryMain,
          fontWeight: 'bold',
          borderRadius: config.borderRadius,
          transition: '0.1s',
        },
      },
    },
  }
}

export default componentsOverrides
