import React from 'react'
import { styled, Tab, Tabs } from '@material-ui/core'
import colors from '../../config/colors'

export const SecondaryTabs = ({ children, value, onChange, ...props }) => {
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          background: colors.primary,
          height: '3px',
        },
      }}
      value={value}
      onChange={onChange}
      {...props}
    >
      {children}
    </Tabs>
  )
}

export const SecondaryTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    minWidth: 0,
    color: colors.primary,
    '&.Mui-selected': {
      color: colors.primary,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  })
)
