import {
  defaultCheckboxLabel,
  fieldVisible,
  IText,
  Measurement,
} from './utils'

export const safetyAnswers = [
  { label: 'Not specified', value: null },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const step3 = [
  Measurement(
    'unloadingToShowerDistance',
    'Offloading point’s distance from safety shower',
    'unloadingToShowerDistanceUnit',
    'diameter'
  ),
  {
    key: 'Level Indicator',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label: 'Is level indicator visible to driver ?',
        name: 'levelIndicator',
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'Offloading pipe labelling',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label: 'Is the offloading pipe labelled with chemical name or UN #',
        name: 'offloadingPipeLabeledWithChemicalUn',
        showLabel: defaultCheckboxLabel,
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'shower visible',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label: 'Safety shower visible from offloading',
        name: 'showerVisibleFromOffloading',
        showLabel: defaultCheckboxLabel,
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'path to safety shower',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label: 'Path to safety shower is free of obstructions',
        name: 'pathToShowerFreeFromObstruction',
        showLabel: defaultCheckboxLabel,
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'standard ppe',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label:
          'Standard PPE (splash suit, boots, gloves, goggles, hard hat, face shield)',
        name: 'standardPpe',
        showLabel: defaultCheckboxLabel,
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'tank has shared containment',
    visible: fieldVisible,
    children: [
      {
        type: 'select',
        initial: null,
        label: 'Tank has shared containment?',
        name: 'tankHasSharedContainment',
        showLabel: defaultCheckboxLabel,
        options: safetyAnswers,
        hideEmptyLabel: true,
      },
    ],
  },
  {
    key: 'special ppe',
    visible: fieldVisible,
    children: [new IText('specialPpe', 'Special PPE', '')],
  },
]

export default step3
