import { Box, Button, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import OrderSummary from './Tabs/OrderSummary'
import Products from './Tabs/Products'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import PageTitle from '../Reusable/PageTitle'
import PageHeading from '../Reusable/PageHeading'
import routes from '../../routes/route_contants'
import TabPanel from '../Reusable/TabPanel'
import bolAPI from '../../api/bol'
import useAPILocal from '../../hooks/useAPILocal'
import Documents from './Tabs/Documents'
import LoadingIndicator from './Tabs/Loading'
import PhotosComments from './Tabs/PhotosComments'

const tabs = [
  {
    label: 'Details',
    hash: '#details',
  },
  {
    label: 'Products',
    hash: '#products',
  },
  {
    label: 'Photos & Comments',
    hash: '#comments!photos',
  },
  // {
  //   label: 'Scale and Fee',
  //   hash: '#fee',
  // },
  {
    label: 'Documents',
    hash: '#documents',
  },
]

function OrderDetails(props) {
  const params = useParams()
  const [value, setValue] = React.useState(0)
  const [orderItems, setOrderItems] = React.useState(null)
  const orderDetail = useAPILocal(bolAPI.bolDetails)
  const bolItemsAPI = useAPILocal(bolAPI.listBolItems)
  const getDocuments = useAPILocal(bolAPI.getDocuments)
  const [order, setOrder] = React.useState(null)
  const [docsData, setDocsData] = useState()

  const history = useHistory()

  useEffect(() => {
    const currentTab = tabs.findIndex(e => e.hash === window.location.hash)
    if (currentTab !== -1) setValue(currentTab)
  }, [window.location.hash])

  const fetchOrderDetails = async onlyOrders => {
    if (!onlyOrders) {
      await orderDetail.request(params.order_id)
    }
    bolItemsAPI
      .request(params.order_id)
      .then(res => setOrderItems(res.ok ? res.data.results : []))
  }
  useEffect(() => {
    setOrder(() => orderDetail?.data)
  }, [orderDetail?.data])

  // let url
  useEffect(() => {
    fetchOrderDetails()
    const currentTab = tabs.findIndex(e =>
      window.location.hash.startsWith(e.hash)
    )
    if (currentTab !== -1) setValue(currentTab)
    getDocuments.request(params.order_id).then(res => {
      setDocsData(res?.data)
    })
  }, [params.order_id])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onUpdate = () => {
    setTimeout(() => {
      fetchOrderDetails(true)
    }, 1420)
  }

  const uploadImageHandler = () => {
    history.push(`${routes.ADD_ORDER_PHOTOS}/photos/add/${params.order_id}/0`)
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle
            title="Order Details"
            backButton={() =>
              history.push(props?.location?.state?.from || routes.ORDER_LIST)
            }
          />
          {value === 2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={uploadImageHandler}
            >
              Add Photos & Comments
            </Button>
          )}
        </PageHeading>
        <Box mt={2}>
          <Tabs value={value} onChange={handleChange} indicatorColor="white">
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                onClick={() => history.push(tab.hash)}
                label={tab.label}
                style={
                  index === value
                    ? {
                        color: 'white',
                        backgroundColor: '#3298FF',
                        height: '100%',
                        borderRadius: '30px',
                      }
                    : {}
                }
              />
            ))}
          </Tabs>
        </Box>
      </Fixed>
      <Scrollable mt={2}>
        <TabPanel value={value} index={0}>
          <OrderSummary order={order} loading={!order} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Products
            order={order}
            loading={!orderItems}
            items={orderItems || []}
            onUpdate={onUpdate}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PhotosComments order={order} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Documents orderId={params.order_id} />
        </TabPanel>
      </Scrollable>
    </Container>
  )
}

export default OrderDetails
