import client from '../../hooks/useClient'

const countriesAPI = {}

countriesAPI.getCountries = async data => {
  return client.get('/countries', data)
}

countriesAPI.getStatesByCountry = async countryId => {
  return client.get(`/countries/${countryId}/states`)
}

export default countriesAPI
