import React from 'react'

import TextInput, { TextInputPropTypes } from './TextInput'

function DateInput(props) {
  return (
    <TextInput
      flex="1"
      {...props}
      type="date"
      inputProps={{ pattern: '\\d{4}-\\d{2}-\\d{2}' }}
    />
  )
}

DateInput.propTypes = TextInputPropTypes

DateInput.defaultProps = {
  ...TextInput.defaultProps,
  //   type: 'date',
}

export default DateInput
