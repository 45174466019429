import React from 'react'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'

const NotFound = () => {
  const history = useHistory()
  return (
    <>
      <title>404 | Tank2Tank</title>
      <Container
        maxWidth="md"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography align="center" color="textPrimary" variant="h1">
          404
        </Typography>
        <Typography variant="h3">
          The page you are looking for isn’t here
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>
        <Button
          variant="contained"
          style={{ width: '80%', maxWidth: 200, margin: 50 }}
          color="primary"
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </Container>
    </>
  )
}

export default NotFound
