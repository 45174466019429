


import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import _ from 'lodash'
import TableHead from '../../../../Reusable/TableHead'
import Scrollable from '../../../../Reusable/Scrollable'
import TableBody from '../../../../Reusable/TableBody'
import Container from '../../../../Reusable/Container'
import { formatContactNo, formatLocation } from '../../../../../helpers/util'
import { PaletteColors } from '../../../../../config/colors'
import Loading from '../../../../Loading'
import routes from '../../../../../routes/route_contants'

const columns = [
  {
    label: 'Name',
    value: e => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              width: 50,
              height: 20,
              justifyContent: 'center',
            }}
          >
            {e?.partner?.tenant_logo && (
              <img
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                alt="Partner logo"
                src={`data:image/jpeg;base64,${e?.partner?.tenant_logo}`}
              />
            )}
          </div>
          <Box style={{ color: PaletteColors.textDark, marginLeft: 10 }}>
            {e?.partner?.tenant_name}
          </Box>
        </div>
      )
    },
  },
  {
    label: 'Location',
    value: e =>
      `${
        e?.partner?.primary_location
          ? formatLocation(e?.partner?.primary_location)
          : '-'
      }`,

    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'Contact No.',
    value: e => formatContactNo(e?.tenant?.tenant_phone) || '-',
  },
]

function Partners({ type, apiResult }) {
  const history = useHistory()

  const onClickHandler = row => {
    history.push(`${routes.CONNECTION_PARTNER_PAGE}/${row?.id}`, {
      id: row?.id,
    })
  }

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            rowOnClick={onClickHandler}
            columns={columns}
            data={apiResult.data?.results}
          />
        )}
      </Scrollable>
    </Container>
  )
}

Partners.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

Partners.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default Partners
