import QRCode from 'qrcode.react'
import React from 'react'
import ReactDOM from 'react-dom'
import { DevSupport } from '@react-buddy/ide-toolbox'
import { ComponentPreviews, useInitial } from '../dev'

const _generateQR = (content, config = { type: 'svg' }) => {
  return new Promise((resolve, reject) => {
    const node = document.createElement('div')
    node.id = 'extra-dom-node'
    document.body.appendChild(node)

    ReactDOM.render(
      <DevSupport
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <QRCode
          value={content}
          style={{ height: '100%', width: '100%', margin: 'auto' }}
          renderAs={config.type}
        />
      </DevSupport>,
      node
    )
    resolve(node)
  })
}

const generateQR = (content, config) => {
  return _generateQR(content, config).then(node => {
    const image = node.children[0];
    node.remove();
    return image;
  });
};

export default generateQR;
