const reducer = (state, action) => {
  const time = new Date()
  let id
  let newOrders

  switch (action.type) {
    case 'SET_AUTH_ERROR':
      return {
        ...state,
        authError: action.payload,
      }
    case 'USER_DETAILS':
      let newUser = action.user
      localStorage.setItem('user', JSON.stringify(newUser))
      return { ...state, user: action.user }
    case 'UPDATE_TENANT':
      newUser = {
        ...state.user,
        user_info: { ...state.user.user_info, tenant: action.data },
      }
      const newState = {
        ...state,
        user: newUser,
      }
      localStorage.setItem('user', JSON.stringify(newUser))
      return newState
    case 'TENANT_DETAILS':
      localStorage.setItem('tenant', JSON.stringify(action.tenantDetails))
      return { ...state, tenantDetails: action.tenantDetails }

    case 'SET_SHIPMENT':
      return {
        ...state,
        shipment: { currentShipment: action.shipment },
      }
    case 'SET_LOCATIONS':
      return {
        ...state,
        locations: action.locations,
      }
    case 'SET_TEAM_MEMBERS':
      return {
        ...state,
        team_members: action.team_members,
      }
    case 'SET_SHIPMENTS':
      return {
        ...state,
        shipments: action.shipments,
      }
    case 'SET_TENANT_CHEMICALS':
      return {
        ...state,
        chemicals: action.tenant_chemicals,
      }
    case 'SET_TANKS':
      return {
        ...state,
        tanks: action.tanks,
      }

    case 'SET_TENANTS':
      return {
        ...state,
        tenants: action.tenants,
      }
    case 'SET_CHEMICALS':
      return {
        ...state,
        shipment: {
          ...state.shipment,
          time,
          chemicals: action.chemicals,
        },
      }
    case 'SET_ORDERS':
      return {
        ...state,
        shipment: {
          ...state.shipment,
          orders: action.orders,
        },
      }
    case 'UPDATE_ORDER':
      id = action.order.order_id
      newOrders = state.shipment.orders
        ? state.shipment.orders?.map(order => {
            if (order.order_id === id) {
              order = action.order
            }
            return order
          })
        : [action.order]
      return {
        ...state,
        shipment: {
          ...state.shipment,
          orders: newOrders,
        },
      }
    case 'UPDATE_ORDER_ITEMS':
      id = action.order
      newOrders = state.shipment.orders?.map(order => {
        if (order.order_id === id) {
          order.items = action.items
        }
        return order
      })
      return {
        ...state,
        shipment: {
          ...state.shipment,
          orders: newOrders,
        },
      }

    case 'PAGINATION':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.name]: {
            page: 1,
          },
        },
      }

    case 'NEXT_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.name]: {
            page: state.pagination[action.name].page + 1,
          },
        },
      }
    case 'PREV_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.name]: {
            page: state.pagination[action.name].page - 1,
          },
        },
      }
    case 'SET_ORDERLIST':
      return {
        ...state,
        orderList: action.orderList,
      }
    case 'SET_TAB':
      return {
        ...state,
        tabs: action.tabs,
      }

    case 'SET_TENANT_CUSTOMERS':
      return {
        ...state,
        customers: action.tenant_customers,
      }
    case 'SET_TABLE_FILTERS':
      localStorage.setItem(
        'table_filters',
        JSON.stringify(action.table_filters)
      )
      return {
        ...state,
        table_filters: action.table_filters,
      }

    default:
      return state
  }
}

export default reducer
