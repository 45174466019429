

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import { returnValue } from '../MyAccount/utils'
import Container from '../Reusable/Container'
import Loading from '../Order/Tabs/Loading'
import routes from '../../routes/route_contants'

const columns = [
  { label: 'Chemical Name', value: returnValue('name') },
  { label: 'UN Number', value: returnValue('unNumber') },
  { label: 'Hazard Class', value: returnValue('hazardClass') },
]

function Chemicals({ apiResult }) {
  const history = useHistory()

  return (
    <Container>
      <Scrollable>
        {apiResult.isPending && <Loading m={4} />}
        <TableBody
          columns={columns}
          data={apiResult?.data?.results}
          rowOnClick={row => {
            history.push(`${routes.CHEMICAL_DETAILS}/${row.id}`)
          }}
        />
      </Scrollable>
    </Container>
  )
}

Chemicals.propTypes = {
  apiResult: PropTypes.object,
}

Chemicals.defaultProps = {
  apiResult: {},
}

export default Chemicals
