


import { Box, Button, Card, ListItemText, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory, useParams, useLocation } from 'react-router'
import { PaletteColors } from '../../config/colors'
import { returnValue } from '../MyAccount/utils'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import TableHead from '../Reusable/TableHead'
import TableBody from '../Reusable/TableBody'
import Scrollable from '../Reusable/Scrollable'
import LoadingIndicator from '../Order/Tabs/Loading'
import { formatLocation } from '../../helpers/util'
import routes from '../../routes/route_contants'
import routeAPI from '../../api/route'
import useAPILocal from '../../hooks/useAPILocal'

const fields = [
  { label: 'Pickup No.', value: e => e?.pickupNumber },
  {
    label: 'Shipment Date',
    value: e =>
      moment(e?.estimatedShipment)
        .format('llll')
        .slice(0, e?.estimatedShipment.length - 3),
  },
  {
    label: 'Driver',
    value: e => `${e?.driver?.first_name} ${e?.driver?.last_name}`,
  },
  { label: 'From', value: e => e?.shipper?.tenant_name },
  { label: 'Carrier', value: e => e?.carrier?.tenant_name },
  { label: 'Status', value: e => e?.status },
]

const OrderColumns = [
  {
    label: 'BOL No.',
    value: returnValue('bolNo'),
  },
  {
    label: 'Partner No.',
    value: e => e?.receiver.customer_no,
  },
  {
    label: 'Partner Name',
    value: e => e?.receiver?.tenant_name,
  },
  {
    label: 'Address',
    value: e =>
      formatLocation(e?.dropLocation, 'address city state country zipcode'),
    flex: 1.5,
  },
]

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function ShipmentDetails() {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const shipmentInfo = useAPILocal(routeAPI.shipmentInfo)

  const fetchShipmentDetails = async () => {
    await shipmentInfo.request(params.shipment_id)
  }

  const handleOrderRowClick = row => {
    history.push(`${routes.ORDER_DETAILS}/${row.id}`, {
      from: location.pathname,
    })
  }
  const handleEditShipmentClick = shipment_id => {
    history.push(`${routes.EDIT_SHIPMENT}/${shipment_id}`, {
      from: location.pathname,
    })
  }

  useEffect(() => {
    fetchShipmentDetails()
  }, [])
  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle
            backButton={() => history.push(routes.SHIPMENTS_LIST)}
            title="Shipment Details"
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleEditShipmentClick(params.shipment_id)}
          >
            Edit Shipment
          </Button>
        </PageHeading>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            padding: 40,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          {shipmentInfo.isPending ? (
            <LoadingIndicator m={4} />
          ) : (
            fields.map(field => (
              <Box key={field.label}>
                <Item
                  label={field.label}
                  value={field.value(shipmentInfo.data)}
                />
              </Box>
            ))
          )}
        </Box>
        {shipmentInfo.isPending ? (
          <LoadingIndicator m={4} />
        ) : (
          <Box mt={3}>
            <TableHead columns={OrderColumns} />
            <TableBody
              columns={OrderColumns}
              data={shipmentInfo.data?.bols}
              rowOnClick={handleOrderRowClick}
            />
          </Box>
        )}
      </Scrollable>
    </Container>
  )
}

export default ShipmentDetails
