export function get_header_row(sheet) {
  const headers = []
  const range = window.XLSX.utils.decode_range(sheet['!ref'])

  let C
  const R = range.s.r
  for (C = range.s.c; C <= range.e.c; ++C) {
    const cell = sheet[window.XLSX.utils.encode_cell({ c: C, r: R })]

    let hdr = `UNKNOWN ${C}`
    if (cell && cell.t) hdr = window.XLSX.utils.format_cell(cell)
    headers.push(hdr)
  }
  return headers
}

export const getFileSize = file => {
  const { size } = file
  if (size > 1024 ** 3) {
    return `${(size / 1024 ** 3).toFixed(2)} GB`
  }
  if (size > 1024 ** 2) {
    return `${(size / 1024 ** 2).toFixed(2)} MB`
  }
  return `${(size / 1024).toFixed(2)} KB`
}
