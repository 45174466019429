


import React from 'react'
import PropTypes from 'prop-types'
import TableHead from '../../../../../Reusable/TableHead'
import Scrollable from '../../../../../Reusable/Scrollable'
import TableBody from '../../../../../Reusable/TableBody'
import Container from '../../../../../Reusable/Container'
import Loading from '../../../../../Order/Tabs/Loading'
import { formatContactNo } from '../../../../../../helpers/util'

const columns = [
  {
    label: 'Name',
    value: e => `${e?.name} ${e?.surname}`,
    minWidth: 180,
  },
  {
    label: 'Job Title',
    value: e => e?.jobTitle,
  },
  {
    label: 'Email',
    value: e => e?.email,
    maxWidth: 300,
  },
  {
    label: 'Contact Number',
    value: e => {
      return formatContactNo(e?.phone) || '-'
    },
  },
]

function Contacts({ apiResult }) {
  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody columns={columns} data={apiResult.data?.results} />
        )}
      </Scrollable>
    </Container>
  )
}

Contacts.propTypes = {
  apiResult: PropTypes.object,
}

Contacts.defaultProps = {
  apiResult: {},
}

export default Contacts
