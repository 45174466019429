import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'

const useLogout = props => {
  const { logout } = useAuth0()

  return async () => {
    Cookies.remove('access_token')
    localStorage.removeItem('user')
    localStorage.removeItem('tenant')
    localStorage.clear()
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE}`,
      },
    })
  }
}

useLogout.defaultProps = {
  returnTo: '',
}

useLogout.propTypes = {
  returnTo: PropTypes.string,
}

export default useLogout
