import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import { PaletteColors } from '../../config/colors'

function PageTitle({ title, variant, color, backButton }) {
  const history = useHistory()
  const isBackFunction = backButton.constructor.name === 'Function'
  return (
    <Box display="flex" alignItems="center">
      {backButton && (
        <Box
          onClick={isBackFunction ? backButton : history.goBack}
          className="button-base"
          display="flex"
          color={color}
          mr={2}
        >
          <ArrowBackIcon />
        </Box>
      )}
      <Typography
        variant={variant}
        color="primary"
        style={{ fontWeight: '700' }}
      >
        {title}
      </Typography>
    </Box>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  backButton: PropTypes.any,
}

PageTitle.defaultProps = {
  variant: 'h5',
  color: PaletteColors.primaryMain,
  backButton: false,
}

export default PageTitle
