import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import ClearIcon from '@material-ui/icons/Clear'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import moment from 'moment'
import routeAPI from '../../api/route'
import useRole from '../../hooks/useRole'
import Container from '../Reusable/Container'
import routes from '../../routes/route_contants'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import PageTitle from '../Reusable/PageTitle'
import PageHeading from '../Reusable/PageHeading'
import SelectInput from '../Reusable/Widgets/SelectInput'
import FilterIcon from '../../assets/filter.png'
import withPaginationLocal from '../../HOC/withPaginationLocal'
import ShipmentListDisplay from './ShipmentListDisplay'

const dateRangeFilters = [
  { label: 'This week', value: 'week' },
  { label: 'Yesterday', value: 'yesterday' },
  // { label: 'Past 7 days', value: 'week' },
  { label: 'This month', value: 'month' },
  { label: 'This year', value: 'year' },
]

const TopLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const FilterHeading = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 500,
  color: '#003366',
})

const SubHeading = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 700,
  color: '#1A1A1A',
})

const DateText = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 400,
  textDecorationLine: 'underline',
  color: '#A3A2A2',
  marginTop: '10px',
})

const Clear = styled(Typography)({
  fontFamily: 'Lato',
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 400,
  textDecorationLine: 'underline',
  color: '#A3A2A2',
})

const Bluebox = styled(Typography)({
  background: '#F2F7FC',
  borderRadius: ' 7px',
  padding: '10px',
  marginTop: '10px',
})

const ShipmentList = () => {
  const history = useHistory()
  const [currentFilter, setCurrentFilter] = useState()
  const { role } = useRole()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [status, setStatus] = React.useState([])
  const [start_date, setStartDate] = React.useState('')
  const [end_date, setEndDate] = React.useState('')
  const [filterCondn, setFilterCondn] = React.useState({})
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const PaginatedShipment = useMemo(
    () => withPaginationLocal(routeAPI.list, filterCondn)(ShipmentListDisplay),
    [filterCondn]
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const applyFilters = (status, start_date, end_date) => {
    if (status === '' && start_date === '' && end_date === '') {
      setFilterCondn({})
    } else {
      const sta = status?.join(',')
      setFilterCondn({
        statuses: sta,
        startDate: start_date === '' ? undefined : moment(start_date).format(),
        endDate: end_date === '' ? undefined : moment(end_date).format(),
      })
    }
    handleClose()
  }

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget)
  }

  const clearStatusFilters = () => {
    setStatus([])
  }

  const clearDateFilters = () => {
    setStartDate('')
    setEndDate('')
  }

  const clearAllFilters = () => {
    clearStatusFilters()
    clearDateFilters()
  }

  const handleToDateChange = e => {
    setEndDate(e.target.value)
  }

  const handleFromDateChange = e => {
    setStartDate(e.target.value)
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Shipments" />
          {role?.admin && (
            <Box className="flex-row">
              <Button
                variant="outlined"
                color="primary"
                style={{ border: 'none', marginRight: '10px' }}
                onClick={handleClickPopover}
              >
                <img src={FilterIcon} style={{ marginRight: '8px' }} alt="" />
                Filter
              </Button>
              <SelectInput
                noLabel
                onChange={setCurrentFilter}
                emptyLabel="All Shipments"
                value={currentFilter}
                options={dateRangeFilters}
                mr={2}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push(routes.ADD_SHIPMENT)}
              >
                Add Shipment
              </Button>
            </Box>
          )}
        </PageHeading>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <PaginatedShipment />
      </Scrollable>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ boxShadow: 'none', borderRadius: '10px' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            padding: '20px',
            boxShadow: 'none',
            width: '500px',
            borderRadius: '10px',
          }}
        >
          <TopLine>
            <FilterHeading>Filter</FilterHeading>
            <ClearIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </TopLine>
          <Bluebox>
            <TopLine>
              <SubHeading>Status</SubHeading>
              <Clear onClick={clearStatusFilters}>Clear</Clear>
            </TopLine>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item md={4}>
                <Button
                  style={{
                    width: '96%',
                    marginTop: '10px',
                    background: 'white',
                    borderColor:
                      status.indexOf('PRE_SHIPMENT') !== -1
                        ? '#3298FF'
                        : 'transparent',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    let newStatus = []
                    if (status.indexOf('PRE_SHIPMENT') === -1) {
                      newStatus = [...status, 'PRE_SHIPMENT']
                    } else {
                      newStatus = status.filter(item => item !== 'PRE_SHIPMENT')
                    }
                    setStatus(newStatus)
                  }}
                >
                  {status.indexOf('PRE_SHIPMENT') !== -1 ? (
                    <CheckIcon color="#3298FF" fontSize="small" />
                  ) : null}
                  Preshipment
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button
                  style={{
                    width: '96%',
                    marginTop: '10px',
                    background: 'white',
                    borderColor:
                      status === 'ARRIVED_TO_LOAD' ? '#3298FF' : 'transparent',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    let newStatus = []
                    if (status.indexOf('ARRIVED_TO_LOAD') === -1) {
                      newStatus = [...status, 'ARRIVED_TO_LOAD']
                    } else {
                      newStatus = status.filter(
                        item => item !== 'ARRIVED_TO_LOAD'
                      )
                    }
                    setStatus(newStatus)
                  }}
                >
                  {status.indexOf('ARRIVED_TO_LOAD') !== -1 ? (
                    <CheckIcon color="#3298FF" fontSize="small" />
                  ) : null}
                  Arrived to load
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button
                  style={{
                    width: '96%',
                    marginTop: '10px',
                    background: 'white',
                    borderColor:
                      status === 'IN_TRANSIT' ? '#3298FF' : 'transparent',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    let newStatus = []
                    if (status.indexOf('IN_TRANSIT') === -1) {
                      newStatus = [...status, 'IN_TRANSIT']
                    } else {
                      newStatus = status.filter(item => item !== 'IN_TRANSIT')
                    }
                    setStatus(newStatus)
                  }}
                >
                  {status.indexOf('IN_TRANSIT') !== -1 ? (
                    <CheckIcon color="#3298FF" fontSize="small" />
                  ) : null}
                  In Transit
                </Button>
              </Grid>

              <Grid item md={4}>
                <Button
                  style={{
                    width: '96%',
                    marginTop: '10px',
                    background: 'white',
                    borderColor:
                      status === 'COMPLETED' ? '#3298FF' : 'transparent',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    let newStatus = []
                    if (status.indexOf('COMPLETED') === -1) {
                      newStatus = [...status, 'COMPLETED']
                    } else {
                      newStatus = status.filter(item => item !== 'COMPLETED')
                    }
                    setStatus(newStatus)
                  }}
                >
                  {status.indexOf('COMPLETED') !== -1 ? (
                    <CheckIcon color="#3298FF" fontSize="small" />
                  ) : null}
                  Delivered
                </Button>
              </Grid>
            </Grid>
          </Bluebox>
          <Bluebox>
            <TopLine>
              <SubHeading>Date</SubHeading>
              <Clear onClick={clearDateFilters}>Clear</Clear>
            </TopLine>
            <TopLine style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DateText>From</DateText>
                <TextField
                  name="start_date"
                  id="start_date"
                  type="date"
                  onChange={handleFromDateChange}
                  style={{ border: 'none', marginLeft: '10px' }}
                  value={start_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DateText>To</DateText>
                <TextField
                  name="end_date"
                  id="end_date"
                  type="date"
                  onChange={handleToDateChange}
                  style={{ border: 'none', marginLeft: '10px' }}
                  value={end_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </TopLine>
          </Bluebox>
          <TopLine style={{ marginTop: '20px' }}>
            <Clear
              style={{ fontSize: '14px', marginTop: '10px' }}
              onClick={clearAllFilters}
            >
              Clear All Filters
            </Clear>
            <div>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: '10px' }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => applyFilters(status, start_date, end_date)}
              >
                Apply Filter
              </Button>
            </div>
          </TopLine>
        </div>
      </Popover>
    </Container>
  )
}

export default ShipmentList
