import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function Container({ children, row, style, ...rest }) {
  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: row ? 'row' : 'column',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
  row: PropTypes.bool,
  style: PropTypes.object,
}

Container.defaultProps = {
  row: false,
  style: {},
}

export default Container
