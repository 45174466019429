import React from 'react'
import PropTypes from 'prop-types'

import './loading.scss'
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
}))

function Loading(props) {
  const classes = useStyles()
  const { fullScreen, text } = props
  if (fullScreen) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
        {text && <Typography
          color="white"
          style={{ fontWeight: '400' }}
        >
          {text}
        </Typography>}
      </Backdrop>
    )
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

Loading.defaultProps = {
  fullScreen: false,
}

Loading.propTypes = {
  fullScreen: PropTypes.bool,
}

export default Loading
