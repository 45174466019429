import client from '../../hooks/useClient'

const checklistAPI = {}

checklistAPI.getChecklists = data => {
  return client.get(`/checklists?limit=${data.limit}&page=${data.page}`)
}

checklistAPI.singleChecklist = checklistId => {
  return client.get(`/checklists/${checklistId}`)
}

checklistAPI.createChecklist = data => {
  return client.post(`/checklists`, data)
}

checklistAPI.updateChecklist = (id, data) => {
  return client.put(`/checklists/${id}`, data)
}

checklistAPI.deleteChecklist = id => {
  return client.delete(`/checklists/${id}`)
}

export default checklistAPI
