import { Button, Grid, Select, TextField, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const MainContainer = styled('div')({
  padding: '30px',
})

export const TopLine = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

export const CreateText = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '23px',
  lineHeight: '30px',
  fontWeight: 500,
  color: '#003366',
  marginLeft: '10px',
})

export const GridLeft = styled(Grid)({
  borderRight: '1px solid rgb(174,174,174,0.2)',
  padding: '50px 50px',
  height: '70vh',
})

export const GridRight = styled(Grid)({
  padding: '20px 50px',
})

export const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const ListTitle = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 700,
  color: '#3298FF',
  marginBottom: '30px',
  cursor: 'pointer',
})

export const Title = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '15px',
  lineHeight: '18px',
  fontWeight: 500,
  color: '#003366',
  marginBottom: '10px',
})

export const SelectStyled = styled(Select)({
  borderRadius: '10px',
  backgroundColor: '#fff',
  width: '90%',
  marginBottom: '40px',
  height: '50px',
})

export const InputStyled = styled(TextField)({
  borderRadius: '10px',
  backgroundColor: '#fff',
  width: '95%',
  marginBottom: '40px',
  height: '50px',
})

export const OptionStyled = styled(TextField)({
  borderRadius: '10px',
  backgroundColor: '#fff',
  height: '50px',
})

export const ButtonSection = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
})

export const ButtonStyled = styled(Button)({
  padding: '10px 20px',
  borderRadius: '7px',
  backgroundColor: '#003366',
  boxShadow: '0px 1px 5px rgba(117, 113, 113, 0.25)',
  textTransform: 'none',
  fontFamily: 'Lato',
  fontSize: '15px',
  lineHeight: '18px',
  fontWeight: 500,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#003366',
    color: '#ffffff',
  },
  '& active': {
    backgroundColor: '#003366',
    color: '#ffffff',
  },
})

export const AddOption = styled(Button)({
  padding: '10px 20px',
  borderRadius: '7px',
  backgroundColor: '#E9F4FE',
  textTransform: 'none',
  fontFamily: 'Lato',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 500,
  color: '#3298FF',
  margin: '0px 10px',
  '&:hover': {
    backgroundColor: '#E9F4FE',
    color: '#3298FF',
  },
  '& active': {
    backgroundColor: '#E9F4FE',
    color: '#3298FF',
  },
})

export const AddQuestion = styled(Button)({
  width: 'fit-content',
  padding: '10px 20px',
  borderRadius: '7px',
  backgroundColor: '#E9F4FE',
  textTransform: 'none',
  fontFamily: 'Lato',
  fontSize: '15px',
  lineHeight: '18px',
  fontWeight: 500,
  color: '#3298FF',
  margin: '20px 0px',
  '&:hover': {
    backgroundColor: '#E9F4FE',
    color: '#3298FF',
  },
  '& active': {
    backgroundColor: '#E9F4FE',
    color: '#3298FF',
  },
})
