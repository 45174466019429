import { useEffect, useState } from 'react'

const useDimension = () => {
  const [device, setDevice] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const updateWindowDimensions = () => {
    setDevice({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  return {
    isMobile: device.width <= 480,
    isTablet: device.width > 480 && device.width <= 1024,
    isDesktop: device.width > 1024,
    device,
  }
}

export default useDimension
