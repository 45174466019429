


import React, { useState } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'
import TableHead from '../../../Reusable/TableHead'
import Scrollable from '../../../Reusable/Scrollable'
import TableBody from '../../../Reusable/TableBody'
import Container from '../../../Reusable/Container'
import Loading from '../../../Loading'

import { formatContactNo } from '../../../../helpers/util'
import routes from '../../../../routes/route_contants'
import VisibilityModal from '../MyCompany/Seller/VisibilityModal'

function MyRequestsList({ type, apiResult }) {
  const history = useHistory()
  const [isOpenInvitation, setIsOpenInvitation] = useState(false)

  const toggleModalInvitation = () => {
    setIsOpenInvitation(!isOpenInvitation)
  }

  const handleRowClick = row => {
    history.push(`${routes.CONNECTION_REQUEST_PAGE}/${row.id}`, {
      data: row,
    })
  }

  const columns = [
    {
      label: 'Requested By',
      value: e =>
        `${e?.requested_by?.first_name} ${e?.requested_by?.last_name}`,
      minWidth: 180,
    },
    {
      label: 'Seller/Buyer',
      value: e => e?.partner?.tenant_name,
      minWidth: 180,
    },
    {
      label: 'Contact No.',
      value: e => formatContactNo(e?.requested_by?.phone_no),
    },
    {
      label: 'Date & Time',
      value: e =>
        moment(e?.requested_on).format('llll').slice(0, e?.requested_on.length),
      maxWidth: 300,
      flex: 2,
    },
    {
      label: 'Message',
      value: e => e?.note || '-',
    },
  ]

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={handleRowClick}
          />
        )}
        <VisibilityModal
          isOpen={isOpenInvitation}
          toggleModal={toggleModalInvitation}
        />
      </Scrollable>
    </Container>
  )
}

export default MyRequestsList
