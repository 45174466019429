

import { Tab, Tabs, makeStyles, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import step1 from './steps/step1'

export const steps = [{ label: 'Chemical Description', data: step1 }]

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
}))

const StepComponent = ({ index, label }) => (
  <Box display="flex">
    <Box mr={2}>{index + 1}</Box> {label}
  </Box>
)

function Steps({ onChange, current }) {
  const [currentStep, setCurrentStep] = useState(current)
  const classes = useStyles()
  useEffect(() => {
    onChange(currentStep)
  }, [currentStep])

  useEffect(() => {
    setCurrentStep(current)
  }, [current])

  return (
    <Tabs
      orientation="vertical"
      TabIndicatorProps={{ style: { display: 'none' } }}
      value={currentStep}
      onChange={(e, v) => setCurrentStep(v)}
    >
      {steps.map((tab, index) => (
        <Tab
          className={classes.tabs}
          wrapped={false}
          key={tab.label}
          label={<StepComponent index={index} label={tab.label} />}
        />
      ))}
    </Tabs>
  )
}

Steps.propTypes = {
  onChange: PropTypes.func,
}

Steps.defaultProps = {
  onChange: () => {},
}

export default Steps
