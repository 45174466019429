import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'js-cookie'
import Typography from '@material-ui/core/Typography'
import { StateContext } from "../store/StateProvider";

import routes from './route_contants'
import SuperUserRoutes from './SuperUserRoutes'
import AdminRoutes from './AdminRoutes'
import CommonRoutes from './CommonRoutes'
import ProtectedRoute from './protectedRoute'
import useRole from '../hooks/useRole'
import NotFound from '../component/NotFound'

const Routes = () => {
  const { role } = useRole()
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const location = useLocation()
  const [{ authError }] = useContext(StateContext);

  useEffect(() => {
    if (isAuthenticated && !localStorage.getItem('user'))
      return history.push(routes.AUTH)
    if (
      isAuthenticated &&
      location.pathname === '/' &&
      Cookies.get('access_token')
    ) {
      return history.push(routes.AUTH)
    }
  }, [isAuthenticated])

  return (
    <Switch>
      {authError && (
        <Route path="*">
          <Typography
            style={{
              fontSize: '16px',
              lineHeight: '18px',
              color: '#000000',
              textAlign: 'center',
              width: '100%',
              zIndex: 1111,
              marginTop: '50px',
            }}
          >
            There was an error during authorization. Please try again later.
          </Typography>
        </Route>
      )}
      {CommonRoutes?.map((ele, index) => (
        <Route
          exact
          component={ele.component}
          path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
          key={String(index)}
        />
      ))}
      {role?.superuser &&
        SuperUserRoutes?.map((ele, index) => {
          if (ele.isProtected) {
            return (
              <ProtectedRoute
                component={ele.component}
                path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
                key={String(index)}
              />
            )
          }
          return (
            <Route
              component={ele.component}
              path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
              key={String(index)}
            />
          )
        })}
      {role?.admin &&
        AdminRoutes?.map((ele, index) => {
          if (ele.isProtected) {
            return (
              <ProtectedRoute
                component={ele.component}
                path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
                key={String(index)}
              />
            )
          }
          return (
            <Route
              component={ele.component}
              path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
              key={String(index)}
            />
          )
        })}
      {role?.normal_user && (
          <Route path="*">
            <Typography
              style={{
                fontSize: '16px',
                lineHeight: '18px',
                color: '#000000',
                textAlign: 'center',
                width: '100%',
                zIndex: 1111,
                marginTop: '50px',
              }}
            >
              This user account doesn’t have sufficient permission to view this
              page
            </Typography>
          </Route>
        )
        // Commented in T2T-700
        // UserRoutes?.map((ele, index) => {
        //   if (ele.isProtected) {
        //     return (
        //       <ProtectedRoute
        //         component={ele.component}
        //         path={`${ele.path}${ele.queryParams ? ele.queryParams : ''}`}
        //         key={String(index)}
        //       />
        //     )
        //   }
        //   return (
        //     <Route
        //       component={ele.component}
        //       path={ele.path}
        //       key={String(index)}
        //     />
        //   )
        // })
      }

      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
