import { Box, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function TableHead({ columns, selectable, onSelected }) {
  const [checked, setChecked] = useState(false)
  const handleSelected = () => {
    setChecked(prev => !prev)
    onSelected(!checked)
  }
  return (
    <Box className="table-head">
      {selectable && (
        <Box className="table-body-column">
          <Checkbox checked={checked} onChange={handleSelected} />
        </Box>
      )}
      {columns.map(column => (
        <Box
          flex={column.flex || 1}
          key={column.label}
          style={{ minWidth: column.minWidth }}
        >
          {column.label}
        </Box>
      ))}
    </Box>
  )
}

TableHead.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default TableHead
