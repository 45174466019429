const fieldVisible = () => true

export const defaultCheckboxLabel = 'Check if apply'

export const volumeUnit = [
  { label: 'Liters', value: 'liters' },
  { label: 'Gallons', value: 'gallons' },
  { label: 'Wet Tons', value: 'wet_tons' },
  { label: 'Pounds', value: 'pounds' },
  { label: 'Kg', value: 'kg' },
  { label: 'Metric Tons', value: 'metric_tons' },
]

export const diameterUnit = [
  { label: 'in', value: 'in' },
  { label: 'ft', value: 'ft' },
  { label: 'cm', value: 'cm' },
  { label: 'm', value: 'm' },
]

class FormField {
  constructor(name, label, initial, validations) {
    this.name = name
    this.label = label
    this.initial = initial
    this.validations = validations
  }
}

class IText extends FormField {
  constructor(name, label, initial, validations, options = []) {
    super(name, label, initial, validations)
    this.type = 'text'
    Object.keys(options).forEach(option => {
      this[option] = options[option]
    })
  }
}

class IDate extends FormField {
  constructor(name, label, initial, validations) {
    super(name, label, initial, validations)
    this.type = 'date'
  }
}

class ISelect extends FormField {
  constructor(name, label, initial, validations, options) {
    super(name, label, initial, validations)
    this.type = 'select'
    this.options = options
  }
}

class IAutoComplete extends FormField {
  constructor(name, label, initial, validations, api, renderItem) {
    super(name, label, initial, validations)
    this.type = 'autocomplete'
    this.api = api
    this.renderItem = renderItem
  }
}

class MultipleAutoComplete extends IAutoComplete {
  constructor(...props) {
    super(...props)
    const members = this
    members.multiple = true
    const options = props[props.length - 1]
    Object.keys(options).forEach(option => {
      members[option] = options[option]
    })
  }
}

function Measurement(
  value,
  valuelabel,
  name,
  label,
  unit_name,
  unit_type = 'volume',
  validations = []
) {
  const unit = unit_name || `${name}_unit`
  return {
    key: `${label}-key`,
    visible: fieldVisible,
    children: [
      new IText(name, label, '', ['text', ...validations]),
      new IText(value, valuelabel, '', ['text', ...validations]),
    ],
  }
}

const modifyFields = data => {
  const newValues = {}
  Object.keys(data).map(key => {
    if (data[key]) {
      newValues[key] = data[key]
    } else if (data[key] === false) {
      newValues[key] = data[key]
    }
    return key
  })
  newValues.code = data.code
  newValues.name = data.name
  if (data.description !== '') {
    newValues.description = data.description
  }
  newValues.unNumber = data.unNumber || null
  if (data.grade !== '') {
    newValues.grade = data.grade
  }
  if (data.concentration !== '') {
    newValues.concentration = data.concentration
  }
  newValues.sell = data.sell
  newValues.buy = data.buy

  delete newValues.tenant
  delete newValues.files
  delete newValues.chemical
  delete newValues.id

  return newValues
}

export {
  ISelect,
  IAutoComplete,
  MultipleAutoComplete,
  IText,
  IDate,
  Measurement,
  fieldVisible,
  modifyFields,
}
