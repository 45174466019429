import React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import colors from '../../config/colors'
import Search from '../Search'

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: colors.primary,
    fontSize: 14,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    borderRadius: theme.shape.borderRadius,
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    backgroundColor: '#00000010',
    '&:hover': {
      backgroundColor: '#00000020',
    },
    transition: '.5s',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

function SearchBar() {
  const [dialogOpen, setDialogOpen] = useState(false)
  const classes = useStyles()
  return (
    <div className={classes.search}>
      <Search dialogOpen onClose={() => setDialogOpen(false)} />
    </div>
  )
}

export default SearchBar
