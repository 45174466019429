

import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import useRouteDetails from '../../hooks/useRouteDetails'
import colors from '../../config/colors'

const useStyles = makeStyles({
  listItem: {
    padding: '4px',
    height: '42px',
    color: colors.primary,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'red',
      '& .MuiListItemText-root': {
        color: 'white',
      },
    },
  },
})

const styles = {
  link: {
    margin: '5px 0',
    alignSelf: 'center',
    width: '100%',
    textDecoration: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    fontSize: 14,
    color: '#FFFFFF',
  },
  selectedText: {
    fontSize: 14,
    color: '#3298FF',
  },
}

const NewNavigationLinks = ({ onNavigate }) => {
  const { allRoutes, current, path } = useRouteDetails()
  const history = useHistory()
  const classes = useStyles()
  const goToRoute = route => {
    history.push(route)
    onNavigate()
  }
  return (
    <div style={{ overflow: 'auto' }}>
      <div style={styles.container}>
        {Object.entries(allRoutes)
          // .slice(0, 4)
          .map(route => {
            const selected = path === route[1]?.routeName;
            return (
              <Tooltip
                title={
                  <div style={{ padding: '5px' }}>
                    <Typography color="inherit">
                      {route[1]?.description}
                    </Typography>
                  </div>
                }
                placement="left-start"
                key={route[0]}
                arrow
              >
                <Link to={route[0]} style={styles.link}>
                  <ListItem
                    onClick={() => goToRoute(route[0])}
                    button
                    className={classes.listItem}
                    selected={selected}
                    style={{
                      backgroundImage:
                        selected &&
                        'linear-gradient(270deg, #3399FF 0%, rgba(51, 153, 255, 0) 100%)',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        left: '0px',
                        width: '10px',
                        backgroundColor: '#3298FF',
                        height: selected ? '42px' : 0,
                      }}
                    ></div>
                    <ListItemIcon style={{ width: '65px' }}>
                      <img
                        alt=""
                        src={route[1].iconWhite}
                        style={{
                          width: '22px',
                          height: '22px',
                          marginLeft: '30px',
                        }}
                      />
                    </ListItemIcon>
                    {/* route[1]: current route */}
                    <ListItemText
                      primaryTypographyProps={{
                        className: selected ? 'font-bold' : '',
                        style: styles.text,
                      }}
                      primary={route[1].name}
                    />
                  </ListItem>
                </Link>
              </Tooltip>
            )
          })}
      </div>
    </div>
  )
}

export default NewNavigationLinks

NewNavigationLinks.propTypes = {
  onNavigate: PropTypes.func.isRequired,
}
