import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { SecondaryTab, SecondaryTabs } from '../../../Reusable/SecondaryTabs'
import Container from '../../../Reusable/Container'
import Fixed from '../../../Reusable/Fixed'
import Scrollable from '../../../Reusable/Scrollable'
import TabPanel from '../../../Reusable/TabPanel'
import Buyer from './Buyer'
import Seller from './Seller'

export default function Explore() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Container>
        <Fixed>
          <Box className="flex-row" justifyContent="space-between">
            <Box flex={1}>
              <SecondaryTabs value={value} onChange={handleChange}>
                <SecondaryTab label="Potential Suppliers" />
                <SecondaryTab label="Potential Customers" />
              </SecondaryTabs>
            </Box>
          </Box>
        </Fixed>
        <Scrollable mt={2}>
          <TabPanel value={value} index={0}>
            <Seller />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Buyer />
          </TabPanel>
        </Scrollable>
      </Container>
    </>
  )
}
