import { Box, makeStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import config from '../theme/config'
import { PaletteColors } from '../config/colors'
import useAPILocal from '../hooks/useAPILocal'
import Typography from '@mui/material/Typography'
import LoadingIndicator from '../component/Order/Tabs/Loading'

const { apiLimit: limit } = config

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: 'transparent',
      color: PaletteColors.secondaryMain,
      fontWeight: 'bold',
    },
  },
}))

function withPaginationLocal(api, params) {
  return function (Component) {
    function PaginatedComponent(props) {
      const classes = useStyles()
      const API = useAPILocal(api)
      const [currentPage, setCurrentPage] = useState(1)
      const [lastPage, setLastPage] = useState(0)

      const callAPI = async page => {
        await API.request({
          ...params,
          limit,
          page,
        })
        setLastPage(page)
      }

      useEffect(() => {
        callAPI(currentPage)
      }, [currentPage])

      return (
        <Box style={{ height: '100%', overflow: 'hidden' }}>
          {API.isPending ? <LoadingIndicator m={4} /> : (
          API.data?.count > 0 ? (
            <>
              <Component apiResult={API} {...props} currentPage={currentPage} />
              <div className="pagination-bottom">
                {API.isResolved ? (
                  <div className="summary">
                    Showing <strong>{(currentPage - 1) * limit + 1}</strong> -{' '}
                    <strong>
                      {API.data?.pages === currentPage
                        ? API.data?.count
                        : currentPage * limit}
                    </strong>{' '}
                    of <strong>{API.data?.count}</strong>
                  </div>
                ) : (
                  <div></div>
                )}

                <Pagination
                  count={API.data?.pages}
                  page={currentPage}
                  className={classes.root}
                  onChange={(e, pageNo) => {
                    setCurrentPage(pageNo)
                  }}
                />
              </div>
            </>
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body1">No results found...</Typography>
            </Box>
          ))}
        </Box>
      )
    }

    return PaginatedComponent
  }
}

export default withPaginationLocal
