import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Scrollable from '../../Reusable/Scrollable'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import userAPI from '../../../api/user'
import TeamMembersInviteList from './TeamMembersInviteList'
import useStateValue from '../../../hooks/useStateValue'
import withPaginationLocal from '../../../HOC/withPaginationLocal'

const dateRangeFilters = [
  { label: 'Invited', value: 'invited' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Cancelled', value: 'cancelled' },
]

function TeamMembersInvite({ type, apiResult }) {
  const [state] = useStateValue()

  const [currentFilter, setCurrentFilter] = useState('invited')

  const TeamMemberInvitation = withPaginationLocal(userAPI.invitationList, {
    status: currentFilter,
    tenantId: state?.user?.user_info?.tenant?.tenant_id,
  })(TeamMembersInviteList)

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading mb={2}>
          <PageTitle backButton title="Invited Users" />
          <Box className="flex-row">
            <SelectInput
              noLabel
              onChange={setCurrentFilter}
              // emptyLabel="All Members"
              value={currentFilter}
              options={dateRangeFilters}
            />
          </Box>
        </PageHeading>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <TeamMemberInvitation />
      </Scrollable>
    </Container>
  )
}

TeamMembersInvite.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

TeamMembersInvite.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default TeamMembersInvite
