import client from '../../hooks/useClient'

const searchAPI = {}

searchAPI.globalSearch = data =>
  client.get(
    `/global/search?search=${data.search || ''}&limit=${data.limit || 12}`
  )

export default searchAPI
