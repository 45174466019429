// import { Details } from '@material-ui/icons'

const Details = [
  {
    key: 'Category Details',
    visible: true,
    children: [
      {
        type: 'select',
        label: 'Checklist',
        name: 'category',
        options: [
          { label: 'General', value: 'general' },
          { label: 'Carrier', value: 'carrier' },
          { label: 'Shipper', value: 'shipper' },
        ],
      },
      {
        type: 'select',
        label: 'Required / Optional',
        name: 'category',
        options: [
          { label: 'Required', value: 'required' },
          { label: 'Optional', value: 'optional' },
        ],
      },
    ],
  },
  {
    key: 'Partner Details',
    visible: true,
    children: [
      {
        type: 'text',
        label: 'Checklist Name',
        name: 'partner_name',
        placeholder: 'Enter checklist name',
      },
    ],
  },
]

export default Details
