

import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import useRole from '../../../hooks/useRole'
import Loading from '../../Loading'
import OrderItem from '../OrderItem'
import Scrollable from '../../Reusable/Scrollable'

const columns = [
  {
    label: 'Ordered Qty',
  },
  {
    label: 'Shipped Qty',
  },
  {
    label: 'Product',
    // minWidth: 180,
  },
  {
    label: 'Net Weight',
  },
  {
    label: 'Checklist',
  },
  {
    label: 'Status',
  },
  {
    label: 'Action',
  },
]

function CustomTableHead({ columnsProp }) {
  return (
    <Box className="table-head">
      {columnsProp.map(column => (
        <Box
          flex={column.flex || 1}
          key={column.label}
          style={{ minWidth: column.minWidth }}
        >
          {column.label}
        </Box>
      ))}
    </Box>
  )
}
function Products({ loading, order, items = [], onUpdate }) {
  const { role } = useRole()

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <Scrollable>
        <CustomTableHead columnsProp={columns} />
        <Box className="table-body">
          {items?.map((item, idx) => {
            const key = `table-row-${idx}`
            return (
              <React.Fragment key={key}>
                <OrderItem
                  onDone={resp => {
                    onUpdate(resp)
                  }}
                  key={item.order_item_id}
                  item={{
                    ...item,
                    checklist: order?.carrier_checklist?.products
                      ? order?.carrier_checklist?.products[
                          `${
                            Object.keys(order?.carrier_checklist?.products)[idx]
                          }`
                        ]
                      : {},
                  }}
                />
              </React.Fragment>
            )
          })}
        </Box>
      </Scrollable>
    </div>
  )
}

export default Products
