


import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import TableHead from '../../../../../Reusable/TableHead'
import Scrollable from '../../../../../Reusable/Scrollable'
import TableBody from '../../../../../Reusable/TableBody'
import Container from '../../../../../Reusable/Container'
import Loading from '../../../../../Order/Tabs/Loading'
import { returnValue } from '../../../../../MyAccount/utils'
import { formatContactNo, formatLocation } from '../../../../../../helpers/util'
import routes from '../../../../../../routes/route_contants'

const columns = [
  {
    label: 'Name',
    value: returnValue('location_name'),
  },
  {
    label: 'Address',
    value: e =>
      `${formatLocation(e, 'postal_address city state country postal_code')}`,
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'Contact No.',
    value: e => formatContactNo(e?.phone_no),
  },
]

function Locations({ apiResult, partnerId }) {
  const history = useHistory()

  const handleRowClick = row => {
    history.push(`${routes.LOCATION_DETAILS}/${row.location_id}`, {
      partnerId,
    })
  }

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={handleRowClick}
          />
        )}
      </Scrollable>
    </Container>
  )
}

Locations.propTypes = {
  apiResult: PropTypes.object,
  partnerId: PropTypes.string,
}

Locations.defaultProps = {
  apiResult: {},
  partnerId: '',
}

export default Locations
