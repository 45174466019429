import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import EditIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState, useRef } from 'react'
import { throttled } from '../../../helpers/util'
import {
  deafultFunc,
  defaultObject,
  defaultString,
} from '../../../theme/config'
import useAPILocal from '../../../hooks/useAPILocal'
import Icon from "../../Icon";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function AutoCompleteInput({
  api,
  onChange,
  label,
  value: defaultItem,
  renderItem,
  error,
  multiple,
  hidden,
  disabled,
  clearOnBlur,
  title = '',
  ...rest
}) {
  const listAPI = useAPILocal(api.src)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [item, setItem] = useState(defaultItem)
  const [edit, setEdit] = useState(!defaultItem)
  const ref = useRef()
  const [data, setData] = useState([])

  const handleChange = e => {
    setValue(e.target.value)
  }
  useEffect(() => {
    setItem(defaultItem)
  }, [defaultItem])
  const handleValueChange = (e, v) => {
    if (v) {
      setItem(v)
      setEdit(false)
      onChange(v)
    }
  }
  useEffect(() => {
    if (value) {
      throttled(
        () =>
          listAPI.request({ search: value, ...api.params }).then(res => {
            setData(res.data?.results || res.data?.data?.results || [])
            setOpen(true)
          }),
        0.5
      )
    }
  }, [value])

  useEffect(() => {
    setData([])
  }, [api.params])

  useEffect(() => {
    if (!hidden && !item) setEdit(true)
  }, [hidden])

  useEffect(() => {
    if (item && edit) {
      ref.current.focus()
    }
  }, [edit])

  function editText() {
    setEdit(true)
    setItem('')
    setValue('')
    onChange('')
  }

  function removeText() {
    setItem('')
    setValue('')
    onChange('')
  }

  return (
    !hidden && (
      <Box className="input-container" flex="1">
        <Box className="input-label">{label}</Box>
        {edit || multiple ? (
          <Autocomplete
            disabled={disabled}
            clearOnBlur={clearOnBlur}
            fullWidth
            multiple={multiple}
            options={data}
            open={open}
            onOpen={() => {
              if (value) setOpen(true)
            }}
            className={item?.length > 0 ? 'multiple-autocomplete' : ''}
            defaultValue={multiple ? defaultItem || [] : null}
            onClose={() => setOpen(false)}
            getOptionLabel={renderItem}
            sx={{ width: 300 }}
            noOptionsText={
              title === 'contact'
                ? 'No Operational Contacts defined yet, please add some'
                : `No ${title} found for '${value}'`
            }
            onChange={handleValueChange}
            ListboxProps={{ style: { fontSize: 14, paddingLeft: 13 } }}
            onInput={handleChange}
            renderOption={
              multiple
                ? (option, { selected }) => (
                    <li>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {renderItem(option)}
                    </li>
                  )
                : undefined
            }
            loading={listAPI.isPending}
            renderInput={params => (
              <TextField
                {...params}
                onBlur={() => {
                  if (item) setEdit(false)
                }}
                placeholder={rest.placeholder}
                inputRef={ref}
                autoFocus={true}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {listAPI.isPending ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Box flex="1" display="flex">
            <TextField
              value={typeof item === 'object' ? renderItem(item) : item}
              style={{ flex: 1 }}
              disabled
            />
            <Button size="small" onClick={() => editText()}>
              <EditIcon style={{ fontSize: 18 }} />
            </Button>
            <Button
              onClick={() => removeText()}
            >
              <Icon name="close" />
            </Button>
          </Box>
        )}
        {error !== '' && <div className="input-error">{error}</div>}
      </Box>
    )
  )
}

AutoCompleteInput.propTypes = {
  onChange: PropTypes.func,
  api: PropTypes.any,
  label: PropTypes.string,
  defaultItem: PropTypes.object,
  renderItem: PropTypes.func,
  title: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any.isRequired,
  clearOnBlur: PropTypes.bool,
  disabled: PropTypes.bool,
}

AutoCompleteInput.defaultProps = {
  onChange: deafultFunc,
  api: defaultObject,
  label: defaultString,
  defaultItem: null,
  title: defaultString,
  error: defaultString,
  clearOnBlur: true,
  disabled: false,
  renderItem: option =>
    Object.keys(option)
      .map(k => option[k])
      .join(' - '),
}

export default AutoCompleteInput
