import React from 'react';
import TextInput, { TextInputPropTypes } from './TextInput';

function DateTimeInput(props) {
  return (
    <TextInput
      flex="1"
      {...props}
      type="datetime-local"
      inputProps={{
        pattern: '\\d{4}\\.\\d{2}\\.\\d{2}[Tt]\\d{2}:\\d{2}'
      }}
    />
  );
}

DateTimeInput.propTypes = TextInputPropTypes;

DateTimeInput.defaultProps = {
  ...TextInput.defaultProps,
};

export default DateTimeInput;
