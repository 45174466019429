import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

function LoadingIndicator({ loading, ...rest }) {
  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...rest}
    >
      <CircularProgress />
    </Box>
  )
}

export default LoadingIndicator
