import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function PageHeading({ children, ...rest }) {
  return (
    <Box {...rest} display="flex" justifyContent="space-between">
      {children}
    </Box>
  )
}

PageHeading.propTypes = {
  children: PropTypes.any.isRequired,
}

export default PageHeading
