
import React, { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import withPaginationLocal from '../../../../../HOC/withPaginationLocal'
import SellerTenantList from './SellerTenantList'
import tenantAPI from '../../../../../api/tenant'
import Scrollable from '../../../../Reusable/Scrollable'
import Container from '../../../../Reusable/Container'
import Fixed from '../../../../Reusable/Fixed'
import PageHeading from '../../../../Reusable/PageHeading'
import SearchBar from '../../../../Reusable/Widgets/SearchBar'
import ConnectionModal from '../TenantDetailPage/ConnectionModal'

export default function Seller() {
  const [searchStr, setSearchStr] = useState(undefined)
  const [isOpenConnection, setIsOpenConnection] = useState(false)

  const toggleModalConnection = () => {
    setIsOpenConnection(!isOpenConnection)
  }

  const TenantList = withPaginationLocal(tenantAPI.publicSellers, {
    isActive: true,
    tenantName: searchStr || '',
  })(SellerTenantList)

  const handleSearch = val => {
    if (val?.length >= 3) {
      setSearchStr(val)
    } else {
      if (val?.length === 0) {
        setSearchStr(undefined)
      }
    }
  }

  return (
    <>
      <Container>
        <Fixed mb={2}>
          <PageHeading alignItems="center" style={{ marginTop: '5px' }}>
            <Typography
              style={{
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#1A1A1A',
              }}
            >
              Tenants
            </Typography>
            <Box display="flex">
              <Box mr={2}>
                <SearchBar onChange={handleSearch} />
              </Box>

              <Button
                onClick={toggleModalConnection}
                variant="outlined"
                color="primary"
              >
                Private Connection
              </Button>
            </Box>
          </PageHeading>
        </Fixed>
        <Scrollable style={{ height: '100%' }}>
          <TenantList />
        </Scrollable>
        <ConnectionModal
          isOpen={isOpenConnection}
          toggleModal={toggleModalConnection}
          privateConnection
        />
      </Container>
    </>
  )
}
