import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import TableHead from '../Reusable/TableHead'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import Container from '../Reusable/Container'
import { returnValue } from './utils'
import { formatLocation } from '../../helpers/util'
import Loading from '../Order/Tabs/Loading'
import { PaletteColors } from '../../config/colors'
import Tankl from '../../assets/TankList.png'
import routes from '../../routes/route_contants'
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const columns = [
  {
    label: 'Reg. No.',
    value: returnValue('tankRegistrationNo'),
    minWidth: 180,
  },
  {
    label: 'Product Details',
    value: e => {
      if (e.chemical?.description) {
        return (
          <Box>
            <Box style={{ color: PaletteColors.textDark }}>
              {e.chemical?.description}
            </Box>
          </Box>
        )
      }
      if (e.products[0]?.chemical?.name) {
        return (
          <Box style={{ display: 'inline-block' }}>
            <HtmlTooltip
              title={
                <>
                  <Typography color="inherit">
                    <b>{'Universal Product: '}</b>
                  </Typography>
                  {e.products[0]?.chemical?.name}
                  <Typography color="inherit">
                    <b>{'Company Products: '}</b>
                  </Typography>
                  {e.products?.map(product => {
                    return <p>{`${product.code} - ${product.name}`}</p>
                  })}
                </>
              }
              arrow
            >
              <Box
                style={{
                  color: PaletteColors.textDark,
                  textDecorationLine: 'underline',
                  textDecorationColor: 'grey',
                  textDecorationStyle: 'dotted',
                  textDecorationThickness: '0.1em',
                  textUnderlineOffset: '3px',
                }}
              >
                {e.products[0]?.chemical?.name}
              </Box>
            </HtmlTooltip>
          </Box>
        )
      }
      if (e.products[0]?.name) {
        return (
          <Box>
            <Box style={{ color: PaletteColors.textDark }}>
              {e.products[0]?.name}
            </Box>
          </Box>
        )
      }
      return ''
    },
  },
  // {
  //   label: 'City',
  //   value: e => e.location?.city,
  // },
  {
    label: 'Type',
    value: e => {
      const maps = { loadingTank: 'Loading', unloadingTank: 'Unloading' }
      return Object.keys(maps)
        .filter(r => e[r])
        .map(p => maps[p])
        .join(' / ')
    },
  },
  {
    label: 'QR Code',
    value: e => {
      return (
        <img
          alt="qr"
          src={Tankl}
          style={{ width: '3rem' }}
          onClick={event => {
            event.stopPropagation()
            // handleOpenDialog()
          }}
        />
      )
    },
    maxWidth: 300,
  },
]

const customer_tank_columns = [
  columns[0],
  columns[1],

  // {
  //   label: 'City',
  //   value: e => e.customer.customer_location.city,
  // },
  { ...columns[3], label: 'Type' },
  { label: 'QR code', value: e => 2 },
]

function Tanks({ type, apiResult, selectable, onSelectRows }) {
  const history = useHistory()
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  let col = columns
  if (type === 'partner') {
    col = [...col[0], ...col.slice(2)]
  }

  useEffect(() => {
    setSelectedRows(prev => prev.map(e => selectedAll))
  }, [selectedAll])

  useEffect(() => {
    if (apiResult.isResolved)
      setSelectedRows(apiResult.data?.results?.map(e => false))
  }, [apiResult.isResolved])
  return (
    <Container>
      <Scrollable>
        <TableHead
          columns={type === 'tenant' ? columns : customer_tank_columns}
        />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={type === 'tenant' ? columns : customer_tank_columns}
            data={apiResult.data?.results}
            rowOnClick={row =>
              history.push(`${routes.MY_COMPANY_TANK_DETAILS}/${row.tankRegistrationNo}`)
            }
          />
        )}
      </Scrollable>
    </Container>
  )
}

Tanks.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

Tanks.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default Tanks
