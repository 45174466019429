import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import routes from '../routes/route_contants'
import useStateValue from '../hooks/useStateValue'
import Loading from './Loading'
import { isToRedirectMobile } from './RedirectApp'
import Links from '../helpers/linking'
import useAPILocal from '../hooks/useAPILocal'
import authAPI from '../api/auth'

const Auth = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()
  const [ isAuthorizationLoading, setAuthorizationLoading ] = useState(true);

  let invite_info = Cookies.get('invite_info') || {}
  if (Object.keys(invite_info).length > 0) {
    invite_info = JSON.parse(invite_info)
  }
  /* apis */
  const auth = useAPILocal(authAPI.authorize)

  const history = useHistory()
  const [, dispatch] = useStateValue()

  const authorizeApi = async () => {
    const token = await getAccessTokenSilently()
    if (token !== '') {
      const result = await auth.request({ token })

      if (!result.ok) {
        dispatch({ type: 'SET_AUTH_ERROR', payload: true })
        return null
      } else {
        setAuthorizationLoading(false);
        history.push('/orders')
      }
      const {
        data: { user_info },
      } = result.data

      // store jwt in cookie
      Cookies.set('access_token', result.data?.data?.token, { expires: 4 })

      dispatch({
        type: 'USER_DETAILS',
        user: result.data?.data,
      })

      // redirect to mobile app , if user on mobile(browser)
      let continue_route

      if (user_info?.role?.role_id === 0) {
        continue_route = routes.TENANTS
        // super user does not exists in mobile, so no-redirection
        return history.push(continue_route)
      }
      if (user_info?.role?.role_id === 1) {
        continue_route = routes.ORDER_LIST
      } else {
        continue_route = routes.SHIPMENTS_LIST
      }

      if (user_info?.role?.role_id === 1 && !user_info?.tenant?.slug) {
        continue_route = routes.SHIPMENTS_LIST
      }

      // redirect middleware page
      if (isToRedirectMobile()) {
        return history.push(routes.REDIRECT_APP, {
          link: Links.LOGIN,
          token,
          continue_route,
        })
      }

      return history.push(continue_route)
    }
    return {}
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        // call the auth.authorize api
        authorizeApi()
      } catch (error) {
        // console.log(error)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    return () => {
      if (Cookies.get('invite_info')) {
        Cookies.remove('invite_info')
      }
    }
  }, [])

  if (isLoading || isAuthorizationLoading) {
    return <Loading fullScreen text={"Logging in"}/>
  }

  return <></>
}

export default Auth
