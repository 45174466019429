import themeColors from './themeColors.scss'

export default function colorPalette() {
  return {
    primary: {
      light: themeColors.primaryLight,
      main: themeColors.primaryMain,
      dark: themeColors.primaryDark,
      contrastText: themeColors.white,
    },
    secondary: {
      light: themeColors.secondaryLight,
      main: themeColors.secondaryMain,
      dark: themeColors.secondaryDark,
      contrastText: themeColors.white,
    },
    error: { main: themeColors.errorMain },
    text: {
      primary: themeColors.textDark,
      secondary: themeColors.textMedium,
      hint: themeColors.textLight,
    },
  }
}
