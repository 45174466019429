import { useLocation } from 'react-router'
import routes from '../routes/route_contants'
import useRole from './useRole'
import TankIcon from '../assets/Navigation/T2T Tanks.svg'
import TankIconWhite from '../assets/Navigation/T2T tanks white.svg'
import OrderIcon from '../assets/Navigation/T2T Orders.svg'
import OrderIconWhite from '../assets/Navigation/T2T BOL2.svg'
import LocationIcon from '../assets/Location.png'
import ProductIcon from '../assets/Product.png'
import ShipmentIcon from '../assets/Navigation/T2T Shipment.svg'
import ShipmentIconWhite from '../assets/Navigation/T2T ShipmentWhite.svg'
import TeamMembersIcon from '../assets/Navigation/T2T Team Members.svg'
import MyCompanyIcon from '../assets/Navigation/T2T my company.svg'
import MyCompanyIconWhite from '../assets/Navigation/T2T comp.svg'
// import Invitation from '../assets/Navigation/T2T Invitation.svg'
import Invitation from '../assets/Navigation/t2t-invitation.png'
import WhiteInvitation from '../assets/Navigation/white-invitation.svg'
import ConnectionIcon from '../assets/Navigation/Connections.svg'
import ConnectionsWhiteIcon from '../assets/Navigation/ConnectionsWhite.svg'
import ChecklistIcon from '../assets/Navigation/Checklist Active.svg'
import ChecklistWhiteIcon from '../assets/Navigation/Checklist Inactive.svg'

const navigations = role => {
  switch (role) {
    case 'admin':
      return {
        [routes.ORDER_LIST]: {
          name: `Orders`,
          routeName: 'orders',
          icon: OrderIcon,
          iconWhite: OrderIconWhite,
          description: 'View and add Orders',
        },
        [routes.SHIPMENTS_LIST]: {
          name: 'Shipments',
          routeName: 'shipments',
          icon: ShipmentIconWhite,
          iconWhite: ShipmentIcon,
          description: 'View all orders in a shipment',
        },
        [routes.CONNECTIONS]: {
          name: 'Connections',
          routeName: 'connections',
          icon: ConnectionIcon,
          iconWhite: ConnectionsWhiteIcon,
          description: 'View & add connections to other companies',
        },
        [routes.CHECK_LIST]: {
          name: 'Checklist',
          routeName: 'checklist',
          icon: ChecklistIcon,
          iconWhite: ChecklistWhiteIcon,
          description:
            'View & add delivery checklists by product, tank, location',
        },
        [routes.TANKS_LIST]: {
          name: 'Tanks & Products',
          routeName: 'tanks&products',
          icon: TankIcon,
          iconWhite: TankIconWhite,
          description: 'View and add Tanks/Products',
        },
        [routes.MY_COMPANY]: {
          name: 'My Account',
          routeName: 'my-account',
          icon: MyCompanyIcon,
          iconWhite: MyCompanyIconWhite,
          description: 'My Account Details',
        },
      }
    case 'driver':
      return {}
    case 'superadmin':
      return {
        [routes.TENANTS]: {
          name: 'Tenants',
          routeName: 'tenants',
          icon: Invitation,
          iconWhite: TankIconWhite,
          description: 'Tenants Details',
        },
        [routes.CHEMICAL_LIST]: {
          name: 'Chemicals',
          routeName: 'chemicals',
          icon: Invitation,
          iconWhite: OrderIconWhite,
          description: 'Chemicals Details',
        },
      }
    default:
      return {}
  }
}

function useRouteDetails() {
  const location = useLocation()
  let { roleObj: role } = useRole()

  if (!role) {
    role = { role_name: 'admin' }
  }
  const path = location?.pathname
    ?.slice(1, location?.pathname?.length)
    ?.split('/')?.[0]
    ?.toLowerCase()

  return {
    path,
    current: navigations(role.role_name)[`/${path}`],
    allRoutes: navigations(role.role_name),
  }
}

export default useRouteDetails
