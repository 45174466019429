import { useEffect } from 'react'
import { useState } from 'reinspect'

function useCurrentLocation() {
  const [location, setLocation] = useState(null)
  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      data => {
        setLocation({
          latitude: data.coords.latitude,
          longitude: data.coords.longitude,
        })
      },
      e => console.warn(e)
    )
  }, [])
  return location
}

export default useCurrentLocation
