



import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import Fixed from '../../../../Reusable/Fixed'
import Scrollable from '../../../../Reusable/Scrollable'
import Container from '../../../../Reusable/Container'
import PageHeading from '../../../../Reusable/PageHeading'
import SearchBar from '../../../../Reusable/Widgets/SearchBar'
import Partners from './Partners'
import connectionsAPI from '../../../../../api/connections'
import withPaginationLocal from '../../../../../HOC/withPaginationLocal'

function PartnerList() {
  const [searchStr, setSearchStr] = useState(undefined)

  const PaginatedPartner = withPaginationLocal(connectionsAPI.getPartners, {
    search: searchStr || '',
  })(Partners)

  const handleSearch = val => {
    if (val?.length >= 3) {
      setSearchStr(val)
    } else {
      if (val?.length === 0) {
        setSearchStr(undefined)
      }
    }
  }

  return (
    <Container>
      <Fixed>
        <Box>
          <PageHeading alignItems="center" style={{ marginTop: '5px' }}>
            <Typography
              style={{
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#1A1A1A',
              }}
            >
              All Partners
            </Typography>
            <SearchBar onChange={handleSearch} />
          </PageHeading>
        </Box>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <PaginatedPartner />
      </Scrollable>
    </Container>
  )
}

PartnerList.propTypes = {}

PartnerList.defaultProps = {}

export default PartnerList
