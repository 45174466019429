

import { Box, Typography, Button } from '@material-ui/core'
import { Stack } from '@mui/material'
// import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import Modal from '../../Modal'
import AutoCompleteInput from '../../Reusable/Widgets/AutoCompleteInput'
import CheckBoxInput from '../../Reusable/Widgets/CheckBoxInput'
import { AddQuestion } from '../styles'


const DetailCard = ({
  rows,
  setRows,
  idField,
  nameField,
  api,
  renderItem,
  type = 'Product',
}) => {
  const [text, setText] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      display="flex"
      height="100%"
      style={{
        backgroundColor: '#F8FAFC',
        borderRadius: '7px',
        padding: '20px',
        flexDirection: 'column',
        marginBottom: '10px',
      }}
    >
      <Typography
        style={{
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '18px',
          color: '#003366',
        }}
      >
        {type} Details
      </Typography>
      <CheckBoxInput
        label="All"
        value={!!rows.all}
        onChange={e => {
          setRows({
            ...rows,
            all: e,
            data: [],
          })
        }}
        showLabel="Assign To"
        checklist
      />
      <Box
        display="flex"
        style={{
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {rows.data.map((item, index) => (
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            marginBottom={2}
            key={index}
            style={{
              border: '1px solid #A3A2A2',
              margin: '10px 0',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                backgroundColor: '#F4F4F4',
                padding: '10px',
                borderRadius: '30px',
                width: '95%',
              }}
            >
              <Typography> {item?.name}</Typography>
            </div>
            <DeleteIcon
              width={20}
              height={20}
              style={{ color: '#000', cursor: 'pointer'}}
              onClick={() =>
                setRows({
                  ...rows,
                  data: rows.data.filter(item1 => item?.id !== item1?.id),
                })
              }
            />
          </Stack>
        ))}
        <AddQuestion
          onClick={() => setIsOpen(true)}
          style={{ marginLeft: '50px' }}
        >
          Assign More {type}
        </AddQuestion>
        <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
          <div>
            <AutoCompleteInput
              flex="15"
              hidden={!isOpen}
              label={`${type} Name`}
              api={api}
              renderItem={renderItem}
              value={text}
              onChange={val => {
                setText(val)
              }}
            />
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="contained"
              color="primary"
              disabled={!text}
              onClick={() => {
                setRows({
                  ...rows,
                  all: false,
                  data: [
                    ...rows.data,
                    { id: text?.[idField], name: text?.[nameField] },
                  ],
                })
                setText(null)
                setIsOpen(false)
              }}
            >
              Assign
            </Button>
            <Button
              style={{
                marginTop: '20px',
                marginRight: '15px',
              }}
              variant="outlined"
              color="primary"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Box>
    </Box>
  )
}

export default DetailCard
