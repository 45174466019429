
import { Box, Button, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import productAPI from '../../../../../api/product'
import withPaginationLocal from '../../../../../HOC/withPaginationLocal'
import Container from '../../../../Reusable/Container'
import Fixed from '../../../../Reusable/Fixed'
import PageHeading from '../../../../Reusable/PageHeading'
import Scrollable from '../../../../Reusable/Scrollable'
import SearchBar from '../../../../Reusable/Widgets/SearchBar'
import TextInput from '../../../../Reusable/Widgets/TextInput'
import BuyerProducts from './BuyerProducts'

const Buyer = () => {
  const [searchStr, setSearchStr] = useState(undefined)
  const BuyerProductList = withPaginationLocal(productAPI.listSharedOwn, {
    search: searchStr || '',
    view: 'BUYING',
  })(BuyerProducts)

  const handleSearch = val => {
    if (val?.length >= 3) {
      setSearchStr(val)
    } else {
      if (val?.length === 0) {
        setSearchStr(undefined)
      }
    }
  }

  return (
    <>
      {/* <div
        style={{
          padding: '40px',
          backgroundColor: '#F8FAFC',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Typography>I'm looking for</Typography>
        <TextInput placeholder="Enter Product Name" label={false} />
        <Typography>/</Typography>
        <TextInput placeholder="Enter UN Product Name" label={false} />
        <Button
          variant="contained"
          color="primary"
          // onClick={toggleModal}
        >
          Add to list
        </Button>
      </div> */}
      <Container>
        <Fixed mb={2}>
          <PageHeading alignItems="center" style={{ marginTop: '5px' }}>
            <Typography
              style={{
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#1A1A1A',
              }}
            >
              Product list (looking for)
            </Typography>
            <Box display="flex">
              <Box mr={2}>
                <SearchBar onChange={handleSearch} />
              </Box>
            </Box>
          </PageHeading>
        </Fixed>
        <Scrollable style={{ height: '100%' }}>
          <BuyerProductList />
        </Scrollable>
      </Container>
    </>
  )
}

export default Buyer
