import { Box, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { deafultFunc } from '../../theme/config'
import Typography from "@mui/material/Typography";

function TableBody({
  data,
  columns,
  rowOnClick,
  selectable,
  onSelectRows,
  selectedRows,
}) {
  const [rowSelected, setRowSelected] = useState(data?.map(e => false))
  const handleRowClick = row => {
    return function (e) {
      if (e.target.type === 'checkbox') {
        e.stopPropagation()
        return false
      }
      rowOnClick(row)
    }
  }
  const handleRowSelect = i => {
    return () => {
      setRowSelected(prev => {
        const newSelected = [...prev]
        newSelected[i] = !newSelected[i]
        return newSelected
      })
      return false
    }
  }

  useEffect(() => {
    if (rowSelected && onSelectRows)
      onSelectRows(
        rowSelected.map((e, index) => (e ? data[index] : null)).filter(e => e)
      )
  }, [rowSelected])

  useEffect(() => {
    if (selectedRows?.length > 0) setRowSelected(selectedRows)
  }, [selectedRows])

  return (
    <Box className="table-body">
      {data?.map((row, i) => {
        const key = `table-row-${i}`

        return (
          <Box
            className="table-body-row"
            key={key}
            onClick={handleRowClick(row)}
          >
            {selectable && (
              <Box className="table-body-column">
                <Checkbox
                  checked={rowSelected?.[i]}
                  onChange={handleRowSelect(i)}
                />
              </Box>
            )}
            {columns?.map((column, index) => {
              const key2 = `table-row-${column.label}-${index}`
              return (
                <Box
                  className="table-body-column"
                  flex={column.flex || 1}
                  style={{
                    minWidth: column.minWidth,
                    width: 1 / columns.length,
                  }}
                  key={key2}
                >
                  {column?.value(row)}
                </Box>
              )
            })}
          </Box>
        )
      })}
      {data?.length === 0 && (
        <Box component="h3" m={2} className="flex-align-middle">
          <Typography variant="body1">No results found...</Typography>
        </Box>
      )}
    </Box>
  )
}

TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowOnClick: PropTypes.func,
}

TableBody.defaultProps = {
  data: null,
  rowOnClick: deafultFunc,
}

export default TableBody
