import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from '@material-ui/core'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { useHistory } from 'react-router-dom'
import Clear from '@material-ui/icons/Clear'
import { CONSTANTS } from './helpers'
import Icon from '../Icon'
import routes from '../../routes/route_contants'
import IconButton from '../Icon/IconButton'
import { throttled } from '../../helpers/util'
import Dropdown from './dropdown'
import useDimension from '../../hooks/useDimension'
import useRole from '../../hooks/useRole'
import TextInput from '../Reusable/Widgets/TextInput'
import useAPILocal from '../../hooks/useAPILocal'
import searchAPI from '../../api/search'

const Heading = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  color: '#898989',
  margin: '10px 0px',
})

const SearchBox = styled(Box)({
  backgroundColor: '#F8FAFC',
  borderRadius: '10px',
  padding: '10px',
  cursor: 'pointer',
})

const SearchResult = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const Title = styled('p')({
  fontSize: '14px',
  lineHeight: '17px',
  color: '#1A1A1A',
  fontWeight: 500,
})

const init = {
  bols: [],
  products: [],
  tenants: [],
  contacts: [],
  partners: [],
}

function Search({ dialogOpen: open, onClose }) {
  const [isDropdown, setIsDropdown] = useState(false)
  const [searchResults, setSearchResults] = useState(init)
  const [text, setText] = useState('')
  const inputRef = useRef(null)
  const history = useHistory()

  const searchApi = useAPILocal(searchAPI.globalSearch)
  const { isMobile, isDesktop, isTablet } = useDimension()
  const [searchType, setSearchType] = useState('All')
  const call = async value => {
    // searchApi.reset()

    await searchApi
      .request({ search: value, limit: 10 })
      .then(res => setSearchResults(res?.data))
  }

  const handleKeyPress = async value => {
    setText(value)
  }

  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      if (text.trim()) call(text)
    }
  }

  const resetAll = () => {
    searchApi.reset()
    setSearchResults(init)
    setIsDropdown(false)
    setText('')
  }

  useEffect(() => {
    if (text.trim()) throttled(() => call(text), 0.5)
    else resetAll()
  }, [text])

  useEffect(() => {
    if (searchApi.isResolved || searchAPI.isRejected || searchApi.isPending) {
      setIsDropdown(true)
    }
  }, [searchApi.isResolved, searchApi.isPending, searchApi.isRejected])

  if (!open) return null

  return (
    // {isMobile && (
    //   <IconButton
    //     onClick={() => {
    //       return onClose()
    //     }}
    //     style={{
    //       cursor: 'pointer',
    //       padding: '8px 5px',
    //       width: '30px',
    //       display: 'block',
    //     }}
    //   >
    //     <Icon name="arrow_back" />
    //   </IconButton>
    // )}
    <Dropdown
      isResolved={
        searchApi.isResolved &&
        searchResults?.bols?.length === 0 &&
        searchResults?.products?.length === 0 &&
        searchResults?.tenants?.length === 0 &&
        searchResults?.contacts?.length === 0 &&
        searchResults?.partners?.length === 0
      }
      isRejected={searchApi.isRejected}
      visible={isDropdown}
      parent={
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '10px',
            alignItems: 'center',
            paddingRight: '5px',
            position: 'fixed',
            top: '25px',
            width: '56%',
          }}
        >
          <form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
            }}
            style={{
              width: '70%',
            }}
          >
            <TextInput
              ref={inputRef}
              placeholder="Search BOL, product, tenant..."
              type="search"
              autoComplete="street-address"
              value={text}
              label={false}
              onChange={handleKeyPress}
              onKeyDown={handleKeyDown}
              noBorder
              isDropdown={isDropdown}
            />
          </form>
          <div
            style={{
              borderBottomRightRadius: '10px',
              borderTopRightRadius: '10px',
              alignItems: 'center',
              height: '43px',
              paddingRight: '10px',
              borderLeft: 'none',
              borderTop: '1.7px solid #f3f3f3',
              borderBottom: '1.7px solid #f3f3f3',
              borderRight: '1.7px solid #f3f3f3',
            }}
          >
            <Clear
              style={{
                color: '#003366',
                width: '15px',
                marginTop: '9px',
                display: text?.length > 0 ? null : 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                resetAll()
                setIsDropdown(false)
              }}
            />
          </div>
          <div
            className="dash-loading"
            style={{ opacity: searchApi.isPending ? 1 : 0 }}
          ></div>
        </Box>
      }
    >
      <div
        style={{
          height: isMobile ? '100%' : 'inherit',
          flexDirection: 'column',
        }}
      >
        {/* Messages if results not found */}
        {searchApi.isPending && (
          <Box className="flex flex-align-middle" p={10}>
            <Box className="flex flex-align-middle" flexDirection="column">
              <CircularProgress size={22} />
              <Typography variant="caption">Loading results...</Typography>
            </Box>
          </Box>
        )}
        {searchApi.isRejected && (
          <div style={{ padding: '15px' }}>
            <Title>
              Ops some error occured while doing search, please try again.
            </Title>
          </div>
        )}
        {searchApi.isResolved &&
          searchResults?.bols?.length === 0 &&
          searchResults?.products?.length === 0 &&
          searchResults?.tenants?.length === 0 &&
          searchResults?.contacts?.length === 0 &&
          searchResults?.partners?.length === 0 && (
            <div style={{ padding: '15px' }}>
              <Title>
                We could not find match for "{text}", please try another search.
              </Title>
            </div>
          )}

        {searchApi.isResolved &&
          (searchResults?.bols?.length !== 0 ||
            searchResults?.products?.length !== 0 ||
            searchResults?.tenants?.length !== 0 ||
            (searchResults?.contacts?.length !== 0) |
              (searchResults?.partners?.length !== 0)) && (
            <div style={{ padding: '20px' }}>
              <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                  borderRadius: '12px',
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('All')}
                  style={{
                    border: `1px solid ${
                      searchType === 'All' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${searchType === 'All' ? '#3298FF' : '#6D6D6D'}`,
                  }}
                >
                  All
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('BOL')}
                  style={{
                    border: `1px solid ${
                      searchType === 'BOL' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${searchType === 'BOL' ? '#3298FF' : '#6D6D6D'}`,
                  }}
                >
                  BOL
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('Tenant')}
                  style={{
                    border: `1px solid ${
                      searchType === 'Tenant' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${searchType === 'Tenant' ? '#3298FF' : '#6D6D6D'}`,
                  }}
                >
                  Tenant
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('Product')}
                  style={{
                    border: `1px solid ${
                      searchType === 'Product' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${
                      searchType === 'Product' ? '#3298FF' : '#6D6D6D'
                    }`,
                  }}
                >
                  Product
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('Contact')}
                  style={{
                    border: `1px solid ${
                      searchType === 'Contact' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${
                      searchType === 'Contact' ? '#3298FF' : '#6D6D6D'
                    }`,
                  }}
                >
                  Contact
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchType('Partner')}
                  style={{
                    border: `1px solid ${
                      searchType === 'Partner' ? '#3298FF' : '#6D6D6D'
                    }`,
                    color: `${
                      searchType === 'Partner' ? '#3298FF' : '#6D6D6D'
                    }`,
                  }}
                >
                  Partner
                </Button>
              </Box>
              {searchType === 'All' && (
                <>
                  {searchResults?.bols?.length > 0 && <Heading>BOL</Heading>}
                  <SearchResult>
                    {searchResults?.bols?.map((item, index) => (
                      <SearchBox
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.ORDER_DETAILS}/${item.id}`)
                          resetAll()
                        }}
                        key={index}
                      >
                        <Title>
                          {`${item.bolNo} -  ${item?.shipper?.tenant_name}`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>

                  {searchResults?.tenants?.length > 0 && (
                    <Heading>Tenant</Heading>
                  )}
                  <SearchResult>
                    {searchResults?.tenants?.map((item, index) => (
                      <SearchBox
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(
                            `${routes.CONNECTION_PARTNER_PAGE}/${item?.partnershipId}`,
                            {
                              id: item?.partnershipId,
                            }
                          )
                          resetAll()
                        }}
                        key={index}
                      >
                        <Title>
                          {`${item.tenant_id?.slice(0, 5)} - ${
                            item?.tenant_name
                          }`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>

                  {searchResults?.products?.length > 0 && (
                    <Heading>Product</Heading>
                  )}
                  <SearchResult>
                    {searchResults?.products?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.PRODUCT_DETAILS}/${item?.id}`)
                          resetAll()
                        }}
                      >
                        <Title>{`${item.unNumber} - ${item?.name}`}</Title>
                      </SearchBox>
                    ))}
                  </SearchResult>

                  {searchResults?.contacts?.length > 0 && (
                    <Heading>Contact</Heading>
                  )}
                  <SearchResult>
                    {searchResults?.contacts?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.CONTACT_PAGE}/${item.id}`)
                          resetAll()
                        }}
                      >
                        <Title>{`${item?.name} ${item?.surname} - ${item?.jobTitle}`}</Title>
                      </SearchBox>
                    ))}
                  </SearchResult>

                  {searchResults?.partners?.length > 0 && (
                    <Heading>Partner</Heading>
                  )}
                  <SearchResult>
                    {searchResults?.partners?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(
                            `${routes.CONNECTION_PARTNER_PAGE}/${item?.id}`,
                            {
                              id: item?.id,
                            }
                          )
                          resetAll()
                        }}
                      >
                        <Title>
                          {`${item?.partner?.tenant_id?.slice(0, 5)} - ${
                            item?.partner?.tenant_name
                          }`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}
              {searchType === 'BOL' && (
                <>
                  {searchResults?.bols?.length > 0 ? (
                    <Heading>BOL</Heading>
                  ) : (
                    <Title>No result found for BOL, please try again</Title>
                  )}

                  <SearchResult>
                    {searchResults?.bols?.map((item, index) => (
                      <SearchBox
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.ORDER_DETAILS}/${item.id}`)
                          resetAll()
                        }}
                        key={index}
                      >
                        <Title>
                          {`${item.bolNo} -  ${item?.shipper?.tenant_name}`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}

              {searchType === 'Tenant' && (
                <>
                  {searchResults?.tenants?.length > 0 ? (
                    <Heading>Tenant</Heading>
                  ) : (
                    <Title>No result found for tenants, please try again</Title>
                  )}
                  <SearchResult>
                    {searchResults?.tenants?.map((item, index) => (
                      <SearchBox
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(
                            `${routes.CONNECTION_PARTNER_PAGE}/${item?.partnershipId}`,
                            {
                              id: item?.partnershipId,
                            }
                          )
                          resetAll()
                        }}
                        key={index}
                      >
                        <Title>
                          {`${item.tenant_id?.slice(0, 5)} - ${
                            item?.tenant_name
                          }`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}

              {searchType === 'Product' && (
                <>
                  {searchResults?.products?.length > 0 ? (
                    <Heading>Product</Heading>
                  ) : (
                    <Title>
                      No result found for products, please try again
                    </Title>
                  )}

                  <SearchResult>
                    {searchResults?.products?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.PRODUCT_DETAILS}/${item?.id}`)
                          resetAll()
                        }}
                      >
                        <Title>{`${item.unNumber} - ${item?.name}`}</Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}

              {searchType === 'Contact' && (
                <>
                  {searchResults?.contacts?.length > 0 ? (
                    <Heading>Contacts</Heading>
                  ) : (
                    <Title>
                      No result found for contacts, please try again
                    </Title>
                  )}

                  <SearchResult>
                    {searchResults?.contacts?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(`${routes.CONTACT_PAGE}/${item.id}`)
                          resetAll()
                        }}
                      >
                        <Title>{`${item?.name} ${item?.surname} - ${item?.jobTitle}`}</Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}

              {searchType === 'Partner' && (
                <>
                  {searchResults?.partners?.length > 0 ? (
                    <Heading>Partners</Heading>
                  ) : (
                    <Title>
                      No result found for partners, please try again
                    </Title>
                  )}

                  <SearchResult>
                    {searchResults?.partners?.map((item, index) => (
                      <SearchBox
                        key={index}
                        onClick={() => {
                          setIsDropdown(false)
                          history.push(
                            `${routes.CONNECTION_PARTNER_PAGE}/${item?.id}`,
                            {
                              id: item?.id,
                            }
                          )
                          resetAll()
                        }}
                      >
                        <Title>
                          {' '}
                          {`${item?.partner?.tenant_id?.slice(0, 5)} - ${
                            item?.partner?.tenant_name
                          }`}
                        </Title>
                      </SearchBox>
                    ))}
                  </SearchResult>
                </>
              )}
            </div>
          )}
      </div>
    </Dropdown>
  )
}

Search.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

Search.defaultProps = {}

export default Search
