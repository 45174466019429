import {
  defaultCheckboxLabel,
  fieldVisible,
  IAutoComplete,
  ISelect,
  IText,
  Measurement,
} from './utils'

const step5 = [
  {
    key: 'Plant side connection',
    visible: fieldVisible,
    children: [
      new ISelect(
        'plantSideConnection',
        'Plant side connection',
        '',
        undefined,
        [
          { value: 'flange', label: 'Flange' },
          { value: 'fcg', label: 'FCG' },
          { value: 'mcg', label: 'MCG' },
        ]
      ),
      new ISelect('unloadingMethod', 'Unloading Method', '', undefined, [
        { value: 'truck_air', label: 'Truck air' },
        { value: 'customer_supplied_air', label: 'Customer supplied air' },
        { value: 'pump', label: 'Pump' },
      ]),
    ],
  },
  Measurement(
    'deliveryConnectionSize',
    'Delivery connection size',
    'deliveryConnectionSizeUnit',
    'diameter'
  ),
  Measurement(
    'verticalLiftRequired',
    'Vertical Lift',
    'verticalLiftRequiredUnit',
    'diameter'
  ),
  {
    key: 'Is Air filtered',
    visible: fieldVisible,
    children: [
      {
        type: 'check',
        initial: false,
        label: 'Air regulated to less than 30 psi',
        name: 'airRegulatedToLessThan30Psi',
      },
      {
        type: 'check',
        initial: false,
        label: 'Is air filtered ?',
        name: 'isTheAirFiltered',
        showLabel: false,
      },
    ],
  },
  {
    key: 'Is Air filtered',
    visible: fieldVisible,
    children: [
      {
        type: 'check',
        initial: false,
        label: 'Does truck need to scale in and out ?',
        name: 'truckScalingInOutRequired',
        showLabel: defaultCheckboxLabel,
      },
    ],
  },
  {
    key: 'Prevent collapse',
    visible: fieldVisible,
    children: [
      new IText(
        'procedureToPreventTrailerCollapse',
        'Procedure to prevent trailer collapse',
        ''
      ),
      new IText('samplingDetails', 'Sampling details', ''),
    ],
  },
]

export default step5
