import ROUTES_PATH from './route_contants'
import { Auth, RedirectApp, Welcome } from '../component'
import ChangePassword from '../component/Profile/ChangePassword'

const Common = [
  {
    path: ROUTES_PATH.WELCOME,
    component: Welcome,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.REDIRECT_APP,
    component: RedirectApp,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.AUTH,
    component: Auth,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.CHANGE_PASSWORD,
    component: ChangePassword,
  },
  // {
  //   path: ROUTES_PATH.INVITATION,
  //   component: CompanyInvitation,
  //   isProtected: true,
  // },
  // {
  //   path: `${ROUTES_PATH.SUPER_INVITATION_DETAILS}/:invite_id`,
  //   component: InvitationDetails,
  //   isProtected: true,
  // },
]

export default Common
