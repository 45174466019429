import userAPI from '../../../../api/user'
import { fieldVisible, MultipleAutoComplete } from './utils'

const step4 = [
  {
    key: 'Responsible for spill management plan',
    visible: fieldVisible,
    children: [
      new MultipleAutoComplete(
        'spillManagementPlanAvailable',
        'Who is responsible for spill management plan ?',
        '',
        '',
        {
          src: userAPI.list,
          params: {},
        },
        option => {
          return option?.email || option
        },
        'Person',
        {
          placeholder: 'eg. user@company.com',
        }
      ),
    ],
  },
  {
    key: 'Staying with driver',
    visible: fieldVisible,
    children: [
      new MultipleAutoComplete(
        'doesSomeoneStayWithDriverDuringOffloading',
        'Does someone stay with driver during offloading ?',
        '',
        '',
        {
          src: userAPI.list,
          params: {},
        },
        option => {
          return option?.email || option
        },
        'Person',
        {
          placeholder: 'eg. user@company.com',
        }
      ),
    ],
  },
]

export default step4
