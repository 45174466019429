import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function Fixed({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>
}

Fixed.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Fixed
