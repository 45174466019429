import client from '../../hooks/useClient'

const connectionsAPI = {}

connectionsAPI.getPartners = data => {
  return client.get(`/partners?search=${data.search}`)
}

connectionsAPI.partnerDetails = partnershipId => {
  return client.get(`/partners/${partnershipId}`)
}

connectionsAPI.buyers = () => {
  return client.get(`/partners/buyers`)
}

connectionsAPI.sellers = () => {
  return client.get(`/partners/sellers`)
}

connectionsAPI.incomingRequests = () => {
  return client.get(`/partners/incoming-requests`)
}

connectionsAPI.outgoingRequests = () => {
  return client.get(`/partners/outgoing-requests`)
}

connectionsAPI.requestPartnership = data => {
  return client.post(
    `/partners/request-partnership/?tenantId=${data?.tenantId}&note=${data?.note}&connectionToken=${data?.connectionToken}`
  )
}

connectionsAPI.acceptPartnership = partnershipId => {
  return client.patch(`/partners/${partnershipId}/accept`)
}

connectionsAPI.rejectPartnership = partnershipId => {
  return client.patch(`/partners/${partnershipId}/decline`)
}

connectionsAPI.deletePartnership = partnershipId => {
  return client.delete(`/partners/${partnershipId}`)
}

connectionsAPI.invitePartner = data => {
  return client.post(`/partners/invite-partner/?email=${data.adminEmail}&tenantName=${data.tenantName}&tenantEin=${data.tenantEin}
  &isSeller=${data.seller}&isBuyer=${data.buyer}&isCarrier=${data.carrier}`)
}

connectionsAPI.partnerTanks = data => {
  return client.get(
    `/partners/${data.partnershipId}/tanks?tankType=${data.tankType}`
  )
}

connectionsAPI.partnerProducts = data => {
  return client.get(`/partners/${data.partnershipId}/products`)
}

connectionsAPI.partnerLocations = data => {
  return client.get(`/partners/${data.partnershipId}/locations`)
}

connectionsAPI.partnerContacts = data => {
  return client.get(`/partners/${data.partnershipId}/contacts`)
}

connectionsAPI.sharedResources = data => {
  return client.post(
    `/partners/shared-resources?entityIds=${data.entityIds}&visibility=${data.visibility}&entityType=${data.entityType}&partnerIds=${data.partnerIds}`
  )
}

export default connectionsAPI
