import { fields } from '../../helpers/fields'
import filereaderWorker from './filereaderWorker'

const addScript = link => {
  const script = document.createElement('script')
  script.src = link
  document.head.appendChild(script)
}

const init = () => {
  const exists = [...document.scripts]
    .map(e => e.src)
    .includes('https://unpkg.com/xlsx@0.17.0/dist/xlsx.full.min.js')
  if (exists) {
    return
  }
  addScript('https://unpkg.com/xlsx@0.17.0/dist/xlsx.full.min.js')
  addScript('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.5/jszip.js')
}

const code = filereaderWorker.toString()

const url = URL.createObjectURL(new Blob([`(${code})()`]))

const worker = new Worker(url)

export const parseExcel = function (file, sheet = null) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      const data = e.target.result

      worker.postMessage({ file: data, sheet, type: 'workbook' })
      worker.onmessage = m => {
        resolve(m.data.workbook)
      }
    }

    reader.onerror = ex => {}
    reader.readAsBinaryString(file)
  })
}

export function get_header_row(sheet) {
  return new Promise((resolve, reject) => {
    worker.postMessage({ type: 'get_header', sheet })
    worker.onmessage = m => {
      resolve(m.data.header)
    }
  })
}

export function checkHeaders(headers) {
  const shipmentHeaders = fields.shipment
    .filter(e => !e.optional)
    .map(f => f.value)

  return Promise.resolve(shipmentHeaders.every(e => headers.includes(e)))
}

export function get_sheet_data(sheet) {
  return new Promise((resolve, reject) => {
    worker.postMessage({ type: 'sheet_to_array', sheet })
    worker.onmessage = m => {
      resolve(m.data.sheetData)
    }
  })
}

export function json_to_sheet(json) {
  const header = fields.shipment.map(e => e.value)
  return new Promise((resolve, reject) => {
    worker.postMessage({ type: 'json_to_sheet', json, header })
    worker.onmessage = m => {
      resolve(m.data.sheet)
    }
  })
}

export function sheet_to_csv(sheet) {
  return new Promise((resolve, reject) => {
    worker.postMessage({ type: 'sheet_to_csv', sheet })
    worker.onmessage = m => {
      resolve(m.data.csv)
    }
  })
}

export function remove_unused_fields(data) {
  const shipmentFields = fields.shipment.reduce((acc, e) => {
    acc[e.value.toUpperCase()] = ''
    return acc
  }, {})
  return new Promise(resolve => {
    worker.postMessage({
      type: 'remove_unused_fields',
      json: data,
      fields: shipmentFields,
    })
    worker.onmessage = m => {
      resolve(m.data.finalJSON)
    }
  })
}

init()
