import client from '../../hooks/useClient'

const fileAPI = {}

fileAPI.getFile = fileId => {
  return client.get(`files/${fileId}?base64=true`)
}

fileAPI.getFileMiniature = fileId => {
  return client.get(`files/${fileId}?base64=true&miniature=true`)
}

export default fileAPI
