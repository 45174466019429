import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function Scrollable({ children, style, ...rest }) {
  return (
    <Box style={{ flex: 1, overflow: 'auto', ...style }} {...rest}>
      {children}
    </Box>
  )
}

Scrollable.propTypes = {
  ...PropTypes.instanceOf(Element),
  children: PropTypes.any.isRequired,
}

export default Scrollable
