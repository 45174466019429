
import React, { useState, useEffect } from 'react'
import { Button, Box } from '@material-ui/core'
import OrderImageCard from '../OrderImageCard'
import Container from '../../Reusable/Container'
import Scrollable from '../../Reusable/Scrollable'
import useAPILocal from '../../../hooks/useAPILocal'
import bolAPI from '../../../api/bol'
import LoadingIndicator from './Loading'

export default function PhotosComments({ order }) {
  const [comments, setComments] = useState()

  const getComments = useAPILocal(bolAPI.getComments)

  useEffect(() => {
    if (order?.id)
      getComments
        .request(order?.id)
        .then(res => setComments(res?.data?.results))
  }, [order?.id])

  return (
    <>
      {getComments.isPending ? (
        <LoadingIndicator />
      ) : (
        <Container>
          <Scrollable>
            <Box className="flex-row" style={{ flexWrap: 'wrap', gap: '20px' }}>
              {comments?.map((item, index) => (
                <OrderImageCard
                  picture
                  profilePic={item?.createdBy?.profile_picture}
                  comment={item?.comment || ''}
                  images={item?.images}
                  userName={
                    item?.createdBy?.first_name +
                    ' ' +
                    item?.createdBy?.last_name
                  }
                  date={item?.createdAt}
                  commentId={item?.id}
                  role={item?.createdBy?.role?.role_name}
                  key={index}
                />
              ))}
            </Box>
          </Scrollable>
        </Container>
      )}
    </>
  )
}
