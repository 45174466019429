import client from '../../hooks/useClient'

const tankAPI = {}

tankAPI.list = async data => {
  return client.get(
    `/tanks/list?search=${data.search !== undefined ? data.search : ''}&limit=${
      data.limit || 12
    }&page=${data.page || 0}&tankType=${data.tankType || ''}`
  )
}

tankAPI.create = async details => {
  return client.post('/tanks/create', details)
}

tankAPI.createBulkTank = async file => {
  const formData = new FormData()
  formData.append('file', file)
  return client.post(`/tanks/bulk/create`, formData)
}

tankAPI.info = async tankId => {
  return client.get(`tanks/${tankId}`)
}

tankAPI.updateInfo = async (tankId, details) => {
  return client.put(`/tanks/${tankId}`, details)
}

tankAPI.getFile = async tankId => {
  return client.get(`/tanks/${tankId}/files`)
}

tankAPI.uploadFile = async ({ tankId, data }) => {
  const formdata = new FormData()
  formdata.append('file', data)
  return client.put(`/tanks/${tankId}/files`, formdata)
}

tankAPI.deleteTank = async tankId => {
  return client.delete(`/tanks/${tankId}`)
}

tankAPI.getLoadingTanks = async (bolItemId, data) => {
  return client.get(`/tanks/${bolItemId}/loading-tanks`, data)
}

tankAPI.getUnloadingTanks = async (bolItemId, data) => {
  return client.get(`/tanks/${bolItemId}/unloading-tanks`, data)
}

export default tankAPI
