import React from 'react'
import { propTypes } from 'qrcode.react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import TableHead from '../../Reusable/TableHead'
import Fixed from '../../Reusable/Fixed'
import Scrollable from '../../Reusable/Scrollable'
import TableBody from '../../Reusable/TableBody'
import Container from '../../Reusable/Container'
import { formatContactNo } from '../../../helpers/util'
import Loading from '../../Order/Tabs/Loading'
import routes from '../../../routes/route_contants'
import VisibilityModal from '../../Connections/Tabs/MyCompany/Seller/VisibilityModal'

function Contacts({ apiResult }) {
  const history = useHistory()
  const [isOpenInvitation, setIsOpenInvitation] = useState(false)
  const [data, setData] = useState()
  const toggleModalInvitation = () => {
    setIsOpenInvitation(!isOpenInvitation)
  }
  const handleRowClick = row => {
    history.push(`${routes.CONTACT_PAGE}/${row.id}`)
  }

  const columns = [
    {
      label: 'Name',
      value: e => `${e?.name} ${e?.surname}`,
      minWidth: 180,
    },
    {
      label: 'Job Title',
      value: e => e?.jobTitle,
    },
    {
      label: 'Email',
      value: e => e?.email,
      maxWidth: 300,
    },
    {
      label: 'Contact Number',
      value: e => {
        return formatContactNo(e?.phone) || '-'
      },
    },
    {
      label: 'Visibility',
      value: e => {
        return (
          <Button
            onClick={event => {
              event.stopPropagation()
              setData(e)
              setIsOpenInvitation(!isOpenInvitation)
            }}
            variant="outlined"
            color="secondary"
          >
            {e?.visibility?.replaceAll('_', ' ')}
          </Button>
        )
      },
    },
  ]

  return (
    <Container>
      <Fixed>
        <TableHead columns={columns} />
      </Fixed>
      <Scrollable>
        {apiResult.isPending && <Loading m={4} />}
        <TableBody
          columns={columns}
          data={apiResult.data?.results}
          rowOnClick={handleRowClick}
        />
      </Scrollable>
      <VisibilityModal
        isOpen={isOpenInvitation}
        toggleModal={toggleModalInvitation}
        data={data}
        entityType="CONTACT"
      />
    </Container>
  )
}

Contacts.defaultProps = {
  apiResult: {},
}

export default Contacts
