






import { Box, Button, Dialog, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import userAPI from '../../../../../api/user'
import { PaletteColors } from '../../../../../config/colors'
import Container from '../../../../Reusable/Container'
import Fixed from '../../../../Reusable/Fixed'
import PageHeading from '../../../../Reusable/PageHeading'
import PageTitle from '../../../../Reusable/PageTitle'
import Scrollable from '../../../../Reusable/Scrollable'
import { formatContactNo, formatLocation } from '../../../../../helpers/util'
import ConnectionModal from './ConnectionModal'
import SearchBar from '../../../../Reusable/Widgets/SearchBar'
import TableHead from '../../../../Reusable/TableHead'
import TableBody from '../../../../Reusable/TableBody'

const columnsProducts = [
  {
    label: 'Name',
    value: e => e?.productName,
    minWidth: 180,
  },
  {
    label: 'UN Number',
    value: e => {
      return e?.unNumber
    },
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'City',
    value: e => '-',
  },
]

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function TenantDetailPage(props) {
  const history = useHistory()
  const [searchStr, setSearchStr] = useState('')
  const tenantData = history?.location.state.data
  const [newArray, setNewArray] = useState([])

  const [isOpenConnection, setIsOpenConnection] = useState(false)
  const toggleModalConnection = () => {
    setIsOpenConnection(!isOpenConnection)
  }

  const handleSearch = val => {
    setSearchStr(val)
  }
  useEffect(() => {
    if (searchStr !== '')
      setNewArray(
        tenantData?.products?.filter(item =>
          item.productName.toLowerCase().includes(searchStr?.toLowerCase())
        )
      )
    else setNewArray(tenantData?.products || [])
  }, [searchStr])

  useEffect(() => {
    setNewArray(tenantData?.products || [])
  }, [tenantData?.products])

  const fields = [
    {
      label: 'Name',
      value: e => e?.tenant_name,
    },
    {
      label: 'Location',
      value: e => formatLocation(e?.primary_location),
    },
    { label: 'Email', value: e => e?.tenant_email || '-' },
    {
      label: 'Contact No.',
      value: e => formatContactNo(e?.tenant_phone) || '-',
    },
    {
      label: '',
      value: e => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleModalConnection}
          >
            Connect
          </Button>
        )
      },
    },
  ]

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title={tenantData?.tenant_name} />
        </PageHeading>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            padding: 40,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto auto auto auto',
          }}
        >
          {fields.map(field => (
            <Box key={field.label}>
              <Item label={field.label} value={field.value(tenantData)} />
            </Box>
          ))}
        </Box>
        <Fixed mt={2}>
          <PageHeading alignItems="center">
            <Typography
              style={{
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#3298FF',
              }}
            >
              Products
            </Typography>
            <Box display="flex">
              <Box>
                <SearchBar onChange={handleSearch} />
              </Box>
            </Box>
          </PageHeading>
        </Fixed>
        <Box mt={2}>
          <TableHead columns={columnsProducts} />
          <TableBody columns={columnsProducts} data={newArray} />
        </Box>
      </Scrollable>
      <ConnectionModal
        isOpen={isOpenConnection}
        toggleModal={toggleModalConnection}
        tenantId={history?.location.state.data?.tenant_id}
      />
    </Container>
  )
}

export default TenantDetailPage
