import React, { useEffect, useState } from 'react'
import { Avatar, Box, Divider, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import { Stack } from '@mui/material'
import fileAPI from '../../../api/file'
import useAPILocal from '../../../hooks/useAPILocal'
import Modal from '../../Modal'
import LoadingIndicator from './Loading'
import PdfIcon from '../../../assets/pdf_icon.png'
import bolAPI from '../../../api/bol'
import Loading from '../../Loading'

function Documents({ orderId }) {
  const [pdfData, setPdfData] = useState()
  const getFile = useAPILocal(fileAPI.getFile)
  const getFileMiniature = useAPILocal(fileAPI.getFileMiniature)
  const [sdsModal, setSdsModal] = useState(false)
  const [data, setData] = useState()
  const [imagesData, setImagesData] = useState([])
  const openFile = id => {
    setSdsModal(true)
    getFile.request(id).then(res => {
      if (res.data) {
        const pdfBlob = base64ToBlob(res.data)
        const objectURL = URL.createObjectURL(pdfBlob)
        setPdfData(objectURL)
      }
    })
  }

  function base64ToBlob(base64Data) {
    const byteCharacters = atob(base64Data.split(',')[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'application/pdf' })
  }

  const getDocuments = useAPILocal(bolAPI.getDocuments)
  useEffect(() => {
    if (orderId)
      getDocuments.request(orderId).then(res => {
        setData(res?.data)
        res?.data?.results.map(item => {
          console.log(item.document.id)
        })
        const imagePromises = res?.data?.results?.map(item =>
          getFileMiniature.request(item.document.id).then(res => ({
            id: item.document.id,
            data: res?.data,
          }))
        )
        Promise.all(imagePromises).then(imagesDataArray => {
          setImagesData(imagesDataArray)
        })
      })
  }, [orderId])

  const DocumentsCard = ({ item }) => {
    return (
      <Box
        style={{
          width: '260px',
          backgroundColor: '#F1F5F9',
          borderRadius: '10px',
        }}
      >
        <Box>
          <Stack direction="row" gap="10px" alignItems="center" margin="15px">
            <Avatar
              alt=""
              src={`data:image/jpeg;base64,${item?.createdBy?.profile_picture}`}
            />
            <Stack direction="column" gap="5px">
              <Typography
                style={{
                  fontSize: '15px',
                  lineHeight: '18px',
                  color: '#1A1A1A',
                }}
              >
                {`${item?.createdBy?.first_name} ${item?.createdBy?.last_name}`}
              </Typography>
              <Typography
                style={{
                  textTransform: 'capitalize',
                  fontSize: '12px',
                  lineHeight: '14px',
                  color: '#60B077',
                }}
              >
                {item?.createdBy?.role?.role_name}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          {imagesData.length === 0 ? (
            <Loading />
          ) : (
            <img
              src={
                typeof imagesData?.find(image => image.id === item.document.id)
                  ?.data === 'string'
                  ? imagesData?.find(image => image.id === item.document.id)
                      ?.data
                  : PdfIcon
              }
              style={{
                margin: '15px 15px 0px 15px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              alt=""
              onClick={() => openFile(item.document.id)}
            />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            margin="15px"
            alignItems="center"
          >
            <Stack direction="column" gap="8px">
              <Typography
                style={{
                  fontSize: '15px',
                  lineHeight: '18px',
                  color: '#1A1A1A',
                }}
              >
                {item.type}
              </Typography>
              <Typography
                style={{
                  fontSize: '13px',
                  lineHeight: '16px',
                  color: '#A3A3A3',
                }}
              >
                {moment(item?.createdAt).local().format('DD-MMM-YYYY HH:MM A')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    )
  }

  return getDocuments.isPending ? (
    <LoadingIndicator />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        flexWrap: 'wrap',
        paddingBottom: '20px',
      }}
    >
      {data?.results?.length > 0 ? (
        data?.results?.map(item => <DocumentsCard item={item} key={item.id} />)
      ) : (
        <h4>No documents found for this order</h4>
      )}
      <Modal
        visible={sdsModal}
        onClose={() => setSdsModal(false)}
        style={{ minWidth: '250px', height: '600px' }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ width: '100%', height: '500px' }}
        >
          {getFile?.isPending ? (
            <LoadingIndicator />
          ) : pdfData !== null ? (
            <embed
              height="600px"
              src={pdfData}
              type="application/pdf"
              width="100%"
            />
          ) : (
            <h4 style={{ textAlign: 'center' }}>File not found</h4>
          )}
        </Grid>
      </Modal>
    </div>
  )
}

export default Documents
