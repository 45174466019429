

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SearchBar from './SearchBar'
import useStateValue from '../../hooks/useStateValue'
import useLogout from '../../hooks/useLogout'
import routes from '../../routes/route_contants'

const drawerWidth = 200

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 0,
    minHeight: '90px', // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: 1,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFFFFF',
    height: '90px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: '-24px',
  },
  appBarSpacer: theme.mixins.toolbar,
}))

function Navbar({ drawerOpen, toggleDrawer }) {
  const [state] = useStateValue()
  const logout = useLogout()
  const {
    user: { user_info },
  } = state
  const [popoverOpen, setPopoverOpen] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const anchorRef = useRef(null)
  const mobileScreen = useMediaQuery('(max-width:600px)')
  const handleToggle = () => {
    setPopoverOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setPopoverOpen(false)
  }

  const navigateProfile = () => {
    if (user_info?.role?.role_name === 'admin') {
      history.push(routes.COMPANY_PROFILE)
    } else if (user_info?.role_name === 'normaluser') {
      history.push(routes.USER_PROFILE)
    } else if (user_info?.role?.role_name === 'superadmin') {
      history.push(routes.SUPER_USER_PROFILE)
    }
  }

  return (
    <AppBar
      color="white"
      position="absolute"
      elevation={0}
      className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(
            classes.menuButton,
            drawerOpen && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          // noWrap
          className={classes.title}
        >
          <SearchBar />
        </Typography>
        <Button
          style={{
            textTransform: 'capitalize',
            marginRight: 15,
            position: 'fixed',
            right: '10px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #3298FF',
            borderRadius: '7px',
            padding: '8px',
          }}
          ref={anchorRef}
          onClick={handleToggle}
          color="inherit"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Box
              pr={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              {!mobileScreen && (
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#1A1A1A',
                    lineHeight: '17px',
                  }}
                >
                  {user_info?.first_name ? `${user_info?.first_name} ${user_info?.last_name}` : 'User Profile'}
                </Typography>
              )}
              {!mobileScreen && (
                <div>
                  <Typography
                    style={{
                      textAlign: 'right',
                      fontSize: '12px',
                      lineHeight: '17px',
                      color: '#1A1A1A',
                    }}
                    variant="caption"
                  >
                    {user_info?.tenant?.tenant_name || ''}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'right',
                      fontSize: '12px',
                      lineHeight: '17px',
                      color: '#3298FF',
                    }}
                    variant="caption"
                  >
                    {user_info?.role.role_name ? ` (${user_info?.role.role_name})` : ''}
                  </Typography>
                </div>
              )}
            </Box>
            {/* <ArrowDropDownIcon /> */}
            {user_info?.profile_picture && (
              <Avatar
                src={`${process.env.REACT_APP_API_URL}/user.profile.picture/${user_info.user_id}`}
              />
            )}
          </div>
        </Button>
        <Popper
          open={popoverOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
       
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={drawerOpen} id="menu-list-grow">
                    <MenuItem onClick={navigateProfile}>
                      <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Logout
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  )
}

Navbar.propTypes = {
  drawerOpen: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
}

export default Navbar
