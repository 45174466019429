import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NewNavigationLinks from './NewNavigationLinks'
import useStateValue from '../../hooks/useStateValue'
import Routes from '../../routes'
import Navbar from './Navbar'
import { Welcome } from '..'
import { PaletteColors } from '../../config/colors'
import LogoNew from '../../assets/finalLogo.svg'
import Support from '../../assets/Navigation/support.svg'
import useRole from '../../hooks/useRole'
import Modal from '../Modal'

const drawerWidth = 200

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '100vh',
    backgroundColor: '#003366',
    borderRight: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: PaletteColors.backgroundPrimary,
    borderRadius: 5,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

export default function Dashboard() {
  const [state] = useStateValue()
  const [contactSupportVisible, setContactSupportVisible] = useState(false)
  let { roleObj: role } = useRole()
  const {
    user: { user_info },
  } = state

  const classes = useStyles()
  const mobileScreen = window.screen.availWidth < 600
  const [drawerOpen, setDrawerOpen] = useState(false)

  const { isAuthenticated } = useAuth0()

  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  useEffect(() => {
    if (!mobileScreen) {
      setDrawerOpen(true)
    }
  }, [])

  if (!isAuthenticated) {
    return <Welcome />
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Drawer
        variant={!mobileScreen ? 'permanent' : 'temporary'}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon} style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <img
              src={LogoNew}
              alt="CargoCheck"
              style={{ margin: 'auto', marginTop: '20px' }}
            />
          </div>
          {mobileScreen && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
        <div style={{ flex: 1, marginTop: 40 }}>
          <NewNavigationLinks
            onNavigate={() => {
              if (mobileScreen) {
                setDrawerOpen(false)
              }
            }}
          />
        </div>

        <button
          onClick={() => setContactSupportVisible(true)}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
        >
          <Box
            p={1}
            style={{
              userSelect: 'none',
              paddingBottom: '30px',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={Support} alt="support" />
            <Typography
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                color: '#FFFFFF',
                marginLeft: '10px',
              }}
            >
              Contact Support
            </Typography>
          </Box>
        </button>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div
          style={{
            flexGrow: 1,
            overflow: 'auto',
            margin: !mobileScreen && '25px 25px 25px 25px',
            background: 'white',
            borderRadius: 5,
            marginTop: '53px',
          }}
        >
          <Routes />
        </div>
      </main>

      <Modal
        visible={contactSupportVisible}
        onClose={() => setContactSupportVisible(false)}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          For help during beta testing, please email&nbsp;
          <a href="mailto:support@cargocheck.com">support@cargocheck.com</a>
          .
        </Box>
      </Modal>
    </div>
  )
}
