
import React from 'react'
import { useReducer } from 'reinspect'

export const StateContext = React.createContext()

function StateProvider({ reducer, initialState, children }) {
  return (
    <StateContext.Provider
      value={useReducer(reducer, initialState, state => state, 'App')}
    >
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider
