import { Box, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TextInput from '../Reusable/Widgets/TextInput'
import useForm from '../../hooks/useForm'
import useAPILocal from '../../hooks/useAPILocal'
import LogoPlaceholderTenant from '../../assets/logoPlaceholderTenant.png'
import Loading from '../Loading'
import userAPI from '../../api/user'

function ProfileEdit({ match, saveChanges }) {
  const [image, setImage] = useState()

  const history = useHistory()
  const location = useLocation()
  const updateUserDetails = useAPILocal(userAPI.updateUserDetails)
  const updateUserProfilePic = useAPILocal(userAPI.updateUserProfilePic)

  const [newImage, setNewImage] = useState(null)

  const onImageChange = e => {
    const url = URL.createObjectURL(e.target.files[0])
    setNewImage(url)
    setImage(e.target.files[0])
  }

  const init = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
  }

  const validationSchema = {
    first_name: ['required'],
    last_name: ['required'],
    email: ['required', 'email:Not a valid email'],
    phone_no: ['required', 'min:1', 'max:64', 'dashedNumber'],
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(init, validationSchema, async result => {
      try {
        const data = {
          firstName: result?.first_name,
          lastName: result?.last_name,
          email: result?.email,
          phoneNumber: result?.phone_no,
          roleId: location.state.data?.role?.role_id,
        }
        if (image) {
          const formData = new FormData()
          formData.append('image', image)
          await updateUserProfilePic.request(formData)
        }

        updateUserDetails
          .request(location.state.data.user_id, data)
          .then(res => history.goBack())
      } catch (err) {
        alert('Error')
      }
    })

  useEffect(() => {
    const obj = {
      first_name: location.state.data?.first_name,
      last_name: location.state.data?.last_name,
      email: location.state.data?.email,
      phone_no: location.state.data?.phone_no,
    }
    setValues(obj)
  }, [location.state.data])

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  return (
    <>
      <Container pt={3}>
        <Fixed px={3}>
          <PageHeading mb={2}>
            <PageTitle backButton title="Edit Profile" />
          </PageHeading>
        </Fixed>
        {/* {updateUserDetails.isPending || !tenantInfo ? (
          <LoadingIndicator m={4} />
        ) : ( */}
        <Scrollable mt={2}>
          <Box display="flex">
            <Box flex="20%"></Box>
            <Box flex="80%" height="100%">
              <Box overflow="auto" height="100%"></Box>
              <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
                <Box display="flex" className="form-col">
                  <Box paddingTop="30px">
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        placeholder="Add profile picture"
                        hidden
                        id="f03"
                        style={{ borderRadius: '50%', cursor: 'pointer' }}
                        onChange={onImageChange}
                      />
                      <label htmlFor="f03" style={{ cursor: 'pointer' }}>
                        <div className="edittenant">
                          <img
                            className="edittenantimage"
                            src={
                              newImage ||
                              `${process.env.REACT_APP_API_URL}/user.profile.picture/${location.state.data?.user_id}`
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = LogoPlaceholderTenant
                            }}
                            alt=""
                            style={{
                              opacity: newImage ? 1 : 0.3,
                            }}
                          />
                          {!newImage && (
                            <div className="browselogo">Browse Logo</div>
                          )}
                        </div>
                      </label>
                    </div>
                    {/* {(location.state.data?.profile_picture || newImage) && (
                      <div className="removelogo">
                        <Button>Remove Logo</Button>
                      </div>
                    )} */}
                  </Box>
                </Box>

                <Box display="flex" className="form-row">
                  <TextInput
                    flex="0.4"
                    label="First Name"
                    name="first_name"
                    {...additionalProps('first_name')}
                  />
                  <TextInput
                    flex="0.4"
                    label="Last Name"
                    name="last_name"
                    {...additionalProps('last_name')}
                  />
                </Box>

                <Box display="flex" className="form-row">
                  <TextInput
                    flex="0.4"
                    label="Phone Number"
                    name="phone_no"
                    {...additionalProps('phone_no')}
                  />
                  <TextInput
                    flex="0.4"
                    label="Email id"
                    name="email"
                    {...additionalProps('email')}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Scrollable>
        {/* )} */}
        <Fixed>
          <Box py={1} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={updateUserDetails.isPending}
            >
              {updateUserDetails.isPending ? <Loading /> : 'Save Changes'}
            </Button>
          </Box>
        </Fixed>
      </Container>
    </>
  )
}

export default ProfileEdit
