import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'
import Fixed from '../Reusable/Fixed'
import Container from '../Reusable/Container'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import TabPanel from '../Reusable/TabPanel'
import Scrollable from '../Reusable/Scrollable'
import routes from '../../routes/route_contants'
import withPaginationLocal from '../../HOC/withPaginationLocal'
import ChecklistList from './ChecklistList'
import checklistAPI from '../../api/checklist'

function Checklist() {
  const history = useHistory()

  // const ChecklistsList = useMemo(
  //   () =>
  //     withPaginationLocal(checklistAPI.getChecklists, { limit: 12 })(
  //       ChecklistList
  //     ),
  //   []
  // )

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading mb={2}>
          <PageTitle title="Checklist" />

          <Box className="flex-row">
            <Button
              onClick={() => history.push(`${routes.CREATE_CHECKLIST}`)}
              variant="contained"
              color="primary"
            >
              Create Checklist
            </Button>
          </Box>
        </PageHeading>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <TabPanel>
          <ChecklistList />
        </TabPanel>
      </Scrollable>
    </Container>
  )
}

Checklist.propTypes = {}

Checklist.defaultProps = {}

export default Checklist
