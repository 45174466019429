import React from 'react'
import PropTypes from 'prop-types'
import styles from './button.module.css'

const IconButton = ({ onClick, style, children, ...rest }) => {
  return (

    <div
      role="button"
      onClick={onClick}
      className={`${styles.button} ${rest.className || ''}`}
      style={style}
      {...rest}
    >
      {children}
    </div>
  )
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
}

IconButton.defaultProps = {
  style: {},
  onClick: () => {},
}
export default IconButton
