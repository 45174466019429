
export default () => {
  function get_header_row(sheet) {
    const headers = []
    const range = self.XLSX.utils.decode_range(sheet['!ref'])
    let C
    const R = range.s.r
    for (C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[self.XLSX.utils.encode_cell({ c: C, r: R })]

      let hdr = `UNKNOWN ${C}`
      if (cell && cell.t) hdr = self.XLSX.utils.format_cell(cell)
      headers.push(hdr)
    }
    return headers
  }

  function remove_unused_fields(data, fields) {
    return data.map(row => {
      const newRow = {}
      Object.keys(row).forEach(field => {
        if (field.toUpperCase() in fields) {
          newRow[field.toUpperCase()] = row[field]
        }
      })
      return newRow
    })
  }

  function sheet_to_array(sheet) {
    return self.XLSX.utils.sheet_to_json(sheet, {
      // raw: false,
    })
  }
  function json_to_sheet(json, header) {
    return self.XLSX.utils.json_to_sheet(json, { header, dateNF: 'mm-dd-yyyy' })
  }
  function sheet_to_csv(sheet) {
    return self.XLSX.utils.sheet_to_csv(sheet)
  }
  self.importScripts('https://unpkg.com/xlsx@0.17.0/dist/xlsx.full.min.js')
  self.addEventListener('message', function ({ data }) {
    switch (data.type) {
      case 'get_header':
        const header = get_header_row(data.sheet)
        this.postMessage({ type: 'get_header', header })
        break
      case 'sheet_to_array':
        const sheetData = sheet_to_array(data.sheet)
        this.postMessage({ type: 'sheet_to_array', sheetData })
        break

      case 'json_to_sheet':
        const sheet = json_to_sheet(data.json, data.header)
        this.postMessage({ type: 'json_to_sheet', sheet })
        break

      case 'sheet_to_csv':
        const csv = sheet_to_csv(data.sheet)
        this.postMessage({ type: 'sheet_to_csv', csv })
        break

      case 'remove_unused_fields':
        const finalJSON = remove_unused_fields(data.json, data.fields)
        this.postMessage({ type: 'remove_unused_fields', finalJSON })
        break

      default:
        const xlsx = self.XLSX
        const options = {
          type: 'binary',
          bookSheets: true,
          raw: true,
          cellDates: true,
        }
        if (data.sheet != null) {
          options.bookSheets = false
          options.sheets = [data.sheet]
        }
        const workbook = xlsx.read(data.file, options)
        this.postMessage({ type: 'workbook', workbook })
        break
    }
  })
}
