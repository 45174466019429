import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import Cookies from 'js-cookie'
import { StateInspector } from 'reinspect'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import './styles/index.scss'
import StateProvider from './store/StateProvider'
import reducer from './store/reducers'
import initialState from './store/initialState'
import client from './hooks/useClient'

client.setBaseURL(process.env.REACT_APP_API_URL)
client.addAsyncRequestTransform(async request => {
  const authToken = Cookies.get('access_token')
  if (!authToken) return
  request.headers.Authorization = `Bearer ${authToken}`
})

let user
let table_filters

try {
  user = localStorage.getItem('user')
  table_filters = localStorage.getItem('table_filters')
  if (user) {
    user = JSON.parse(user)
  } else {
    user = {}
  }
  if (table_filters) {
    table_filters = JSON.parse(table_filters)
  } else {
    table_filters = null
  }
} catch (error) {
  console.error(error)
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    scope="read:current_user"
  >
    <StateInspector name="t2t">
      <StateProvider
        initialState={{
          ...initialState,
          user,
          table_filters,
        }}
        reducer={reducer}
      >
        <App />
      </StateProvider>
    </StateInspector>
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
