import { downloadFile } from './downloadQR'
import template from './labelTemplate'

const init = () => {
  const exists = [...document.scripts]
    .map(e => e.src)
    .includes('https://html2canvas.hertzen.com/dist/html2canvas.min.js')
  if (exists) {
    return
  }
  const script = document.createElement('script')
  script.src = 'https://html2canvas.hertzen.com/dist/html2canvas.min.js'
  document.head.appendChild(script)
}
/**
 *
 * @param {HTMLOrSVGElement|HTMLOrSVGImageElement|HTMLCanvasElement|HTMLImageElement} image
 * @param {object} data
 * @returns base64 of the image
 */
export function getLabel(image, data, templateNo) {
  return new Promise((resolve, reject) => {
    if (!window.html2canvas) {
      return
    }
    const doc = document.createElement('div')
    doc.style.position = 'absolute'
    doc.style.top = 0
    doc.style.opacity = 0

    doc.innerHTML = template(image, data, templateNo)
    document.body.appendChild(doc)

    window.html2canvas(doc.firstChild).then(canvas => {
      doc.remove()
      const d = canvas.toDataURL()
      resolve(d)
    })
  })
}

function downloadLabel(image, data, templateNo) {
  getLabel(image, data, templateNo).then(base64 => {
    downloadFile(base64, `TANK_${data.tankRegistrationNo}.png`)
  })
}

export default function downloadTankLabel(image, data, templateNo) {
  downloadLabel(image, data, templateNo)
}

init()
