import { fieldVisible, IAutoComplete, ISelect, IText } from './utils'

const step6 = [
  {
    key: 'Product information',
    visible: fieldVisible,
    children: [
      new IText('specialInstructions', 'Special Instructions', '', undefined, {
        multiline: true,
      }),
    ],
  },
]

export default step6
