import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { deafultFunc, defaultString } from '../../../theme/config'

function CheckBoxInput({
  label,
  value,
  onChange,
  error,
  showLabel,
  checklist,
  other,
  disabled,
}) {
  const handleChange = (e, checked) => {
    onChange(checked)
  }
  return (
    <Box className="input-container" flex={checklist ? '' : 1}>
      {!other && <Box className="input-label">{showLabel || ''}</Box>}
      <Box
        className="form-checkbox-input"
        style={checklist ? { border: 'none' } : {}}
      >
        <FormControlLabel
          className="flex-1"
          control={
            <Checkbox
              disableRipple
              checked={!!value}
              disabled={disabled}
              onChange={handleChange}
            />
          }
          label={label}
        />
      </Box>
      {error !== '' && <div className="input-error">{error}</div>}
    </Box>
  )
}

CheckBoxInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  error: PropTypes.string,
  showLabel: PropTypes.bool,
}

CheckBoxInput.defaultProps = {
  onChange: deafultFunc,
  label: defaultString,
  error: defaultString,
  showLabel: 'Check All that apply',
}

export default CheckBoxInput
