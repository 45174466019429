import React from 'react'
import './modal.scss'
import PropTypes from 'prop-types'

import IconButton from '../Icon/IconButton'
import Icon from '../Icon'
import { Typography } from '@material-ui/core'

export default function Modal({
  visible,
  onClose,
  children,
  style,
  cancel,
  bodyStyle,
  title,
  locationModal,
}) {
  const displayValue = visible ? 'block' : 'none'
  return (
    <div
      id="myModal"
      className={cancel ? 'modalCancel' : 'modal'}
      style={{ display: displayValue }}
      // onClick={handleOnClick}
    >
      <div
        className={cancel ? 'modal-content-cancel' : 'modal-content'}
        style={style}
      >
        <div
          className="modal-header"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: title ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {title && (
            <Typography
              color="primary"
              style={{ fontWeight: '600', fontSize: '18px' }}
            >
              {title}
            </Typography>
          )}

          <IconButton
            role="presentation"
            onClick={onClose}
            onKeyDown={onClose}
            className="close"
            style={locationModal ? { fontSize: '2px', padding: '2px' } : {}}
          >
            <Icon name="close" />
          </IconButton>
        </div>
        <div className="modal-body" style={bodyStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
}

Modal.defaultProps = {
  visible: false,
  onClose: () => {},
  children: <div>nothing to show</div>,
}
