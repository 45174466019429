import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Container from '../../../Reusable/Container'
import Fixed from '../../../Reusable/Fixed'
import PageHeading from '../../../Reusable/PageHeading'
import PageTitle from '../../../Reusable/PageTitle'
import Scrollable from '../../../Reusable/Scrollable'
import TextInput from '../../../Reusable/Widgets/TextInput'
import useForm from '../../../../hooks/useForm'
import useStateValue from '../../../../hooks/useStateValue'
import Loading from '../../../Loading'
import './edittenant.scss'
import tenantAPI from '../../../../api/tenant'
import useAPILocal from '../../../../hooks/useAPILocal'
import { formatLocation } from '../../../../helpers/util'
import AddLocationModal from '../../../Location/AddLocationModal'

const useStyles = makeStyles(theme => ({
  deleteImage: {
    zIndex: 11,
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
    color: 'red',
    height: '20px',
  },
}))

function EditTenantDetails() {
  const [image, setImage] = useState()
  const [isUpdating, setIsUpdating] = useState(false)
  const [tenantData, setTenantData] = useState()
  const history = useHistory()
  const location = useLocation()
  const tenantUpdate = useAPILocal(tenantAPI.tenantInfoUpdate)
  const uploadTenantLogo = useAPILocal(tenantAPI.uploadTenantLogo)
  const tenantInfo = useAPILocal(tenantAPI.tenantDetails)
  const [isOpenLocation, setIsOpenLocation] = useState(false)
  const [partnerLocation, setPartnerLocation] = useState()

  const toggleModalLocation = () => {
    setIsOpenLocation(!isOpenLocation)
  }

  const [newImage, setNewImage] = useState()
  const classes = useStyles()

  const onImageChange = files => {
    const file = files[0]
    if (files.length !== 0) {
      const url = URL.createObjectURL(file)
      setNewImage(url)
      setImage(file)
    }
  }

  const removeLogo = () => {
    setNewImage(undefined)
  }

  const validationSchema = {
    tenant_name: ['required'],
    tenant_phone: ['min:1', 'max:64', 'dashedNumber'],
    tenant_ein: ['eq:9', 'number:EIN should be a number'],
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(
      {
        tenant_name: '',
        tenant_ein: '',
        primary_location: '',
        tenant_email: '',
        tenant_phone: '',
        slug: '',
      },
      validationSchema,
      async result => {
        const updateParams = {
          tenant_name: result.tenant_name,
          primary_location: partnerLocation?.location_id,
          tenant_ein: result.tenant_ein,
          tenant_email: result.tenant_email,
          tenant_phone: result.tenant_phone,
          slug: result.slug,
          is_active: tenantData?.is_active,
        }
        try {
          setIsUpdating(() => true)
          await tenantUpdate.request(
            location.state.data?.tenant_id,
            updateParams
          )
          if (image) {
            const formData = new FormData()
            formData.append('image', image)

            const res = await uploadTenantLogo.request({
              image: formData,
              tenantId: location.state.data?.tenant_id,
            })
          }

          history.goBack()
        } catch (err) {
          setIsUpdating(() => false)
          alert('Error')
        }
      }
    )

  const dashedNumber = (value, returnValue = false) => {
    let newValue = ''
    value = value || ''
    value = value?.split('-')?.join('')
    value = value?.replace(/\D[^\.]/g, '')
    newValue = value?.slice(0, 3)
    if (value?.slice(0, 3) !== '' && value?.slice(3, 6) !== '') newValue += '-'
    newValue += value?.slice(3, 6)
    if (value?.slice(3, 6) !== '' && value?.slice(6) !== '') newValue += '-'
    newValue += value?.slice(6)
    if (returnValue) return newValue
    handleOnChange({ name: 'tenant_phone', value })
  }

  const onLocationAdded = locationData => {
    if (locationData) {
      setPartnerLocation(() => locationData?.primary_location)
    }
    setIsOpenLocation(false)
  }

  useEffect(() => {
    const obj = {
      tenant_name: tenantData?.tenant_name,
      tenant_id: tenantData?.tenant_id,
      tenant_ein: tenantData?.tenant_ein || '',
      tenant_email: tenantData?.tenant_email,
      tenant_phone: dashedNumber(tenantData?.tenant_phone, true),
      slug: tenantData?.slug,
    }
    setPartnerLocation(tenantData?.primary_location)
    setValues(obj)
  }, [tenantData])

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  useEffect(() => {
    tenantInfo
      .request({
        tenantId: location.state.data?.tenant_id,
      })
      .then(res => setTenantData(res?.data))
  }, [])

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Edit Details" backButton />
        </PageHeading>
      </Fixed>
      <Scrollable mt={2}>
        {tenantInfo.isPending ? (
          <Loading />
        ) : (
          <Box display="flex">
            <Box flex="20%"></Box>
            <Box flex="80%" height="100%">
              <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
                <Box display="flex" className="form-col">
                  <Box paddingRight="20px" flex="0.5">
                    <TextInput
                      flex="0.5"
                      label="Tenant Name"
                      name="tenant_name"
                      {...additionalProps('tenant_name')}
                    />
                  </Box>
                  <Box paddingTop="30px" flex="0.5">
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        placeholder="Add profile picture"
                        hidden
                        id="f03"
                        style={{ borderRadius: '50%' }}
                        onChange={e => onImageChange(e.target.files)}
                      />

                      <label htmlFor="f03">
                        <div className="edittenant">
                          {newImage && (
                            <DeleteIcon
                              className={classes.deleteImage}
                              onClick={removeLogo}
                            />
                          )}

                          <img
                            className="edittenantimage"
                            src={
                           
                              newImage
                                ? newImage === 'nologo'
                                  ? null
                                  : newImage
                                : `data:image/jpeg;base64,${tenantInfo?.data?.tenant_logo}`
                            }
                            alt=""
                            style={{
                              opacity: newImage ? 1 : 0.3,
                            }}
                          />
                          {!newImage && (
                            <div className="browselogo">Browse Logo</div>
                          )}
                        </div>
                      </label>
                    </div>
                  </Box>
                </Box>
                <Box display="flex" className="form-row">
                  <TextInput
                    flex="0.5"
                    label="Tenant Slug"
                    name="slug"
                    {...additionalProps('slug')}
                  />

                  <TextInput
                    flex="0.5"
                    label="Tenant E-Mail"
                    name="tenant_email"
                    {...additionalProps('tenant_email')}
                  />
                </Box>
                <Box display="flex" className="form-row">
                  <TextInput
                    flex="0.5"
                    label="Tenant Contact Number"
                    name="tenant_phone"
                    {...additionalProps('tenant_phone')}
                  />
                  <TextInput
                    flex="0.5"
                    label="Tenant EIN"
                    name="tenant_ein"
                    {...additionalProps('tenant_ein')}
                  />
                </Box>

                <Box
                  display="flex"
                  className="form-row"
                  style={{ marginTop: '20px' }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    // disabled
                    onClick={() => toggleModalLocation()}
                  >
                    Edit Partner Location
                  </Button>
                  {partnerLocation && (
                    <>
                      <Typography
                        style={{
                          marginLeft: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {formatLocation(partnerLocation)}
                      </Typography>
                    </>
                  )}
                  {!partnerLocation && (
                    <>
                      <Typography
                        style={{
                          marginLeft: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'red',
                        }}
                      >
                        Location is required
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Scrollable>
      <Fixed>
        <Box py={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Box>
        {isOpenLocation && (
          <AddLocationModal
            isOpen={isOpenLocation}
            toggleModal={toggleModalLocation}
            onDone={onLocationAdded}
            location={partnerLocation}
            superAdmin
            tenantId={location.state.data?.tenant_id}
          />
        )}
      </Fixed>
    </Container>
  )
}

export default EditTenantDetails
