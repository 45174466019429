import React, { useMemo } from 'react'

import Container from '../../Reusable/Container'
import TeamMembersList from './TeamMembersList'
import TabPanel from '../../Reusable/TabPanel'
import Scrollable from '../../Reusable/Scrollable'
import withPaginationLocal from '../../../HOC/withPaginationLocal'
import userAPI from '../../../api/user'

function TeamMembersInvitation() {
  const TeamMemberInvitation = useMemo(
    () => withPaginationLocal(userAPI.list, {})(TeamMembersList),
    []
  )

  return (
    <Container>
      <Scrollable style={{ height: '100%' }}>
        <TabPanel>
          <TeamMemberInvitation />
        </TabPanel>
      </Scrollable>
    </Container>
  )
}

TeamMembersInvitation.propTypes = {}

TeamMembersInvitation.defaultProps = {}

export default TeamMembersInvitation
