import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import useForm from '../../hooks/useForm'
import moment from 'moment/moment'
import { Box, Button } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import useAPILocal from '../../hooks/useAPILocal'
import bolAPI from '../../api/bol'
import { dateTimeFormatPattern } from '../../helpers/util'
import DateTimeInput from "../Reusable/Widgets/DateTimeInput";

const OrderEtaUpdate = ({ order, onClose, visible }) => {
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const updateEtaApi = useAPILocal(bolAPI.updateEstimatedArrival)

  const { handleOnChange, values, setValues, handleSubmit } = useForm(
    {
      timestamp: moment(order?.estimatedArrival).format(
        dateTimeFormatPattern()
      ),
    },
    { timestamp: ['required'] },
    async result => {
      updateEtaApi
        .request({
          bolId: order?.id,
          timestamp: encodeURIComponent(
            moment(result.timestamp, dateTimeFormatPattern())
              .toISOString(true)
          ),
        })
        .then(r => {
          setStatus(r.status)
          if (r.status <= 201) {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            setError(r.data?.descriptionMessage)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
  )

  return (
    <Modal visible={visible} onClose={onClose}>
      <DateTimeInput
        flex="1"
        label="Estimated Arrival Date & Time"
        name="estimated_arrival"
        value={values?.timestamp}
        onChange={it => setValues({ timestamp: it })}
      />
      <Box style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Box>
      {status && (
        <div style={{ marginTop: 10 }}>
          <MuiAlert
            severity={error ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {error || 'ETA updated successfuly!'}
          </MuiAlert>
        </div>
      )}
    </Modal>
  )
}

OrderEtaUpdate.propTypes = {
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default OrderEtaUpdate
