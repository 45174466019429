import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MuiAlert from '@material-ui/lab/Alert'
import { Box, Button } from '@material-ui/core'
import Modal from '../Modal'
import useForm from '../../hooks/useForm'
import AutoCompleteInput from '../Reusable/Widgets/AutoCompleteInput'
import userAPI from '../../api/user'
import useAPILocal from '../../hooks/useAPILocal'

const OrderDriverUpdate = ({ data, onClose, visible, onDone }) => {
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(null)
  const updateDriver = useAPILocal(userAPI.driverRouteAssign)
  const [validationSchema, setValidationSchema] = useState({
    driver: ['required'],
  })

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm({ driver: {} }, validationSchema, async result => {
      updateDriver
        .request(result?.driver?.userId || data?.route?.driver?.user_id, [
          data.id,
        ])
        .then(r => {
          setStatus(r.status)
          if (r.status <= 201) {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            setError(r.data?.message)
          }
        })
        .catch(e => {
          console.log(e)
        })
    })

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  useEffect(() => {
    const currentDriver = {
      firstName: data?.route?.driver?.first_name,
      lastName: data?.route?.driver?.last_name,
      email: data?.route?.driver?.email,
    }
    setValues(() => ({
      driver: currentDriver || {},
    }))
  }, [])

  return (
    <Modal visible={visible} onClose={onClose}>
      <AutoCompleteInput
        flex="0.5"
        label="Driver"
        value={values.driver}
        api={{
          src: userAPI.list,
          params: { role: 9 },
        }}
        renderItem={option =>
          `${option?.firstName} ${option?.lastName} - ${option?.email}`
        }
        {...additionalProps('driver')}
      />
      <Box style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
        >
          {' '}
          Update Driver{' '}
        </Button>
      </Box>
      {status && (
        <div style={{ marginTop: 10 }}>
          <MuiAlert
            severity={error ? 'error' : 'success'}
            elevation={6}
            variant="filled"
            style={{ whiteSpace: 'pre-line' }}
          >
            {error || 'Driver updated successfuly!'}
          </MuiAlert>
        </div>
      )}
    </Modal>
  )
}

OrderDriverUpdate.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
}

export default OrderDriverUpdate
