



import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import { AddQuestion } from '../styles'
import TextInput from '../../Reusable/Widgets/TextInput'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import tree from '../../../assets/tree.png'

const TextInputCustom = ({ methods, name, ...rest }) => (
  <Controller
    control={methods.control}
    rules={{ required: 'Field is required' }}
    name={name}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      <TextInput
        onChange={onChange}
        value={methods.watch(name)}
        {...rest}
        error={error?.message || ''}
      />
    )}
  />
)

const SelectInputCustom = ({ methods, pathString, name, ...rest }) => (
  <Controller
    control={methods.control}
    rules={{ required: 'Field is required' }}
    name={name}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      <SelectInput
        {...rest}
        onChange={e => {
          onChange(e)
          if (rest.onChange) rest.onChange(e)
        }}
        hideEmptyLabel
        error={error?.message || ''}
        value={methods.watch(name)}
      />
    )}
  />
)

export const getIndexFromArray = (arr, sendArr = false) => {
  arr = _.cloneDeep(arr)
  arr = arr.map(item => item + 1)
  if (sendArr) return arr
  return `${arr.join('.')}.`
}

export const sendPathString = arr => {
  const path = `questions${arr.reduce((prev, curr, currentIndex) => {
    prev = _.cloneDeep(prev)
    prev += currentIndex === 0 ? `[${curr}]` : `.subQuestions[${curr}]`
    return prev
  }, '')}`
  return path
}

const renderCheckList = ({ index, indexArr, methods, viewMode }) => {
  const pathString = sendPathString([...indexArr, index])
  const index_ = index
  return (
    <div
      key={index.toString()}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '25px',
      }}
    >
      <Grid container>
        <Grid item md={8}>
          <TextInputCustom
            disabled={viewMode}
            style={{ width: '90%' }}
            label="Question"
            methods={methods}
            name={`${pathString}.question`}
          />
        </Grid>
        <Grid item md={2}>
          <SelectInputCustom
            disabled={viewMode}
            methods={methods}
            flex="0.5"
            name={`${pathString}.answerType`}
            options={[
              { label: 'Yes/No', value: 'YES_NO' },
              { label: 'Single Select', value: 'SINGLE_OPTION' },
              { label: 'Multiple Select', value: 'MULTI_OPTION' },
              { label: 'Text', value: 'TEXT' },
            ]}
            onChange={answerType => {
              if (answerType === 'YES_NO')
                methods.setValue(pathString, {
                  ...methods.watch(pathString),
                  answerType,
                  answers: ['YES', 'NO'],
                })
              else if (answerType === 'TEXT') {
                methods.setValue(pathString, {
                  ...methods.watch(pathString),
                  answerType,
                  answers: [],
                })
              } else
                methods.setValue(pathString, {
                  ...methods.watch(pathString),
                  answerType,
                  answers: ['', ''],
                })
              methods.setValue(`${pathString}.subQuestions`, [])
            }}
          />
        </Grid>
        {!viewMode && (
          <Grid md={2}>
            <DeleteIcon
              width={20}
              height={20}
              style={{
                color: '#C0C0C0',
                cursor: 'pointer',
                marginTop: '45',
                marginLeft: '8',
              }}
              onClick={() => {
                const index1 = pathString.lastIndexOf('[')
                const index2 = pathString.lastIndexOf(']')
                const indexFinal = parseInt(
                  pathString.substring(index1 + 1, index2),
                  10
                )
                const preArray = pathString.substring(0, index1)
                methods.setValue(
                  preArray,
                  methods
                    .watch(preArray)
                    .filter((_, index) => index !== indexFinal)
                )
              }}
            />
          </Grid>
        )}
      </Grid>
      {methods.watch(`${pathString}.answerType`) === 'TEXT' && (
        <Box display="flex" className="form-row" style={{ marginLeft: '25px' }}>
          {methods.watch(pathString).subQuestions?.length > 0 &&
            renderCheckList({
              index: 0,
              indexArr: [...indexArr, index],
              methods,
              viewMode,
            })}
        </Box>
      )}
      {(methods.watch(`${pathString}.answerType`) === 'SINGLE_OPTION' ||
        methods.watch(`${pathString}.answerType`) === 'MULTI_OPTION' ||
        methods.watch(`${pathString}.answerType`) === 'YES_NO') && (
        <div>
          {methods.watch(`${pathString}.answers`).map((item, index) => (
            <Box key={index}>
              <Box display="flex" className="form-row">
                <TextInputCustom
                  disabled={
                    methods.watch(`${pathString}.answerType`) === 'YES_NO' ||
                    viewMode
                  }
                  name={`${pathString}.answers[${index}]`}
                  methods={methods}
                  flex="0.5"
                  style={{ width: '80%', marginLeft: '25px' }}
                  label={false}
                />
                {methods.watch(`${pathString}.answerType`) !== 'YES_NO' &&
                  !viewMode &&
                  index > 1 && (
                    <DeleteIcon
                      width={20}
                      height={20}
                      style={{
                        color: '#C0C0C0',
                        cursor: 'pointer',
                        marginTop: '10',
                        marginLeft: '0',
                      }}
                      onClick={() => {
                        methods.setValue(
                          `${pathString}.answers`,
                          methods
                            .watch(`${pathString}.answers`)
                            .filter((item, index1) => index !== index1)
                        )
                      }}
                    />
                  )}
                {item !== '' &&
                  !viewMode &&
                  pathString.split('subQuestions').length < 3 &&
                  !(methods.watch(`${pathString}.subQuestions`) || []).find(
                    itm => itm.parentAnswer === item
                  ) && (
                    <Button
                      style={{
                        minWidth: '26px',
                        height: '26px',
                        border: '0.5px solid #C9C9C9',
                        borderRadius: '3px',
                        marginTop: '10px',
                        background: '#fff',
                      }}
                      onClick={() => {
                        methods.setValue(
                          `${pathString}.subQuestions[${
                            (methods.watch(`${pathString}.subQuestions`) || [])
                              .length
                          }]`,
                          {
                            question: '',
                            answerType: 'TEXT',
                            subQuestions: [],
                            answers: [],
                            parentAnswer: item,
                          }
                        )
                      }}
                    >
                      <img src={tree} alt="" />
                    </Button>
                  )}
                {methods
                  .watch(pathString)
                  .subQuestions.findIndex(itm => itm.parentAnswer === item) !==
                  -1 &&
                  renderCheckList({
                    index: methods
                      .watch(pathString)
                      .subQuestions.findIndex(itm => itm.parentAnswer === item),
                    indexArr: [...indexArr, index_],
                    methods,
                    viewMode,
                  })}
              </Box>
            </Box>
          ))}
          {methods.watch(`${pathString}.answerType`) !== 'YES_NO' &&
            !viewMode && (
              <AddQuestion
                onClick={() =>
                  methods.setValue(
                    `${pathString}.answers[${
                      (methods.watch(`${pathString}.answers`) || []).length
                    }]`,
                    ''
                  )
                }
              >
                Add Option
              </AddQuestion>
            )}
        </div>
      )}
    </div>
  )
}

const Questions = ({ methods, viewMode }) => {
  return (
    <div>
      {methods.watch()?.questions?.map((item, index) =>
        renderCheckList({
          index,
          indexArr: [],
          methods,
          viewMode,
        })
      )}
      {!viewMode && (
        <AddQuestion
          onClick={() =>
            methods.setValue(
              `questions.[${methods.watch('questions').length}]`,
              {
                question: '',
                answerType: 'TEXT',
                subQuestions: [],
                answers: [],
              }
            )
          }
        >
          Add Question
        </AddQuestion>
      )}
    </div>
  )
}

export default Questions
