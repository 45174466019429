import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Links from '../helpers/linking'

export const isToRedirectMobile = () => {
  const isMobile = {
    Android() {
      return navigator.userAgent.match(/Android/i)
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    any() {
      return isMobile.Android() || isMobile.iOS()
    },
  }
  return isMobile.any()
  //   return true // testing
}

function RedirectApp() {
  const location = useLocation()
  const history = useHistory()

  const { link = Links.LOGIN, token, continue_route } = location.state || {}

  const continue_ = () => {
    // go back to authorize
    if (continue_route) {
      history.push(continue_route)
    }
  }

  const redirect_link = `${link}?token=${token}`

  return (
    <>
      <div className="welcome">
        <div className="container">
          <h2 className="title">
            Tank<span>2</span>Tank
          </h2>
          <div>
            <button
              type="button"
              className="btn-primary login-button"
              onClick={() => continue_()}
            >
              Continue to website...
            </button>
          </div>
          <div className="link">
            <a href={redirect_link}> Open in Mobile App</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default RedirectApp
