import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import React, { useState } from 'react'

function SelectSheet({ worksheets, onSelect }) {
  const [worksheet, setWorksheet] = useState(-1)
  const handleChange = e => {
    setWorksheet(e.target.value)
  }
  return (
    <Box display="flex" pt={2}>
      <FormControl style={{ minWidth: 120 }}>
        <Select value={worksheet} onChange={handleChange} autoWidth>
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {worksheets.map((sheetName, index) => (
            <MenuItem key={`Worksheet-${sheetName}`} value={index}>
              {sheetName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          onSelect({
            Worksheets: 1,
            SheetNames: [worksheets[worksheet]],
            selectedSheet: worksheet,
          })
        }}
        style={{ marginLeft: 20 }}
        variant="contained"
      >
        Proceed
      </Button>
    </Box>
  )
}

export default SelectSheet
