import client from '../../hooks/useClient'

const contactsAPI = {}

contactsAPI.getContacts = data => {
  return client.get(`/contacts?search=${data.search}`)
}

contactsAPI.createContact = data => {
  return client.post(`/contacts`, data)
}

contactsAPI.getSingleContact = contactId => {
  return client.get(`/contacts/${contactId}`)
}

contactsAPI.editContact = (contactId, data) => {
  return client.put(`/contacts/${contactId}`, data)
}

contactsAPI.deleteContact = contactId => {
  return client.delete(`/contacts/${contactId}`)
}

export default contactsAPI
