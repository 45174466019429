import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import InfoIcon from '@material-ui/icons/Info'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/CheckCircle'
import { Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Modal from '../Modal'
import OrderItemUpdate from './OrderItemUpdate'
import useStateValue from '../../hooks/useStateValue'
import Checklist from './CheckList'

const useStyles = makeStyles({
  blueBox: {
    background: 'rgb(238, 242, 247, 0.4)',
    borderRadius: 7,
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxHeader: {
    fontFamily: 'Lato',
    fontSize: '13px',
    lineHeight: '16px',
    color: '#636363',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  boxContent: {
    fontFamily: 'Lato',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#1A1A1A',
    fontWeight: 600,
    marginTop: '10px',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '40px 35px 15px 35px',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const OrderItem = ({ item, onDone }) => {
  const [{ user }] = useStateValue()
  const [configuration, setConfiguration] = useState({})
  const [checklistModal, setChecklistModal] = useState(false)
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [showAnswers, setShowAnswers] = useState(null)

  useEffect(() => {
    const config = user?.user_info?.tenant?.configuration
    if (config) {
      const obj = {
        loading: config?.load_scan,
        unloading: config?.unload_scan,
      }
      setConfiguration(() => obj)
    }
  }, [user])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const ViewStatusModal = () => {
    const classes = useStyles()
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        style={{}}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <h2 style={{ color: '#003366' }}>Status</h2>
          <CloseIcon
            fontSize="25"
            onClick={handleClose}
            color="primary"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <DialogContent style={{ width: '532px' }}>
          <div className={classes.blueBox}>
            <div className={classes.boxHeader}>
              Product Name
              <div className={classes.boxContent}>{item?.product?.name}</div>
            </div>
            <div className={classes.boxHeader}>
              Ordered Qty
              <div className={classes.boxContent}>{item?.orderedQty}</div>
            </div>
            <div className={classes.boxHeader}>
              Shipped Qty
              <div className={classes.boxContent}>{item?.shippedQty}</div>
            </div>
          </div>
          <div className="orderCheckListWrapper" style={{ marginTop: '10px' }}>
            <div className="statusText" style={{ color: '#3298FF' }}>
              Loading Status
            </div>
            <div className="statusTextRight" style={{ color: '#3298FF' }}>
              Unloading Status
            </div>
          </div>
          <hr className="dividerStatus" />
          <div className="statusWrapper">
            <div className="loadingStatusWrapper">
              {configuration?.loading?.tank === true && (
                <div className="statusBoxContainer">
                  <div>
                    <p className="statusTitle">Tank</p>
                  </div>
                  <div className="statusBoxSecondChild">
                    <div>
                      {item.loadingData && item.loadingData.tank ? (
                        <DoneIcon
                          style={{ color: 'mediumseagreen', fontSize: '13px' }}
                        />
                      ) : (
                        <InfoIcon
                          style={{ color: '#DF585', fontSize: '13px' }}
                        />
                      )}
                    </div>
                    <div>
                      {item.loadingData && item.loadingData.tank && (
                        <p className="statusValue">{item?.loadingData?.tank}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {configuration?.loading?.compartment === true && (
                <div className="statusBoxContainer">
                  <div>
                    <p className="statusTitle">Compartment</p>
                  </div>
                  <div className="statusBoxSecondChild">
                    <div>
                      {item.loadingData && item.loadingData.compartment ? (
                        <DoneIcon
                          style={{ color: 'mediumseagreen', fontSize: '13px' }}
                        />
                      ) : (
                        <InfoIcon
                          style={{ color: '#DF5858', fontSize: '13px' }}
                        />
                      )}
                    </div>
                    <div>
                      {item.loadingData && item.loadingData.compartment ? (
                        <div className="timeanddate">
                          <p>
                            {' '}
                            {new Date(item?.loadingData.compartment)
                              .toUTCString()
                              .substr(0, 16)}
                          </p>
                          <p className="statusValue">
                            {' '}
                            {new Date(
                              item?.loadingData.compartment
                            ).toLocaleTimeString('en-US')}
                          </p>
                        </div>
                      ) : (
                        <p>Not Scanned</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="statusBoxContainer">
                <div>
                  <p className="statusTitle">Load Finished</p>
                </div>
                <div className="statusBoxSecondChild">
                  <div>
                    {item.loadingData && item.loadingData.loading_completed ? (
                      <DoneIcon
                        style={{ color: 'mediumseagreen', fontSize: '13px' }}
                      />
                    ) : (
                      <InfoIcon
                        style={{ color: '#DF5858', fontSize: '13px' }}
                      />
                    )}
                  </div>
                  <div>
                    {item.loadingData && item.loadingData.loading_completed ? (
                      <div className="timeanddate">
                        <p>
                          {' '}
                          {new Date(item?.loadingData.loading_completed)
                            .toUTCString()
                            .substr(0, 16)}
                        </p>
                        <p className="statusValue">
                          {' '}
                          {new Date(
                            item?.loadingData.loading_completed
                          ).toLocaleTimeString('en-US')}
                        </p>
                      </div>
                    ) : (
                      <p>Not Scanned</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* //loading end here */}

            <div className="unloadingStatusWrapper">
              {configuration?.unloading?.tank === true && (
                <div className="statusBoxContainer">
                  <div>
                    <p className="statusTitle">Tank</p>
                  </div>
                  <div className="statusBoxSecondChild">
                    <div>
                      {item.unloadingData && item.unloadingData.tank ? (
                        <DoneIcon
                          style={{ color: 'mediumseagreen', fontSize: '13px' }}
                        />
                      ) : (
                        <InfoIcon
                          style={{ color: '#DF585', fontSize: '13px' }}
                        />
                      )}
                    </div>
                    <div>
                      {item.unloadingData && item.unloadingData.tank && (
                        <p className="statusValue">
                          {item?.unloadingData?.tank}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {configuration?.unloading?.compartment === true && (
                <div className="statusBoxContainer">
                  <div>
                    <p className="statusTitle">Compartment</p>
                  </div>
                  <div className="statusBoxSecondChild">
                    <div>
                      {item.unloadingData && item.unloadingData.compartment ? (
                        <DoneIcon
                          style={{ color: 'mediumseagreen', fontSize: '13px' }}
                        />
                      ) : (
                        <InfoIcon
                          style={{ color: '#DF5858', fontSize: '13px' }}
                        />
                      )}
                    </div>
                    <div>
                      {item.unloadingData && item.unloadingData.compartment ? (
                        <div className="timeanddate">
                          <p>
                            {' '}
                            {new Date(item?.unloadingData.compartment)
                              .toUTCString()
                              .substr(0, 16)}
                          </p>
                          <p className="statusValue">
                            {' '}
                            {new Date(
                              item?.unloadingData.compartment
                            ).toLocaleTimeString('en-US')}
                          </p>
                        </div>
                      ) : (
                        <p>Not Scanned</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="statusBoxContainer">
                <div>
                  <p className="statusTitle">Unload Completed</p>
                </div>
                <div className="statusBoxSecondChild">
                  <div>
                    {item.unloadingData &&
                    item.unloadingData.unloading_completed ? (
                      <DoneIcon
                        style={{ color: 'mediumseagreen', fontSize: '13px' }}
                      />
                    ) : (
                      <InfoIcon
                        style={{ color: '#DF5858', fontSize: '13px' }}
                      />
                    )}
                  </div>
                  <div>
                    {item.unloadingData &&
                    item.unloadingData.unloading_completed ? (
                      <div className="timeanddate">
                        <p>
                          {' '}
                          {new Date(item?.unloadingData.unloading_completed)
                            .toUTCString()
                            .substr(0, 16)}
                        </p>
                        <p className="statusValue">
                          {' '}
                          {new Date(
                            item?.unloadingData.unloading_completed
                          ).toLocaleTimeString('en-US')}
                        </p>
                      </div>
                    ) : (
                      <p>Not Scanned</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
  const TableCell = ({ children, minWidth }) => {
    return (
      <Box className="table-body-column" flex={1} style={{ minWidth }}>
        {children}
      </Box>
    )
  }
  return (
    <>
      <ViewStatusModal />
      {/* <StandAloneModal /> */}
      <Box className="table-body-row">
        <TableCell>
          {item.orderedQty} {item.qtyUnit}
        </TableCell>
        <TableCell>
          {item.shippedQty} {item.qtyUnit}
        </TableCell>
        <TableCell minWidth={140}>{item.product?.name}</TableCell>
        <TableCell>
          {item.netWeight || '0'} {item.netWeightUnit}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setChecklistModal(!checklistModal)
            }}
            style={{ width: 'auto', marginTop: '-7px' }}
            variant="outlined"
          >
            Show
          </Button>
        </TableCell>

        <TableCell>
          <Button
            onClick={handleClickOpen}
            style={{ width: 'auto', marginTop: '-7px' }}
            variant="outlined"
          >
            View
          </Button>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => setIsUpdateModal(!isUpdateModal)}
            style={{ width: 'auto', marginTop: '-7px' }}
            variant="outlined"
          >
            Edit
          </Button>
        </TableCell>
      </Box>

      {isUpdateModal && (
        <OrderItemUpdate
          onDone={onDone}
          data={item}
          visible={isUpdateModal}
          onClose={() => setIsUpdateModal(!isUpdateModal)}
        />
      )}
      {
        <Modal
          style={{ maxWidth: '800px' }}
          visible={checklistModal}
          onClose={() => {
            setChecklistModal(false)
            setShowAnswers(null)
          }}
        >
          <Checklist
            bolId={item?.bol}
            onClose={() => {
              setChecklistModal(false)
            }}
            type="Product Checklist"
            setShowAnswers={setShowAnswers}
            showAnswers={showAnswers}
          />
        </Modal>
      }
    </>
  )
}

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default OrderItem
