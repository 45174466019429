import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import TableHead from '../../Reusable/TableHead'
import Scrollable from '../../Reusable/Scrollable'
import TableBody from '../../Reusable/TableBody'
import Container from '../../Reusable/Container'
import Loading from '../../Loading'
import routes from '../../../routes/route_contants'
import { formatContactNo } from '../../../helpers/util'

const columns = [
  {
    label: ' ',
    value: e => (
      <img
        src={`${process.env.REACT_APP_API_URL}users/${e.userId}/profile-picture/`}
        alt={`${e.first_name}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src =
            'https://www.pngkit.com/png/detail/281-2812821_user-account-management-logo-user-icon-png.png'
        }}
        style={{
          height: 38,
          width: 38,
          marginRight: 10,
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
    ),
  },
  {
    label: 'Name',
    value: e => `${e?.firstName || '-'} ${e?.lastName || ''}`,
    minWidth: 180,
  },
  {
    label: 'Role',
    value: e =>
      e?.role?.role_name.charAt(0).toUpperCase() + e?.role?.role_name.slice(1),
  },
  {
    label: 'Email ID',
    value: e => e?.email,
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'Contact No.',
    value: e => formatContactNo(e?.phoneNo),
  },
  {
    label: 'Status',
    value: e => (e?.active ? 'Active' : 'Inactive'),
  },
]

function TeamMembersList({ type, apiResult }) {
  const history = useHistory()

  const handleRowClick = row => {
    history.push(`${routes.TEAM_MEMBERS_INFO}/${row.userId}`)
  }
  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={handleRowClick}
          />
        )}
      </Scrollable>
    </Container>
  )
}

TeamMembersList.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

TeamMembersList.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default TeamMembersList
