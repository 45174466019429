import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import { PaletteColors } from '../../../config/colors'
import { throttled } from '../../../helpers/util'

function SearchBar({ placeholder, onChange }) {
  const handleChange = e => {
    const text = e.target.value
    throttled(() => onChange(text), 0.5)
  }
  return (
    <Box display="flex" alignItems="center" className="search-bar-container">
      <div className="search-bar-icon">
        <SearchIcon
          style={{ color: PaletteColors.textLight }}
          fontSize="small"
        />
      </div>
      <input
        type="text"
        name=""
        className="search-bar"
        onInput={handleChange}
        placeholder={placeholder}
      />
    </Box>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

SearchBar.defaultProps = {
  placeholder: 'Search',
}

export default SearchBar
