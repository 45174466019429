import { createMuiTheme } from '@material-ui/core'
import config from './config'
import colorPalette from './colorPalette'
import componentsOverrides from './overrides'
import themeColors from './themeColors.scss'

const theme = createMuiTheme({
  palette: colorPalette(themeColors),
  props: {
    MuiListItem: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextFied: {},
    MuiSelect: {
      disableUnderline: true,
    },
    MuiTabs: {
      // indicatorColor: 'transparent',
      // disableRipple: true,
      disabled: true,
      TabIndicatorProps: {
        style: {
          // background: `${themeColors.secondaryMain}40`,
          color: '#FFFFFF !important',
          // background: '#3298FF',
          // height: '100%',
          minWidth: 0,
          // borderRadius: '30px',
        },
      },
    },
    MuiTab: {
      disableRipple: true,
      style: {
        color: themeColors.secondaryMain,
        opacity: 1,
      },
    },
  },
  typography: {
    // fontSize: 14,
    fontFamily: config.fontFamily,
  },
  overrides: componentsOverrides(),
})

export default theme
