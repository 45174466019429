import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import {
  Box,
  Divider,
  Tabs,
  Tab,
  makeStyles,
  Select,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SelectInput from '@material-ui/core/Select/SelectInput'
import moment from 'moment'
import useForm from '../../hooks/useForm'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TextInput from '../Reusable/Widgets/TextInput'
import DateInput from '../Reusable/Widgets/DateInput'
import CheckBoxInput from '../Reusable/Widgets/CheckBoxInput'
import AutoCompleteInput from '../Reusable/Widgets/AutoCompleteInput'
import Loading from '../Loading'
import useStateValue from '../../hooks/useStateValue'
import routeAPI from '../../api/route'
import tenantAPI from '../../api/tenant'
import useAPILocal from '../../hooks/useAPILocal'
import userAPI from '../../api/user'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
}))
const StepComponent = ({ index, label }) => (
  <Box display="flex">
    <Box mr={2}>{index + 1}</Box> {label}
  </Box>
)

const InputTypes = {
  text: { Component: TextInput },
  select: {
    Component: SelectInput,
    props: {
      SelectComponent: withStyles({ icon: { marginRight: 13 } })(Select),
    },
  },
  check: { Component: CheckBoxInput },
  autocomplete: { Component: AutoCompleteInput },
  date: { Component: DateInput },
}

const init = {
  net_weight: '',
  gross_weight: '',
  shipped_qty: '',
  ordered_qty: '',
  qty_unit: '',
  chem_un: '',
}

function ShipmentEdit({ data }) {
  const [{ user }] = useStateValue()
  const [userRole, setUserRole] = useState('')
  const [driverDisabled, setDriverDisabled] = useState(true)
  const [validationSchema, setValidationSchema] = useState({
    carrier: ['required'],
  })
  const [carrierDisabled, setCarrierDisabled] = useState(true)
  const [shipmentData, setShipmentData] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const shipmentInfo = useAPILocal(routeAPI.shipmentInfo)
  const shipmentInfoUpdate = useAPILocal(routeAPI.shipmentUpdate)

  const fetchShipmentDetails = async () => {
    await shipmentInfo.request(params.shipment_id)
  }
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    document.title = 'Edit Shipment'
  }, [])
  useEffect(() => {
    const role = user?.user_info?.role?.role_name
    if (role === 'carrier') {
      setDriverDisabled(false)
      const schema = { driver: ['required'] }
      setValidationSchema(schema)
    } else if (role === 'admin') {
      setCarrierDisabled(false)
      const schema = { carrier: ['required'] }
      setValidationSchema(schema)
    }
  }, [user])

  useEffect(() => {
    fetchShipmentDetails()
  }, [])

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm({ driver: {}, carrier: {} }, validationSchema, async result => {
      const updateParams = {
        shipment_id: shipmentData.shipment_id,
      }
      if (shipmentData?.driver?.user_id !== result?.driver?.user_id) {
        updateParams.driver = result?.driver?.user_id
      }
      if (shipmentData?.carrier?.tenant_id !== result?.carrier?.tenant_id) {
        // updateParams.driver = null
        updateParams.carrier = result?.carrier?.tenant_id
      }
      try {
        setIsUpdating(() => true)
        await shipmentInfoUpdate.request(
          {
            carrier: result?.carrier?.tenant_id,
            driver: result?.driver?.user_id,
            location: shipmentData?.location?.location_id,
          },
          params.shipment_id
        )
        history.goBack()
      } catch (err) {
        setIsUpdating(() => false)
        alert('Error')
      }
    })
  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  useEffect(() => {
    setShipmentData(shipmentInfo?.data)
    setValues(() => ({
      driver: shipmentInfo?.data?.driver || {},
      carrier: shipmentInfo?.data?.carrier || {},
    }))
  }, [shipmentInfo.isResolved])

  return (
    // <>
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle backButton title="Edit Shipment" />
        </PageHeading>
      </Fixed>
      {!shipmentInfo.isResolved || isUpdating ? (
        <Loading />
      ) : (
        <>
          <Scrollable mt={10} style={{ height: '100%' }}>
            <Box display="flex" height="100%">
              <Box overflow="auto" height="100%">
                {/* <Box display="flex"> */}
                {/* <Box mr={2}>1</Box> Shipment Details */}
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  value={currentStep}
                  onChange={(e, v) => setCurrentStep(v)}
                >
                  <Tab
                    className={classes.tabs}
                    wrapped={false}
                    key="Shipment Details"
                    label={<StepComponent index={0} label="Shipment Details" />}
                  />
                </Tabs>
                {/* </Box> */}
              </Box>
              <Box mx={6} px={2}>
                <Divider orientation="vertical" style={{ height: '60%' }} />
              </Box>
              <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
                <Box display="flex" className="form-row">
                  <TextInput
                    flex="1"
                    label="Pickup Number"
                    name="pickup_number"
                    disabled
                    value={shipmentData?.pickupNumber}
                  />
                  <DateInput
                    flex="1"
                    label="Shipment Date"
                    name="shipment_date"
                    value={moment(shipmentInfo?.created_on).format(
                      'YYYY-MM-DD'
                    )}
                    disabled
                  />
                </Box>
                <Box display="flex" className="form-row">
                  {driverDisabled ? (
                    <TextInput
                      flex="1"
                      label="Driver"
                      name="driver"
                      value={`${values.driver?.first_name} ${values.driver?.last_name}`}
                      disabled
                    />
                  ) : (
                    <AutoCompleteInput
                      flex="1"
                      label="Drivccer"
                      {...additionalProps('driver')}
                      // defaultValue={values.driver}
                      value={values.driver}
                      api={{ src: userAPI.list, params: { role: 9 } }}
                      renderItem={option =>
                        `${option?.firstName} ${option?.lastName}`
                      }
                      disabled={driverDisabled}
                    />
                  )}

                  <TextInput
                    flex="1"
                    label="Shipper"
                    name="shipper"
                    value={shipmentData?.shipper.tenant_name}
                    disabled
                  />
                </Box>
                <Box display="flex" className="form-row">
                  {carrierDisabled ? (
                    <TextInput
                      flex="1"
                      label="Carrier"
                      name="carrier"
                      value={values.carrier?.tenant_name}
                      disabled
                    />
                  ) : (
                    <AutoCompleteInput
                      flex="0.5"
                      label="Carrier"
                      value={values.carrier}
                      api={{
                        src: tenantAPI.tenants,
                        params: { isActive: true },
                      }}
                      renderItem={option => option?.tenant_name}
                      {...additionalProps('carrier')}
                      disabled={carrierDisabled}
                    />
                  )}
                </Box>
                <Box mt={6} display="flex" justifyContent="flex-end">
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Finish
                  </Button>
                </Box>
              </Box>
            </Box>
          </Scrollable>
          {/* <Fixed> */}
          {/* </Fixed> */}
        </>
      )}
    </Container>
    // </>
  )
}

export default ShipmentEdit
