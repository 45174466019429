import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Box,
  Divider,
  Tabs,
  Tab,
  makeStyles,
  Select,
  Button,
  Typography,
  Grid,
  Dialog,
} from '@material-ui/core'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Loading from '../../Loading'
import useAPILocal from '../../../hooks/useAPILocal'
import bolAPI from '../../../api/bol'
import fileAPI from '../../../api/file'

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
      borderBottom: '1px solid red',
    },

    borderBottom: '1px solid rgb(182, 182, 182,0.5)',
  },
  singleTab: {
    '& hover': {
      display: 'none',
    },
  },
  greyCard: {
    background: '#F1F5F9',
    borderRadius: '10px',
    width: '100%',
    padding: '20px 40px 40px 40px',
  },
  content1: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#636363',
    marginTop: '10px',
  },
  content2: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Lato',
    fontWeight: 500,
    color: '#1A1A1A',
    marginTop: '8px',
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  imageSingle: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    margin: '20px 20px 20px 0px',
  },
  imageSingleLarge: {
    width: '400px',
    height: '400px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  grid: {
    marginTop: '20px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  addNew: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    margin: '20px 20px 20px 0px',
    border: '1px dashed #3298FF',
    alignItems: 'center',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addNewText: {
    fontSize: '14px',
    lineHeight: '14px',
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#3298FF',
    textAlign: 'center',
    marginTop: '10px',
  },
})

function SimpleDialog(props) {
  const { onClose, open, imageData } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {imageData ? <img src={imageData} alt="upl" /> : <Loading fullScreen />}
    </Dialog>
  )
}

function PhotosAndCommentView({ picture }) {
  const classes = useStyles()
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [imageData, setImageData] = useState()

  const [imagesData, setImagesData] = useState([])
  const [comments, setComments] = useState()
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const getSingleComment = useAPILocal(bolAPI.getSingleComment)
  const getFileMiniature = useAPILocal(fileAPI.getFileMiniature)
  const getFile = useAPILocal(fileAPI.getFile)

  useEffect(() => {
    if (params.comment_id) {
      getSingleComment.request(params.comment_id).then(res => {
        setComments(res?.data)
        if (res?.data?.images && res?.data?.images.length > 0) {
          const imagePromises = res?.data?.images?.map(image =>
            getFileMiniature.request(image.id).then(res => ({
              id: image.id,
              data: res?.data,
            }))
          )
          Promise.all(imagePromises).then(imagesDataArray => {
            setImagesData(imagesDataArray)
            setImagesLoaded(true) // Update the imagesLoaded state here
          })
        } else {
          setImagesLoaded(true) // Update the imagesLoaded state here if there are no images
        }
      })
    }
  }, [])
  const handleClickOpen = id => {
    getFile.request(id).then(res => {
      setImageData(res?.data)
    })
    if (id) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setImageData(null)
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading mb={2}>
          <PageTitle backButton />
        </PageHeading>
      </Fixed>
      {!getSingleComment?.isResolved ? (
        <Loading />
      ) : (
        <>
          <div className={classes.greyCard}>
            <Grid container>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>
                  Uploaded By
                </Typography>
                <Typography className={classes.content2}>
                  {comments?.createdBy?.first_name || '-'}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>
                  Date & Time
                </Typography>
                <Typography className={classes.content2}>
                  {comments?.createdBy?.created_on || '-'}
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.grid}>
                <Typography className={classes.content1}>Role</Typography>
                <Typography className={classes.content2}>
                  {comments?.createdBy?.role?.role_name || '-'}
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.grid}>
                <Typography className={classes.content1}>Comment</Typography>
                <Typography className={classes.content2}>
                  {comments?.comment || ``}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.images}>
            {imagesData.length > 0 ? (
              imagesData.map(item => (
                <img
                  src={item.data}
                  key={item.id}
                  className={classes.imageSingle}
                  onClick={() => handleClickOpen(item.id)}
                  alt="pic"
                />
              ))
            ) : !imagesLoaded ? (
              Array.isArray(comments?.images) && comments.images.length > 0 ? (
                comments.images.map((_, index) => (
                  <div className={classes.imageSingle}>
                    <Loading key={index} />
                  </div>
                ))
              ) : (
                <div className={classes.imageSingle}>
                  <Loading />
                </div>
              )
            ) : null}
          </div>
        </>
      )}

      <SimpleDialog open={open} onClose={handleClose} imageData={imageData} />
    </Container>
  )
}

export default PhotosAndCommentView
