import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import {
  Box,
  Divider,
  Tabs,
  Tab,
  makeStyles,
  Select,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core'
import Dropzone from 'react-dropzone'

import Upload from '../../../assets/upload.png'
import useForm from '../../../hooks/useForm'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import TextInput from '../../Reusable/Widgets/TextInput'
import Loading from '../../Loading'
import useAPILocal from '../../../hooks/useAPILocal'
import bolAPI from '../../../api/bol'
import routeAPI from '../../../api/route'
import { Alert } from '@mui/material'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageSingle: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    margin: '20px 20px 20px 0px',
  },
  imageSingleLarge: {
    width: '400px',
    height: '400px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  addNew: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    margin: '20px 20px 20px 0px',
    border: '1px dashed #3298FF',
    alignItems: 'center',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addNewText: {
    fontSize: '14px',
    lineHeight: '14px',
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#3298FF',
    textAlign: 'center',
    marginTop: '10px',
  },
}))
const StepComponent = ({ index, label }) => (
  <Box display="flex">
    <Box mr={2}>{index + 1}</Box> {label}
  </Box>
)

const validationSchema = {}

function AddPhotos({ data }) {
  const [orderPicData, setOrderPicData] = useState({ comment: '' })
  const [image, setImage] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [isPicUpload, setIsPicUpload] = useState(false)
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const orderDetail = useAPILocal(routeAPI.shipmentInfo)
  const bolComments = useAPILocal(bolAPI.bolComments)
  const [imagesArray, setImagesArray] = useState([])
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(0)

  const pushToServer = async (images, comment) => {
    const response = await bolComments.request(params.order_id, {
      images: images,
      comment: comment,
    })
    if (!response.ok) {
      throw new Error('Error from server')
    }
  }

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm(orderPicData, validationSchema, async result => {
      try {
        setIsUpdating(() => true)
        await pushToServer(imagesArray, result.comment)
      } catch (err) {
        setIsUpdating(() => false)
      }
    })

  useEffect(() => {
    if (params.actionType === 'comment') {
      setImage(() => null)
      if (params.actionType !== 'add') {
        const pictureData = orderDetail?.data?.pictures?.[params.index]
        setOrderPicData(() => pictureData)

        setValues(() => ({
          comment: pictureData?.comment || '',
        }))
      }
    } else if (params.type !== 'add') {
      const pictureData = orderDetail?.data?.pictures?.[params.index]
      setOrderPicData(() => pictureData)

      setValues(() => ({
        comment: pictureData?.comment || '',
      }))
      const picUrl = pictureData?.url || []
      const url = picUrl ? (Array.isArray(picUrl) ? picUrl : [picUrl]) : null
      setImage(() => url)
    }
  }, [orderDetail.isResolved])

  useEffect(() => {
    if (bolComments.isResolved) {
      setOpenSnackSuccess(true)
      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [bolComments.isResolved])

  useEffect(() => {
    if (bolComments.isRejected) {
      setOpenSnack(true);
    }
  }, [bolComments.isRejected]);

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  const handleDrop = files => {
    files.forEach(file => {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(prevImages => [...prevImages, reader.result])
      }
      reader.readAsDataURL(file)
    })
    setImagesArray(prevImagesArray => [...prevImagesArray, ...files])
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  return (
    // <>
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading mb={2}>
          <PageTitle backButton title="Add Photos & Comments" />
        </PageHeading>
      </Fixed>
      {isUpdating || isPicUpload ? (
        <Loading />
      ) : (
        <>
          <Scrollable mt={10} style={{ height: '100%' }}>
            <Box display="flex" height="100%">
              <Box overflow="auto" height="100%">
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  value={currentStep}
                  onChange={(e, v) => setCurrentStep(v)}
                >
                  <Tab
                    className={classes.tabs}
                    wrapped={false}
                    key="Add Photos & Comments"
                    label={
                      <StepComponent index={0} label="Add Photos & Comments" />
                    }
                  />
                </Tabs>
                {/* </Box> */}
              </Box>
              <Box mx={6} px={2}>
                <Divider orientation="vertical" style={{ height: '60%' }} />
              </Box>
              <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
                {image ? (
                  <div className={classes.images}>
                    <Dropzone
                      accept="image/jpeg,image/png"
                      multiple
                      onDrop={acceptedFiles => handleDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Box className={classes.addNew}>
                              <img src={Upload} alt="al" />
                              <Typography className={classes.addNewText}>
                                Add More Photos
                              </Typography>
                            </Box>
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    {image?.map(src => (
                      <img
                        src={src}
                        key={src}
                        className={classes.imageSingle}
                        alt=""
                      />
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                <Box display="flex" className="form-row">
                  <TextInput
                    multiline
                    flex="1"
                    label="Comments"
                    name="comment"
                    {...additionalProps('comment')}
                  />
                </Box>
                <Box mt={6} display="flex" justifyContent="flex-end">
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Add Comment
                  </Button>
                </Box>
              </Box>
            </Box>
          </Scrollable>
        </>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" onClose={handleCloseSnack}>
          {bolComments?.data?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">
          Image successfully uploaded
        </Alert>
      </Snackbar>
    </Container>
    // </>
  )
}

export default AddPhotos
