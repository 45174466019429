

import React, { useEffect, useState, useMemo } from 'react'
import { Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import TableHead from '../Reusable/TableHead'
import Fixed from '../Reusable/Fixed'
import { returnValue } from '../MyAccount/utils'
import Container from '../Reusable/Container'
import chemicalAPI from '../../api/chemical'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import routes from '../../routes/route_contants'
import SearchBar from '../Reusable/Widgets/SearchBar'
import withPaginationLocal from '../../HOC/withPaginationLocal'
import Chemicals from './Chemicals'
import useAPILocal from '../../hooks/useAPILocal'

const columns = [
  { label: 'Chemical Name', value: returnValue('name') },
  { label: 'UN Number', value: returnValue('unNumber') },
  { label: 'Hazard Class', value: returnValue('hazardClass') },
]

function ChemicalsPage() {
  const productList = useAPILocal(chemicalAPI.newList)
  const history = useHistory()
  const [products, setProducts] = useState([])
  const [searchStr, setSearchStr] = useState('')

  useEffect(() => {
    if (products === []) {
      productList
        .request()
        .then(res => setProducts(res.ok ? res.data.results : []))
    }
  }, [])

  const handleRedirect = () => {
    history.push(routes.CHEMICAL_ADD)
  }

  const handleSearch = val => {
    if (val?.length >= 3) {
      setSearchStr(val)
    }
    if (val?.length === 0) {
      setSearchStr(undefined)
    }
  }

  const PaginatedChemicals = withPaginationLocal(chemicalAPI.newList, {
    search: searchStr || '',
    limit: 12,
  })(Chemicals)

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Chemicals" />
          <Box>
            <Button
              onClick={handleRedirect}
              variant="contained"
              color="primary"
            >
              Add Chemical
            </Button>
          </Box>
        </PageHeading>
        <Box mt={2}>
          <PageHeading alignItems="center">
            <Box />
            <Box display="flex">
              <SearchBar onChange={handleSearch} />
            </Box>
          </PageHeading>
        </Box>
      </Fixed>
      <Fixed>
        <Box mt={2}>
          <TableHead columns={columns} />
        </Box>
      </Fixed>
      <PaginatedChemicals />
    </Container>
  )
}

export default ChemicalsPage
