

const fieldVisible = () => true

export const defaultCheckboxLabel = 'Check if apply'

export const volumeUnit = [
  { label: 'Liters', value: 'liters' },
  { label: 'Gallons', value: 'gallons' },
  { label: 'Wet Tons', value: 'wet_tons' },
  { label: 'Pounds', value: 'pounds' },
  { label: 'Kg', value: 'kg' },
  { label: 'Metric Tons', value: 'metric_tons' },
]

export const diameterUnit = [
  { label: 'in', value: 'in' },
  { label: 'ft', value: 'ft' },
  { label: 'cm', value: 'cm' },
  { label: 'm', value: 'm' },
]

class FormField {
  constructor(name, label, initial, validations, title) {
    this.name = name
    this.label = label
    this.initial = initial
    this.validations = validations
    this.title = title
  }
}

class IText extends FormField {
  constructor(name, label, initial, validations, options = []) {
    super(name, label, initial, validations)
    this.type = 'text'
    Object.keys(options).forEach(option => {
      this[option] = options[option]
    })
  }
}

class IDate extends FormField {
  constructor(name, label, initial, validations) {
    super(name, label, initial, validations)
    this.type = 'date'
  }
}

class ISelect extends FormField {
  constructor(name, label, initial, validations, options) {
    super(name, label, initial, validations)
    this.type = 'select'
    this.options = options
  }
}

class IAutoComplete extends FormField {
  constructor(
    name,
    label,
    initial,
    validations,
    api,
    renderItem,
    title,
    multiple,
    hidden,
    disabled,
    clearOnBlur
  ) {
    super(name, label, initial, validations, title)
    this.type = 'autocomplete'
    this.api = api
    this.renderItem = renderItem
    this.multiple = multiple || false
    this.hidden = hidden || false
    this.disabled = disabled || false
    this.clearOnBlur = clearOnBlur || true
  }
}

class MultipleAutoComplete extends IAutoComplete {
  constructor(...props) {
    super(...props)
    const members = this
    members.multiple = true
    const options = props[props.length - 1]
    Object.keys(options).forEach(option => {
      members[option] = options[option]
    })
  }
}

function Measurement(
  name,
  label,
  unit_name,
  unit_type = 'volume',
  validations = []
) {
  const unit = unit_name || `${name}_unit`
  return {
    key: `${label}-key`,
    visible: fieldVisible,
    children: [
      new IText(name, label, '', ['number', ...validations]),
      new ISelect(
        unit,
        'Unit',
        '',
        [`requiredDepends:${name}`],
        unit_type === 'volume' ? volumeUnit : diameterUnit
      ),
    ],
  }
}

const modifyFields = data => {
  const emailExtractor = array => {
    const dt = Array.isArray(array) ? array : []
    return dt?.map(item => item?.email)
  }

  const newValues = {}
  Object.keys(data).map(key => {
    if (data[key]) {
      newValues[key] = data[key]
    } else if (data[key] === false) {
      newValues[key] = data[key]
    }
    return key
  })
  if (data?.chemical?.id) newValues.chemicalId = data.chemical.id
  newValues.chemical = undefined
  newValues.spillManagementPlanAvailable = emailExtractor(
    data?.spillManagementPlanAvailable
  )
  newValues.location = data?.location?.location_id
  newValues.doesSomeoneStayWithDriverDuringOffloading = emailExtractor(
    data?.doesSomeoneStayWithDriverDuringOffloading
  )
  newValues.customer = data?.customer?.customer_id
  newValues.supportedProducts = data?.supportedProducts
  if (data?.products) {
    const products = data?.products.map(product => product?.id)
    newValues.products = products
    if (products.length === 0) {
      delete newValues.products
    }
  }

  return newValues
}

export {
  ISelect,
  IAutoComplete,
  MultipleAutoComplete,
  IText,
  IDate,
  Measurement,
  fieldVisible,
  modifyFields,
}
