import client from '../../hooks/useClient'

const routeAPI = {}

routeAPI.list = async params => {
  return client.get(`/route/list`, params)
}

routeAPI.shipmentInfo = async routeId => {
  return client.get(`/route/${routeId}`)
}

routeAPI.shipmentUpdate = async (data, routeId) => {
  return client.patch(`/route/info/${routeId}`, data)
}

routeAPI.bulkShipmentUpload = async file => {
  const formData = new FormData()
  formData.append('file', file)
  return client.post(`/route/bulk/create`, formData)
}

export default routeAPI
