import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Typography
} from "@material-ui/core";
import { Alert } from "@mui/material";
import productAPI from "../../api/product";
import routes from "../../routes/route_contants";
import "./TankDetailsPage.scss";
import Container from "../Reusable/Container";
import Fixed from "../Reusable/Fixed";
import PageHeading from "../Reusable/PageHeading";
import PageTitle from "../Reusable/PageTitle";
import Scrollable from "../Reusable/Scrollable";
import { PaletteColors } from "../../config/colors";

import { formatContactNo, formatLocation } from "../../helpers/util";
import TableHead from "../Reusable/TableHead";
import TableBody from "../Reusable/TableBody";
import Loading from "../Order/Tabs/Loading";
import LoadingIndicator from "../Order/Tabs/Loading";
import useAPILocal from "../../hooks/useAPILocal";

function convertGrade(grade) {
  return grade
    ? grade
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`)
    : undefined;
}

const fields = [
  { label: "Product UN Number", value: e => e?.unNumber },
  { label: "Product Name", value: e => e?.name },
  { label: "Product Code", value: e => e?.code },
  { label: "Product Description", value: e => e?.description },
  { label: "Product Grade", value: e => convertGrade(e?.grade) || "-" },
  { label: "Product Concentration (%)", value: e => e?.concentration || "-" },
  { label: "Chemical UN Number", value: e => e?.chemical?.unNumber },
  { label: "Chemical Name", value: e => e?.chemical?.name },
  {
    label: "Containing Tanks",
    value: e => e?.tanks?.length
  }
];

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const columns = [
  {
    label: "Tank No./Name",
    value: e => e.tankRegistrationNo
  },
  {
    label: "Parnter Details",
    value: e => (e.customer ? e.customer.customer_name : "-")
  },
  {
    label: "Location",
    value: e =>
      formatLocation(
        e?.location,
        "location_name postal_address city country postal_code"
      )
  },
  {
    label: "Contact No.",
    value: e =>
      e.location?.phone_no ? formatContactNo(e.location?.phone_no) : "-"
  }
];

export default function ProductDetailsPage() {
  const [productInfo, setProductInfo] = useState([]);
  const [tanksInfo, setTanksInfo] = useState([]);
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const path = useParams();

  const productInfoApi = useAPILocal(productAPI.info);
  const productsTanksApi = useAPILocal(productAPI.productTanks);
  const deleteProduct = useAPILocal(productAPI.deleteProduct);
  const [openSnack, setOpenSnack] = React.useState(false);

  const getProductInfo = () => {
    productInfoApi.request(path.id).then(res => setProductInfo(res.data));
  };

  const getTanksInfo = () => {
    if (path.id)
      productsTanksApi
        .request({ productId: path.id, search: "" })
        .then(res => setTanksInfo(res.data?.results));
  };

  const editChemicalInfoData = () => {
    history.push({
      pathname: `${routes.PRODUCT_ADD}`,
      state: {
        mode: "update",
        details: productInfo
      }
    });
  };

  useEffect(() => {
    getProductInfo();
    getTanksInfo();
  }, [path.id]);

  if (productInfoApi.isPending) {
    return <Loading fullScreen />;
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle
            backButton={() => {
              if (location.pathname.startsWith(routes.MY_COMPANY_PRODUCT_DETAILS)) {
                history.push(`${routes.MY_COMPANY}#products`);
              } else {
                history.push(`${routes.TANKS_LIST}#products`);
              }
            }}
              title={productInfo?.name}
              />
              <div>
              <Button
              variant="outlined"
              color="primary"
              style={{
              color: "red",
              borderColor: "red",
              marginRight: "10px",
            }}
            onClick={() => setOpen(true)}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={editChemicalInfoData}
          >
            Edit Details
          </Button>
          {open && (
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete this product ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    deleteProduct.request(path.id).then(() => {
                      if (deleteProduct?.isRejected) setOpen(false);
                      else if (deleteProduct?.status === "idle") {
                        setOpen(false);
                      }
                      setTimeout(() => {
                        history.push(routes.TANKS_LIST);
                      }, 3000);
                    });
                  }}
                  color="primary"
                >
                  {deleteProduct.isPending ? <LoadingIndicator /> : "Yes"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </PageHeading>
    </Fixed>
  <Scrollable p={3}>
    <Box
      className="shipments-list"
      style={{
        backgroundColor: PaletteColors.backgroundPrimary,
        borderRadius: 9
      }}
    >
      <div className="productbox">
        <div className="productboxin">
          {productInfoApi.isPending ? (
            <Loading m={4} />
          ) : (
            <>
              {fields.map(field => (
                <Box key={field.label}>
                  <Item
                    label={field.label}
                    value={
                      field.label === "Containing Tanks"
                        ? field.value(tanksInfo)
                        : field.value(productInfo)
                    }
                  />
                </Box>
              ))}
              {Object.entries(productInfoApi?.data?.extra_fields || {}).map(
                field => (
                  <>
                    <Box key={field.label}>
                      <Item label={field[0]} value={field[1]} />
                    </Box>
                  </>
                )
              )}
            </>
          )}
        </div>
      </div>
    </Box>
    <div style={{ marginTop: "50px", marginBottom: "24px" }}>
      <div className="containingtanks">Containing Tanks</div>
    </div>
    <TableHead columns={columns} />
    {productsTanksApi?.isPending ? (
      <Loading m={4} />
    ) : (
      <TableBody
        columns={columns}
        data={tanksInfo}
        rowOnClick={row =>
          history.push(
            `/tanks&products/tank-details/${row.tankRegistrationNo}`
          )
        }
      />
    )}
    <Snackbar
      open={deleteProduct.isRejected}
      autoHideDuration={6000}
      onClose={handleCloseSnack}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity="error" onClose={handleCloseSnack}>
        {deleteProduct?.data?.message}
      </Alert>
    </Snackbar>
    <Snackbar
      open={deleteProduct?.isResolved}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity="success">Product deleted succesfully</Alert>
    </Snackbar>
  </Scrollable>;
</Container>
)
}
