

import React from 'react'
import './search.scss'
import Icon from '../Icon'
import ShipmentListCard from '../Shipment/ListCard'
import productAPI from '../../api/product'
import bolAPI from '../../api/bol'
import routeAPI from '../../api/route'
import connectionsAPI from '../../api/connections'
import userAPI from '../../api/user'
import { formatLocation } from '../../helpers/util'

const color = {}

const UserIcon = ({ first_name, last_name }) => {
  const inits = `${first_name[0] || ''}${last_name[0] || ''}`
  if (!color[inits])
    color[inits] = `#${(((1 << 24) * Math.random()) | 0).toString(16)}`

  return (
    <div
      className="user-profile-back"
      style={{
        backgroundColor: color[inits],
      }}
    >
      <div
        className="text"
        data-initial={`${first_name[0]}${last_name[0]}`}
      ></div>
    </div>
  )
}

const Users = props => {
  const { firstName, lastName, email, phoneNumber, role, tenant } = props
  return (
    <div className="card">
      <div className="column">
        {firstName && lastName && (
          <UserIcon first_name={firstName} last_name={lastName} />
        )}
        {/* <Icon name="account_circle" /> */}
      </div>

      <div className="column p-2">
        <div className="row font-bold">{`${firstName} ${lastName} |  ${role?.role_name} @${tenant?.tenant_name}`}</div>
        <div className="row">
          {email && (
            <span className="row px-4">
              <Icon name="mail" size={14} color="dodgerblue" />
              <p className="ml-1 mr-2">{email}</p>
            </span>
          )}
          {phoneNumber && (
            <span className="row px-4">
              <Icon name="phone" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{phoneNumber}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Tank = props => {
  const {
    tankDetails: { tankRegistrationNo, tenant, location },
    onClick = undefined,
  } = props
  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="local_shipping" />
      </div>

      <div className="column p-2">
        <div className="row font-bold">{`${tankRegistrationNo}`}</div>
        <div className="row">
          {tenant && (
            <span className="row">
              <Icon name="business" size={14} color="dodgerblue" />
              <p className="ml-1 mr-2">{tenant.tenant_name}</p>
            </span>
          )}
          {location && (
            <span className="row">
              <Icon name="location_on" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{location.location_name}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Partners = props => {
  const { customerDetails, onClick = undefined } = props

  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="person" />
      </div>

      <div className="column p-2">
        {customerDetails?.partner?.tenant_name && (
          <span className="row">
            <Icon name="person" size={14} color="#148f77" />
            <p className="ml-1 mr-2">{customerDetails?.partner?.tenant_name}</p>
          </span>
        )}
        <div className="row">
          {/* {customer_tenant && (
            <span className="row">
              <Icon name="business" size={14} color="dodgerblue" />
              <p className="ml-1 mr-2">{customer_tenant}</p>
            </span>
          )} */}
          {customerDetails?.partner?.primary_location && (
            <span className="row">
              <Icon name="location_on" size={14} color="#148f77" />
              <p className="ml-1 mr-2">
                {formatLocation(customerDetails?.partner?.primary_location)}
              </p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Shipment = props => {
  const {
    shipment: { carrier, shipper, status, shipment_id },
    onClick = undefined,
  } = props
  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="local_shipping" />
      </div>

      <div className="column p-2">
        Pick-Up Number
        <div className="row font-bold">{`  ${shipment_id?.split('-')[0]}`}</div>
        <div className="row">
          {status && (
            <span className="row">
              <Icon name="location_on" size={14} color="dodgerblue" />
              <p className="ml-1 mr-2">Status : {status}</p>
            </span>
          )}
          {carrier && (
            <span className="row">
              <Icon name="business" size={14} color="#148f77" />
              <p className="ml-1 mr-2"> Carrier : {carrier.tenant_name}</p>
            </span>
          )}
          {shipper && (
            <span className="row">
              <Icon name="business" size={14} color="#148f77" />
              <p className="ml-1 mr-2"> Reciever : {shipper.tenant_name}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Location = props => {
  const {
    location_info: {
      location_name,
      location_type,
      state,
      postal_code,
      country,
      phone_no,
    },
    onClick,
  } = props
  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="location_on" />
      </div>

      <div className="column p-2">
        <div className="row font-bold">{`${location_name}`}</div>
        <div className="row">
          {location_type && (
            <span className="row">
              <Icon name="business" size={14} color="dodgerblue" />
              <p className="ml-1 mr-2">{location_type}</p>
            </span>
          )}
          {state && (
            <span className="row">
              <Icon name="language" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{state?.name}</p>
            </span>
          )}
          {country && (
            <span className="row">
              <Icon name="language" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{country?.name}</p>
            </span>
          )}
          {postal_code && (
            <span className="row">
              <Icon name="edit_location" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{postal_code}</p>
            </span>
          )}
          {phone_no && (
            <span className="row">
              <Icon name="phone" size={14} color="#148f77" />
              <p className="ml-1 mr-2">{phone_no}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Orders = props => {
  const {
    order_info: { bolNo, receiver, shipper },
    onClick = undefined,
  } = props
  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="view_quilt" />
      </div>

      <div className="column p-2">
        <div className="row font-bold">{`BOL No. ${bolNo}`}</div>
        <div className="row">
          {receiver && (
            <span className="row">
              <p className="ml-1 mr-2">Receiver : {receiver?.tenant_name}</p>
            </span>
          )}
          {shipper && (
            <span className="row">
              <p className="ml-1 mr-2">Shipper : {shipper?.tenant_name}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Products = props => {
  const {
    data: { name, description },
    onClick = undefined,
  } = props

  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="column">
        <Icon name="science" />
      </div>

      <div className="column p-2">
        <div className="row font-bold">{`Product Name: ${name}`}</div>
        <div className="row">
          {description && (
            <span className="row">
              <p className="ml-1 mr-2">Description : {description}</p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const CONSTANTS = {
  Users: {
    Base: Users,
    Component: Users,
    Api: userAPI.list,
  },
  Shipments: {
    Base: ShipmentListCard,
    Component: Shipment,
    Api: routeAPI.list,
  },
  Orders: {
    Base: Orders,
    Component: Orders,
    Api: bolAPI.list,
  },
  Products: {
    Base: Products,
    Component: Products,
    Api: productAPI.list,
  },
}

export { CONSTANTS }
