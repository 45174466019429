
import React, { useState, useMemo } from 'react'
import { Button, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@mui/material'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import CompanyCard from '../CompanyCard'
import Container from '../../Reusable/Container'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import LoadingIndicator from '../../Order/Tabs/Loading'
import localTenantAPI from '../../../api/tenant'
import withPaginationLocal from '../../../HOC/withPaginationLocal'
import InviteModal from '../invitations/InviteModal'

const dateRangeFilters = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]

export default function CompanyInvitation() {
  const [currentFilter, setCurrentFilter] = useState(true)
  const [openSnackInvite, setOpenSnackInvite] = React.useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = isInvite => () => {
    setIsOpen(!isOpen)
  }
  
  const handleCloseInvite = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackInvite(false)
  }


  const PaginatedTenants = useMemo(
    () =>
      withPaginationLocal(localTenantAPI.tenants, {
        limit: 12,
        isActive: currentFilter,
      })(({ apiResult }) => {
        return (
          <Container>
            <Scrollable p={3} className="shipments-list-new">
              {apiResult.isPending ? (
                <LoadingIndicator m={38} />
              ) : (
                apiResult?.data?.results?.map((item, index) => {
                  return <CompanyCard key={index} company={item} />
                })
              )}
            </Scrollable>
          </Container>
        )
      }),
    [currentFilter]
  )

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle title="Tenants" />
          <Box className="flex-row">
            <Button
              style={{ marginRight: '15px' }}
              variant="contained"
              color="primary"
              onClick={() => setIsOpen(true)}
            >
              Add Tenant
            </Button>
            <SelectInput
              noLabel
              onChange={setCurrentFilter}
              emptyLabel=""
              value={currentFilter}
              options={dateRangeFilters}
              style={{ marginRight: '15px' }}
            />
          </Box>
        </PageHeading>
      </Fixed>
      <Scrollable mt={2} style={{ height: '100%' }}>
        <PaginatedTenants />
      </Scrollable>
      <Box className="flex-row">
        <Snackbar
          open={openSnackInvite}
          autoHideDuration={4000}
          onClose={handleCloseInvite}
          style={{ marginRight: '50px', marginBottom: '20px' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="success">Invitation sent</Alert>
        </Snackbar>
      </Box>
      <InviteModal
        isOpen={isOpen}
        toggleModal={toggleModal} />
    </Container>
  )
}
