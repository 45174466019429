import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableCell,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { fields } from '../../helpers/fields'
import { getFileSize } from './FileRead/utils'

function ShipmentFileMetaData({ columns = [], file, metaData, onError }) {
  const [columnStatus, setColumnStatus] = useState(null)

  const shipmentFields = fields.shipment.reduce((acc, e) => {
    if (e.optional) {
      acc[e.value.toLowerCase()] = 'o'
    } else {
      acc[e.value.toLowerCase()] = true
    }
    return acc
  }, {})
  const fileColumns = columns.reduce((acc, e) => {
    acc[e.toLowerCase()] = true
    return acc
  }, {})

  useEffect(() => {
    const statuses = {}
    Object.keys(shipmentFields).forEach(field => {
      const fieldStatus = { present: true }
      if (!(field in fileColumns)) {
        fieldStatus.present = false
      }
      if (shipmentFields[field] === 'o') {
        fieldStatus.optional = true
      }
      statuses[field] = fieldStatus
    })

    setColumnStatus(statuses)
    if (Object.entries(statuses).some(e => !e[1].present && !e[1].optional)) {
      onError()
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        minWidth: '50vw',
        overflow: 'hidden',
      }}
    >
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {metaData?.rows ? (
            <>
              <Box paddingX={2}>
                <Typography variant="caption">File size</Typography>
                <Typography>{getFileSize(file)}</Typography>
              </Box>
              <Box paddingX={2}>
                <Typography variant="caption">Total rows</Typography>
                <Typography>{metaData?.rows}</Typography>
              </Box>
              <Box paddingX={2}>
                <Typography variant="caption">Reduced file</Typography>
                <Typography>
                  <Link download="file.csv" href={URL.createObjectURL(file)}>
                    Preview
                  </Link>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography>
                Please upload the file with all the required columns.
              </Typography>
            </>
          )}
        </div>
        <Typography style={{ marginTop: 10 }} variant="h5">
          Columns in the file
        </Typography>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)' }}>
          {Object.entries(columnStatus || {}).map(column => {
            let backgroundColor = !column[1].present && '#ff000010'
            if (backgroundColor && column[1].optional) {
              backgroundColor = '#f8ef9e10'
            }
            return (
              <div
                key={column[0]}
                style={{
                  border: '1px solid grey',
                  borderCollapse: 'collapse',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor,
                  padding: 10,
                }}
              >
                <Box paddingRight={2}>
                  {column[1].present ? (
                    <CheckCircleIcon
                      fontSize="30px"
                      style={{ color: 'green' }}
                    />
                  ) : (
                    <CancelIcon fontSize="30px" style={{ color: 'red' }} />
                  )}
                </Box>
                <Box flex="1">
                  <Typography style={{}}>{column[0].toUpperCase()}</Typography>
                  <Typography variant="caption">
                    <em>{column[1].optional ? 'optional' : 'mandatory'}</em>
                  </Typography>
                </Box>
              </div>
            )
          })}
        </div>
        <List style={{ paddingTop: 10 }}>
          {Object.entries(columnStatus || {})
            .filter(e => !e[1].present && !e[1].optional)
            .map(required => {
              return (
                <ListItem key={required[0]}>
                  <FiberManualRecordIcon
                    style={{ fontSize: 10, marginRight: 10 }}
                  />
                  <ListItemText
                    primary={`${required[0].toUpperCase()} is missing from the sheet. Please add ${required[0].toUpperCase()} column in the sheet.`}
                  />
                </ListItem>
              )
            })}
        </List>
      </div>
    </div>
  )
}

export default ShipmentFileMetaData
