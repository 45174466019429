




import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Box, Card, CardContent, ListItemText } from '@material-ui/core'
import routes from '../../routes/route_contants'
import { statuses } from './utils'

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <ListItemText
      primary={label}
      secondary={value}
      primaryTypographyProps={{
        style: {
          fontSize: 12,
          color: '#A3A3A3',
          textAlign: right ? 'right' : '',
        },
        ...rest.primaryTypographyProps,
      }}
      secondaryTypographyProps={{
        style: {
          fontFamily: 'Lato',
          fontSize: 13,
          color: '#1A1A1A',
          fontWeight: '500',
          // lineHeight: 16,
          textAlign: right ? 'right' : '',
        },
        ...rest.secondaryTypographyProps,
      }}
      {...rest}
    />
  )
}

function OrderCard({ order, onClick }) {
  return (
    <Card onClick={onClick} className="mr-2 shipment-list-order-item">
      <CardContent>
        <Box className="shipment-order-status" autoCapitalize>
          {statuses[order?.status?.toLowerCase()] || 'Pre Shipment'}
        </Box>
        <Box mt={1}>
          <Item
            label=""
            value={order?.receiver?.tenant_name}
          />
        </Box>
        <Box mt={1}>
          <Item
            label="City & State"
            secondaryTypographyProps={{
              className: 'partner-name',
              title:
                `${order?.dropLocation?.city 
                }, ${ 
                order?.dropLocation?.state?.name}`,
            }}
            value={
              `${order?.dropLocation?.city 
              }, ${ 
              order?.dropLocation?.state?.name}`
            }
          />
        </Box>
        <Box mt={1}>
          <Item label="BOL Number" value={order.bolNo} />
        </Box>
      </CardContent>
    </Card>
  )
}
function ShipmentListCard({ shipment, onClick }) {
  const history = useHistory()
  const location = useLocation()

  const handleOrderClick = order => {
    return e => {
      e.stopPropagation()
      history.push(`${routes.ORDER_DETAILS}/${order.id}`, {
        from: location.pathname,
      })
    }
  }

  return (
    <div className="shipment-list-card" onClick={onClick}>
      <CardContent>
        <Box className="shipment-card-row mt-3">
          <Item
            label="Shipment Date"
            value={moment(shipment?.estimatedShipment)
              .format('llll')
              .slice(0, shipment?.estimatedShipment?.length - 3)}
          />
          <Item
            right
            label="Status"
            value={shipment?.status}
            style={{ color: '#003366' }}
          />
        </Box>
        <Box className="shipment-card-row mt-3">
          <Item label="Pickup No." value={shipment?.pickupNumber} />
          <Item
            right
            label="Driver"
            value={`${shipment?.driver.first_name} ${shipment?.driver.last_name}`}
          />
        </Box>
        <Box className="shipment-card-row mt-3">
          <Item label="From" value={shipment?.shipper.tenant_name} />
          <Item right label="Carrier" value={shipment?.carrier.tenant_name} />
        </Box>
        <Box mt={2} className="shipment-list-orders" display="flex">
          {shipment?.bols?.map(order => (
            <OrderCard
              key={order.id}
              onClick={handleOrderClick(order)}
              order={order}
            />
          ))}
        </Box>
      </CardContent>
    </div>
  )
}

ShipmentListCard.propTypes = {
  shipment: PropTypes.object,
  onClick: PropTypes.func,
}

ShipmentListCard.defaultProps = {
  shipment: {},
  onClick: () => null,
}

export default ShipmentListCard
