/**
 *
 * @param {string|Blob} url url or blob
 * @param {string} fileName
 */
export const downloadFile = (url, fileName) => {
  const a = document.createElement('a')
  a.download = fileName
  a.href = url
  a.click()
  a.remove()
}

const downloadQR = (svgElement, [height, width], filename = 'QR_CODE.png') => {
  const canvas = document.createElement('canvas')
  const image = new Image()
  const outerHTML = new XMLSerializer().serializeToString(svgElement)
  const blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' })

  const URL = window.URL || window.webkitURL || window
  const blobURL = URL.createObjectURL(blob)

  image.onload = () => {
    const context = canvas.getContext('2d')
    context.canvas.height = height
    context.canvas.width = width
    context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height)
    const d = canvas.toDataURL()
    downloadFile(d, filename)
  }

  image.src = blobURL
}

export default downloadQR
