import React from 'react'
import { useHistory } from 'react-router-dom'
import Container from '../Reusable/Container'
import routes from '../../routes/route_contants'
import ShipmentListCard from './ListCard'
import Scrollable from '../Reusable/Scrollable'
import LoadingIndicator from '../Order/Tabs/Loading'

const ShipmentListDisplay = ({ type, apiResult }) => {
  const history = useHistory()
  const handleClick = shipment => {
    return () => history.push(`${routes.SHIPMENT_DETAILS}/${shipment.id}`)
  }
  return (
    <Container>
      <Scrollable p={3} className="shipments-list-new">
        {apiResult.isPending ? (
          <LoadingIndicator m={4} />
        ) : (
          apiResult?.data?.results?.map(shipment => {
            return (
              <ShipmentListCard
                key={shipment.id}
                shipment={shipment}
                onClick={handleClick(shipment)}
              />
            )
          })
        )}
      </Scrollable>
    </Container>
  )
}

export default ShipmentListDisplay
