import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../config/colors'

function Icon({ name, size, color, style, ...rest }) {
  return (
    <div
      style={{
        color,
        fontSize: size,
        ...style,
      }}
      className={`material-icons ${rest.className || ''}`}
      {...rest}
    >
      {name}
    </div>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
}

Icon.defaultProps = {
  size: 20,
  color: colors.dark,
  style: {},
}

export default Icon
