const SCHEME = 'exp://127.0.0.1:19000/'

const Links = {
  // add app corresponding links here, use :token parameter to send on RedirectApp component
  LOGIN: `${SCHEME}--/login`,
  PERSONAL_DETAILS: `${SCHEME}--/personal-details`,
  TEAM_MEMBERS: `${SCHEME}--/team-members`,

  // use scheme t2t for standalone app only
  // ENV 'REACT_APP_LINK_SCHEME'
  // for local development app exp://127.0.0.1:19000/
  // for standalone app t2t://--/
}

export default Links
