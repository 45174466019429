const config = {
  defaultPath: '/orders',
  fontFamily: `'Lato', sans-serif`,
  borderRadius: 7,
  apiLimit: 12,
}

export const deafultFunc = () => null
export const defaultArray = []
export const defaultObject = {}
export const defaultString = ''

export default config
