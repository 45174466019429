



import { Card, Typography, CardContent } from '@material-ui/core'

import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../routes/route_contants'
import './invitationCard.scss'

function CompanyCard({ company }) {
  const [companylogo, setCompanylogo] = useState(
    `${process.env.REACT_APP_API_URL}/tenant.logo/${company.tenant_id}`
  )

  const history = useHistory()
  const location = useLocation()

  const handleNavigation = () => {
    history.push({
      pathname:
        location.pathname === '/invitations'
          ? `${routes.SUPER_INVITATION_DETAILS}/${company.invite_id}`
          : `${routes.SUPER_TENANT_DETAILS}/${company.tenant_id}`,
      state: company.tenant_id,
    })
  }

  return (
    <>
      <Card className="superadminlistbox" onClick={handleNavigation}>
        <CardContent>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <img
                className="company-logo"
                src={companylogo}
                alt={company.tenant_name}
                onError={() =>
                  setCompanylogo(
                    'http://cdn.onlinewebfonts.com/svg/img_242130.png'
                  )
                }
              />
              <Typography variant="h6">{company.tenant_name}</Typography>
            </div>
            <div className="superadminactiveinactive">
              {location.pathname === '/invitation'
                ? company?.status.charAt(0).toUpperCase() +
                  company?.status.slice(1)
                : company.is_active
                ? 'Active'
                : 'Inactive'}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default CompanyCard
