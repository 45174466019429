


import React from 'react'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import { PaletteColors } from '../../../../../config/colors'
import TableHead from '../../../../Reusable/TableHead'
import Scrollable from '../../../../Reusable/Scrollable'
import TableBody from '../../../../Reusable/TableBody'
import Container from '../../../../Reusable/Container'
import Loading from '../../../../Loading'
import { formatContactNo } from '../../../../../helpers/util'
import routes from '../../../../../routes/route_contants'

function BuyerTenantList({ type, apiResult }) {
  const history = useHistory()

  const handleRowClick = row => {
    history.push(`${routes.CONNECTION_TENANT_PAGE}/${row.tenant_id}`, {
      data: row,
    })
  }

  const columns = [
    {
      label: 'Name',
      value: e => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                width: 50,
                height: 20,
                justifyContent: 'center',
              }}
            >
              {e?.tenant_logo && (
                <img
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt="Partner logo"
                  src={`data:image/jpeg;base64,${e?.tenant_logo}`}
                />
              )}
            </div>
            <Box style={{ color: PaletteColors.textDark, marginLeft: 10 }}>
              {e?.tenant_name}
            </Box>
          </div>
        )
      },
      minWidth: 180,
    },
    {
      label: 'Contact No.',
      value: e => formatContactNo(e?.tenant_phone),
    },
    {
      label: 'Product(s)',
      value: e => {
        return e?.products?.map(
          (item, key) =>
            item.productName + (key !== e?.products?.length - 1 ? ', ' : '')
        )
      },
      maxWidth: 300,
      flex: 2,
    },
  ]

  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data?.results}
            rowOnClick={handleRowClick}
          />
        )}
      </Scrollable>
    </Container>
  )
}

export default BuyerTenantList
