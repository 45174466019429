

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import Scrollable from '../../Reusable/Scrollable'
import useAPILocal from '../../../hooks/useAPILocal'
import checklistAPI from '../../../api/checklist'
import LoadingIndicator from '../../Order/Tabs/Loading'
import { PaletteColors } from '../../../config/colors'
import Container from '../../Reusable/Container'
import { formatContactNo } from '../../../helpers/util'
import contactsAPI from '../../../api/contacts'
import routes from '../../../routes/route_contants'
import Routes from '../../../routes'

const fields = [
  { label: 'ID', value: e => e?.id },
  {
    label: 'Name',
    value: e => `${e?.name} ${e?.surname}`,
    minWidth: 180,
  },
  {
    label: 'Job Title',
    value: e => e?.jobTitle,
  },
  {
    label: 'Decription',
    value: e => e?.description || '-',
  },
  {
    label: 'Email',
    value: e => e?.email,
    maxWidth: 300,
  },
  {
    label: 'Contact Number',
    value: e => {
      return formatContactNo(e?.phone) || '-'
    },
  },
]
const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 14,
            color: PaletteColors.textMedium,
            wordBreak: 'break-all',
          }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function ContactPage() {
  const history = useHistory()
  const path = useParams()
  const [data, setContactData] = useState()
  const [open, setOpen] = React.useState(false)
  const getSingleContact = useAPILocal(contactsAPI.getSingleContact)
  const deleteContact = useAPILocal(contactsAPI.deleteContact)

  const handleRedirect = () => {
    history.push({
      pathname: `${routes.CREATE_CONTACT}`,
      state: { id: path.contact_id, mode: 'edit' },
    })
  }

  useEffect(() => {
    if (path.contact_id)
      getSingleContact
        .request(path.contact_id)
        .then(res => setContactData(res?.data))
  }, [])

  return (
    <Container pt={3}>
      {getSingleContact.isPending ? (
        <LoadingIndicator />
      ) : (
        <>
          <Fixed px={3}>
            <PageHeading mb={2}>
              <PageTitle backButton title={`${data?.name} ${data?.surname}`} />
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    color: 'red',
                    borderColor: 'red',
                    marginRight: '10px',
                  }}
                  onClick={() => setOpen(true)}
                >
                  Delete
                </Button>
                {open && (
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this contact ?
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={() => setOpen(false)} color="primary">
                        No
                      </Button>
                      <Button
                        onClick={() => {
                          deleteContact.request(path.contact_id).then(() => {
                            history.push('/my-company')
                          })
                        }}
                        color="primary"
                      >
                        {deleteContact.isPending ? <LoadingIndicator /> : 'Yes'}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleRedirect}
                >
                  Edit Contact
                </Button>
              </div>
            </PageHeading>
          </Fixed>
          <Scrollable style={{ height: '100%' }} p={3}>
            <Box
              className="shipments-list"
              style={{
                paddingLeft: 30,
                backgroundColor: PaletteColors.backgroundPrimary,
                borderRadius: 9,
              }}
            >
              <div className="tankbox">
                <div className="tankboxin">
                  {getSingleContact.isPending ? (
                    <LoadingIndicator m={4} />
                  ) : (
                    fields.map(field => (
                      <Box key={field.label}>
                        <Item label={field.label} value={field.value(data)} />
                      </Box>
                    ))
                  )}
                </div>
              </div>
            </Box>
          </Scrollable>
        </>
      )}
    </Container>
  )
}

export default ContactPage
