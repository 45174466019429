import { IAutoComplete, ISelect, IText } from '../Tank/TankAdd/steps/utils'

const statuses = {
  pre_shipment: 'Pre Shipment',
  arrived_to_load: 'Arrived to load',
  arrived_to_unload: 'Arrived to unload',
  transit: 'Transit',
  delivered: 'Delivered',
  completed: 'Delivered',
}

const editShipment = [
  {
    key: 'Pickup and Shipment',
    visible: true,
    children: [
      new IText('pickup_number', 'Pickup Number', '', ['required']),
      new IText('shipment_date', 'Shipment Date', '', ['required']),
    ],
  },
  {
    key: 'Driver & Shipper details',
    visible: true,
    children: [
      new IText('driver', 'Driver', '', [
        'number:Please enter a valid 10 digit no.',
      ]),
      new IText('Shipper', 'Shipper', '', [
        'number:Please enter a valid 10 digit no.',
      ]),
    ],
  },
  {
    key: 'Carrier',
    visible: true,
    children: [new IText('carrier', 'Carrier', '')],
  },
]

export { statuses, editShipment }
