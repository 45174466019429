import {
  Box,
  Typography,
} from '@material-ui/core'
import React from 'react'
import Scrollable from '../Reusable/Scrollable'
import { PaletteColors } from '../../config/colors'
import Container from '../Reusable/Container'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'

const formatDate = dateStr => {
  if (!dateStr) {
    return '-'
  }
  const dateObj = new Date(dateStr)
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }
  return dateObj.toLocaleString('en-US', options)
}

const fields = [
  { label: 'Checklist ID', value: e => e?.id },
  { label: 'Name', value: e => e?.name },
  {
    label: 'Answered at',
    value: e => formatDate(e?.answeredAt),
  },
]
const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 14,
            color: PaletteColors.textMedium,
            wordBreak: 'break-all',
          }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function ChecklistAnswers({ data }) {
  return (
    <Container>
      <Scrollable style={{ height: '100%' }} p={3}>
        <Box
          className="shipments-list"
          style={{
            paddingLeft: 30,
            backgroundColor: '#F8FAFC',
            borderRadius: 9,
          }}
        >
          <div className="tankbox">
            <div className="tankboxin">
              {fields.map(field => (
                <Box key={field.label}>
                  <Item label={field.label} value={field.value(data)} />
                </Box>
              ))}
            </div>
          </div>
        </Box>
        <div
          style={{
            marginTop: 15,
            marginBottom: 5,
            padding: 15,
            backgroundColor: '#EBF4FD',
            borderRadius: 9,
            display: 'inline-block',
          }}
        >
          <div style={{ color: '#5196F7' }}>Answer</div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {data.questions.map(item => (
                <TableRow key={item.id} >
                  <div
                    style={{
                      marginTop: 10,
                      paddingInline: 10,
                      backgroundColor: '#F8FAFC',
                      borderRadius: 9,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <TableCell style={{border: 'none'}}>&#8226;&#160; {`${item.question}`}</TableCell>
                    <TableCell style={{border: 'none'}}>
                      {item?.answer ? item.answer.replace(/[\[\]"']/g, '').replace(/,/g, ', ') : '-'}
                    </TableCell>
                  </div>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollable>
    </Container>
  )
}

export default ChecklistAnswers;
