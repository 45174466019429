import client from '../../hooks/useClient'

const userAPI = {}

userAPI.changePassword = data => {
  return client.patch(
    `/users/${data.userId}/password?newPassword=${data.newPassword}`
  )
}

userAPI.list = async params => {
  return client.get(`/users`, params)
}

userAPI.info = async userId => {
  return client.get(`/users/${userId}`)
}

userAPI.updateStatus = data => {
  return client.patch(
    `/users/status?comment=${data.comment}&email=${data.email}&isActive=${data.isActive}`
  )
}

userAPI.invitationList = async params => {
  return client.get(`/users/invitations`, { ...params })
}

userAPI.inviteUser = data => {
  return client.post(`/users/invitations`, data)
}

userAPI.updateInvite = (invitationId, data) => {
  return client.put(`/users/invitations/${invitationId}`, data)
}

userAPI.userProfilePicture = async userId => {
  return client.get(`/users/${userId}/profile-picture`)
}

userAPI.updateUserDetails = (userId, data) => {
  return client.put(`/users/${userId}`, data)
}

userAPI.updateUserProfilePic = image => {
  return client.patch(`/users/picture`, image)
}

userAPI.driverRouteAssign = (userId, data) => {
  return client.patch(`/users/driver-route-assignment/${userId}`, data)
}

export default userAPI
