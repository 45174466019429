import client from '../../hooks/useClient'

const matchersAPI = {}

matchersAPI.getMatchingStrings = partnerId => {
  return client.get(`/matchers/partner/${partnerId}`)
}

matchersAPI.updateMatchingStrings = (partnerId, matchers) => {
  return client.put(`/matchers/partner/${partnerId}?matchers=${matchers}`)
}

matchersAPI.createMatchingStrings = (partnerId, matchers) => {
  return client.post(`/matchers/partner/${partnerId}?matchers=${matchers}`)
}

matchersAPI.deleteMatchingStrings = (partnerId, matchers) => {
  return client.delete(`/matchers/partner/${partnerId}?matchers=${matchers}`)
}

matchersAPI.getLocationMatchingStrings = (partnerId, locationId) => {
  return client.get(`/matchers/partner/${partnerId}/location/${locationId}`)
}

matchersAPI.updateLocationMatchingStrings = (
  partnerId,
  locationId,
  matchers
) => {
  return client.put(
    `matchers/partner/${partnerId}/location/${locationId}?matchers=${matchers}`
  )
}

matchersAPI.createLocationMatchingStrings = (
  partnerId,
  locationId,
  matchers
) => {
  return client.post(
    `matchers/partner/${partnerId}/location/${locationId}?matchers=${matchers}`
  )
}

matchersAPI.getProductMatchingStrings = (partnerId, partnerProductId) => {
  return client.get(
    `/matchers/partner/${partnerId}/product/${partnerProductId}`
  )
}

matchersAPI.updateProductMatchingStrings = (
  partnerId,
  partnerProductId,
  matchers,
  myProductId
) => {
  return client.put(
    `matchers/partner/${partnerId}/product/${partnerProductId}?matchers=${matchers}&myProductId=${myProductId}`
  )
}

matchersAPI.createProductMatchingStrings = (
  partnerId,
  partnerProductId,
  matchers
) => {
  return client.post(
    `matchers/partner/${partnerId}/product/${partnerProductId}?matchers=${matchers}`
  )
}

export default matchersAPI
