import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useAPILocal from '../../hooks/useAPILocal'
import bolAPI from '../../api/bol'

import Container from '../Reusable/Container'
import Scrollable from '../Reusable/Scrollable'
import TableHead from '../Reusable/TableHead'
import Loading from '../Loading'
import TableBody from '../Reusable/TableBody'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Box, Typography } from "@material-ui/core";
import ChecklistAnswers from "../CheckList/ChecklistAnswers";

const columns = [
  {
    label: 'Name',
    value: e => e?.name,
    minWidth: 300,
  },
  {
    label: 'States',
    value: e => e?.states?.map(item => item.replaceAll('_', ' '))?.join(', '),
    minWidth: 180,
  },
  {
    label: 'Assigned To',
    value: e => e?.roles?.join(', '),
    minWidth: 180,
  },
]

const Checklist = ({ type, bolId, setShowAnswers, showAnswers }) => {
  const checklists = useAPILocal(bolAPI.bolChecklist)
  const [checklistData, setChecklistData] = useState(null)

  useEffect(() => {
    if (bolId) checklists.request(bolId)
  }, [])

  const handleRowClick = row => {
    setShowAnswers(row.id)
    setChecklistData(row)
  }

  return showAnswers === null ? (
    <div>
      <div style={{ marginVertical: 20 }}>
        <p
          style={{
            textTransform: 'capitalize',
            margin: '10px 0px 10px 10px',
            color: '#123262',
            fontSize: '18px',
          }}
        >
          {type}
        </p>

        <Container>
          <Scrollable>
            <TableHead columns={columns} />
            {checklists.isPending ? (
              <Loading m={4} />
            ) : (
              <TableBody
                columns={columns}
                data={checklists.data}
                rowOnClick={handleRowClick}
              />
            )}
          </Scrollable>
        </Container>
      </div>
    </div>
  ) : (
    <div>
      <Box display="flex" alignItems="center">
        <Box
          onClick={() => setShowAnswers(null)}
          className="button-base"
          display="flex"
          color="primary"
          mr={2}
        >
          <ArrowBackIcon />
        </Box>
        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: '700' }}
        >
          Checklist
        </Typography>
      </Box>
      <ChecklistAnswers data={checklistData} />
    </div>
  )
}

Checklist.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  bolId: PropTypes.string.isRequired,
}

Checklist.defaultProps = {
  data: {},
}

export default Checklist
