import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableRow,
  TableCell,
  Typography,
  Box,
  Link,
} from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import useStateValue from '../../hooks/useStateValue'
import Icon from '../Icon'

import {
  checkHeaders,
  get_header_row,
  get_sheet_data,
  json_to_sheet,
  parseExcel,
  remove_unused_fields,
  sheet_to_csv,
} from './parseExcel'

import { getFileSize } from './FileRead/utils'

import SelectSheet from './FileRead/SelectSheet'
import ShipmentFileMetaData from './ShipmentFileMetaData'
import { fields } from '../../helpers/fields'
import { downloadFile } from '../../helpers/downloadQR'
import routeAPI from '../../api/route'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import useAPILocal from '../../hooks/useAPILocal'
import { Alert, Snackbar } from '@mui/material'

function FileUploading() {
  return (
    <div>
      <div className="loader-animated"></div>
      <p>Uploading</p>
    </div>
  )
}

function ShipmentAdd() {
  const history = useHistory()
  const [, dispatch] = useStateValue()
  const [viewFileUpload, setViewFileUpload] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)
  const [fileColumns, setFileColumns] = useState(null)
  const [fileUploadStatus, setFileUploadStatus] = useState(
    'Preparing file please wait...'
  )
  const [fileMetaData, setFileMetaData] = useState({})
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const [workbook, setWorkbook] = useState({})
  const [errorMsg, setErrorMsg] = useState()
  const [draggingStatus, setDraggingStatus] = useState('undragged')
  const fileUploadRef = useRef()
  const bulkShipmentUpload = useAPILocal(routeAPI.bulkShipmentUpload)

  const handleClose = () => {
    setViewFileUpload(false)
    setUploadDisabled(false)
  }

  const [fileExtension, setFileExtension] = useState('xlsx')
  const [response, setResponse] = useState({})
  const mimes = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    csv: 'text/csv',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  }

  async function getHeaderAndData(sheet) {
    setFileUploadStatus('Fetching column names...')
    const header = await get_header_row(sheet)
    const headersMatched = await checkHeaders(header)
    if (!headersMatched) {
      setFileColumns(header)
      setFileMetaData(prev => ({
        ...prev,
        rows: 0,
        columns: header.length,
      }))
      return { header, data: [] }
    }
    setFileUploadStatus('Fetching rows...')
    const arr = await get_sheet_data(sheet)
    setFileUploadStatus('Removing unnecessary fields...')
    const newArr = await remove_unused_fields(arr)
    // setFileUploadStatus(sheet, arr)
    setFileUploadStatus('Generating csv...')
    const sheetJ = await json_to_sheet(newArr)
    // setFileUploadStatus('NEW SHEET', sheetJ)
    const csv = await sheet_to_csv(sheetJ)
    setFileUploadStatus('Generating file...')
    const newFile = new File([csv], 'file.csv', { type: 'text/csv' })
    setCurrentFile(newFile)
    setFileColumns(header)
    setFileExtension('csv')
    setFileMetaData(prev => ({
      ...prev,
      rows: arr.length,
      columns: header.length,
    }))
    return { header, data: arr }
  }

  const handleFileChange = file => {
    if (!file) return
    const ext = file.name.match(/[^\\]*\.(\w+)$/)[1]
    parseExcel(file).then(async d => {
      if (ext === 'csv') {
        setFileMetaData({ ...d, Worksheets: 1 })
        setWorkbook(d)
        const { header, data } = await getHeaderAndData(
          d.Sheets[d.SheetNames[0]]
        )
      } else if (d.SheetNames.length === 1) {
        parseExcel(file, 0).then(async wb => {
          setWorkbook(wb)
          const { header, data } = await getHeaderAndData(
            wb.Sheets[wb.SheetNames[0]]
          )
        })
      } else {
        setFileMetaData(d)
      }
    })
    setFileExtension(ext)
    if (!(ext in mimes)) {
      alert('Upload a file with acceptable formats (.xlsx,.xls, .pdf, .csv)')
      setDraggingStatus('undragged')
      return
    }
    setDraggingStatus('dragged')
    setCurrentFile(file)
  }

  const downloadSampleFile = () => {
    const headers = fields.shipment.map(f => f.value)
    const sample = new File([headers.join(',')], { type: 'text/csv' })
    const url = URL.createObjectURL(sample)
    downloadFile(url, 'sample_shipments.csv')
    setTimeout(() => {
      URL.revokeObjectURL(url)
    }, 2000)
  }

  const uploadFile = async () => {
    setDraggingStatus('done:uploading')
    try {
      bulkShipmentUpload.request(currentFile).then(res => {
        if (res.data?.errorMessages.length !== 0) {
          setDraggingStatus('done:error')
          setErrorMsg(res.data?.errorMessages)
        } else {
          setDraggingStatus('done:created')
          setResponse({
            shipment_count: res.length,
            order_count: res.data?.routes?.length,
          })
        }
      })
    } catch (error) {
      setDraggingStatus('done:error')
    }
  }

  useEffect(() => {
    setViewFileUpload(!!currentFile)
  }, [currentFile])

  if (draggingStatus === 'done:created')
    setTimeout(() => {
      history.goBack()
    }, 5000)

  return (
    <>
      <Container pt={3} px={3}>
        <Fixed>
          <PageHeading>
            <PageTitle title="Add Shipment" backButton />
          </PageHeading>
        </Fixed>

        <div>
          {currentFile && (
            <>
              <div
                className="py-2"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ padding: 10 }}>File: {currentFile.name}</div>
                <div>{getFileSize(currentFile)}</div>
                <label>
                  <Button
                    onClick={() => {
                      setViewFileUpload(true)
                    }}
                    color="primary"
                    variant="contained"
                    size="small"
                    autoFocus
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrentFile(null)
                      setFileMetaData(null)
                      setUploadDisabled(false)
                      setFileColumns(null)
                      setDraggingStatus('undragged')
                    }}
                    color="primary"
                    size="small"
                  >
                    Cancel
                  </Button>
                </label>
              </div>
            </>
          )}
          <div
            className={draggingStatus}
            onDragOver={e => e.preventDefault()}
            onDragEnterCapture={() => setDraggingStatus('dragging')}
            onDragLeave={() => setDraggingStatus('undragged')}
            onDrop={e => {
              handleFileChange(e.dataTransfer.files[0])
              e.preventDefault()
            }}
            style={{
              padding: '100px 0',
              border: '0.3px solid grey',
              display: 'flex',
              borderRadius: '7px',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            {draggingStatus.startsWith('done') ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {draggingStatus === 'done:uploading' && <FileUploading />}
                <Snackbar
                  open={draggingStatus === 'done:uploaded'}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Alert severity="success">File Uploaded</Alert>
                </Snackbar>

                {draggingStatus === 'done:uploaded' && (
                  <>
                    <p className="text-secondary-small">
                      File uploaded successfully, creating shipments...
                    </p>
                  </>
                )}

                {draggingStatus === 'done:created' && (
                  <>
                    <p>Number of orders created: {response?.order_count}</p>
                    <br />
                    <p className="text-secondary-small">
                      You'll be redirected to the shipment list page
                    </p>
                  </>
                )}
                <Snackbar
                  open={draggingStatus === 'done:created'}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Alert severity="success">Shipments created</Alert>
                </Snackbar>
                {draggingStatus === 'done:error' && (
                  <>
                    {errorMsg?.map(item => (
                      <p className="text-secondary-small" key={item}>
                        {item}
                      </p>
                    ))}
                    {/* <p className="text-secondary-small">
                      Make sure you're uploading the correct data. Check all the
                      fields properly. Check if the BOL Id is unique
                    </p> */}
                    <Button
                      color="primary"
                      onClick={() => {
                        setDraggingStatus('undragged')
                        setCurrentFile(null)
                      }}
                    >
                      Try again
                    </Button>
                  </>
                )}
                <Snackbar
                  open={draggingStatus === 'done:error'}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Alert severity="error">
                    There was an error creating shipments
                  </Alert>
                </Snackbar>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Icon name="cloud_upload" size={56} />
                <p className="text-secondary-small py-2">
                  Drag and drop a file to upload or click on browse
                </p>
                <input
                  type="file"
                  id="fileUpload"
                  style={{ display: 'none' }}
                  ref={fileUploadRef}
                  accept={`${mimes.xlsx}, ${mimes.xls}, .csv, ${mimes.xlsm}`}
                  onChange={e => {
                    handleFileChange(e.target.files[0])
                    fileUploadRef.current.value = ''
                  }}
                />
                <label>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      fileUploadRef.current.click()
                    }}
                  >
                    Browse file
                  </Button>
                </label>
              </div>
            )}
          </div>
          <div style={{ marginTop: '10px' }}>
            <Link style={{ cursor: 'pointer' }} onClick={downloadSampleFile}>
              Download sample file
            </Link>
          </div>
          <div>
            <Typography variant="h6" style={{ marginTop: '10px' }}>
              Required columns in the sheet
            </Typography>
            <div style={{ color: 'red' }}>*Mandatory</div>
            <div
              style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)' }}
            >
              {fields.shipment.map(e => (
                <div key={e} style={{ padding: '10px 10px 10px 0px' }}>
                  <Typography style={{ display: 'flex', flexDirection: 'row' }}>
                    {e.value}
                    <div style={{ color: 'red' }}>{!e.optional && '*'}</div>
                  </Typography>
                  <Typography variant="caption">{e.key}</Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Dialog
          open={viewFileUpload}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={fileColumns ? 'md' : 'sm'}
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">File details</DialogTitle>
          <DialogContent>
            {fileMetaData?.SheetNames?.length > 1 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="caption">
                  Found multiple worksheets in this file, please select 1 to
                  proceed
                </Typography>
                <SelectSheet
                  onSelect={data => {
                    setFileMetaData(prev => ({ ...prev, ...data }))

                    setTimeout(() => {
                      setFileUploadStatus(
                        'File is big and is taking some extra time...'
                      )
                    }, 5000)
                    parseExcel(currentFile, data.selectedSheet).then(
                      async wb => {
                        setWorkbook({ ...wb, ...data })
                        const { header, data: sheetData } =
                          await getHeaderAndData(wb.Sheets[data.SheetNames[0]])
                      }
                    )
                  }}
                  worksheets={fileMetaData.SheetNames}
                />
              </div>
            ) : (
              <div>
                {fileColumns ? (
                  <ShipmentFileMetaData
                    columns={fileColumns}
                    file={currentFile}
                    metaData={fileMetaData}
                    onError={() => setUploadDisabled(true)}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={15} style={{ margin: 20 }} />
                    <Typography>{fileUploadStatus}</Typography>
                  </Box>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={!fileColumns || uploadDisabled}
              onClick={() => {
                handleClose()
                uploadFile()
              }}
              color="primary"
              autoFocus
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}

export default ShipmentAdd
