import { Box, Button, Snackbar, Checkbox } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../Modal'
import useForm from '../../../hooks/useForm'
import Loading from '../../Loading'
import TextInput from '../../Reusable/Widgets/TextInput'
import useAPILocal from '../../../hooks/useAPILocal'
import tenantAPI from '../../../api/tenant'
import CheckBoxInput from '../../Reusable/Widgets/CheckBoxInput'

const init = {
  email: '',
  companyName: '',
  companyEin: '',
}

const validationSchema = {
  email: [
    'required:Email is required',
    'email:Email field is not a valid email address',
  ],
  companyName: ['required:Company name is required'],
  companyEin: ['required:Company EIN is required', 'eq:9', 'number:EIN should be a number'],
}

const Invite = props => {
  const { isOpen, toggleModal } = props
  const inviteApi = useAPILocal(tenantAPI.inviteTenant)
  const [openSnackInviteError, setOpenSnackInviteError] = React.useState(false)
  const [openSnackInviteSent, setOpenSnackInviteSent] = React.useState(false)

  const apiRequest = async (
    companyName,
    companyEin,
    email,
    seller,
    carrier,
    buyer
  ) => {
    const result = await inviteApi.request({
      adminEmail: email.toLowerCase(),
      tenantName: companyName,
      tenantEin: companyEin,
      seller: seller || false,
      carrier: carrier || false,
      buyer: buyer || false,
      active: true,
    })
    if (!result.ok) {
      setOpenSnackInviteError(true)
      return null
    }
    resetForm()
    if (!result.isRejected) {
      setOpenSnackInviteSent(true)
      toggleModal('invited')()
    }
  }

  const { errors, handleOnChange, resetForm, values, handleSubmit } = useForm(
    init,
    validationSchema,
    result => {
      if (
        result?.companyName &&
        result?.companyEin &&
        result?.email &&
        errors?.email !== '' &&
        !errors?.companyName !== ''
      ) {
        apiRequest(
          result?.companyName,
          result?.companyEin,
          result?.email,
          result?.seller,
          result?.carrier,
          result?.buyer
        )
      }
    }
  )

  const handleCloseInvite = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackInviteSent(false)
    setOpenSnackInviteError(false)
  }

  return (
    <>
      <Modal visible={isOpen} onClose={toggleModal(false)} title="Add Tenant">
        <TextInput
          placeholder="Enter Company Name"
          label="Company Name"
          onChange={value => {
            return handleOnChange({
              name: 'companyName',
              value,
            })
          }}
          value={values.companyName}
          error={errors.companyName}
        />
        <TextInput
          placeholder="Enter Company EIN"
          label="Company EIN"
          onChange={value => {
            return handleOnChange({
              name: 'companyEin',
              value,
            })
          }}
          value={values.companyEin}
          error={errors.companyEin}
        />
        <TextInput
          placeholder="Enter Email"
          label="Email"
          onChange={value => {
            return handleOnChange({ name: 'email', value })
          }}
          value={values.email}
          error={errors.email}
        />
        <CheckBoxInput
          label="Seller"
          value={values.seller}
          onChange={value => {
            return handleOnChange({ name: 'seller', value })
          }}
          checklist
        />
        <CheckBoxInput
          label="Carrier"
          value={values.carrier}
          onChange={value => {
            return handleOnChange({ name: 'carrier', value })
          }}
          other
          checklist
        />
        <CheckBoxInput
          label="Buyer"
          value={values.buyer}
          onChange={value => {
            return handleOnChange({ name: 'buyer', value })
          }}
          other
          checklist
        />

        {/* <div className="text-footer-modal">
        Company name entered here will be replaced by the registered name
        entered here by the company admin.
      </div> */}
        {inviteApi.isPending && <Loading />}
        {!inviteApi.isPending && (
          <Button
            style={{ marginTop: '20px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Modal>
      <Snackbar
        open={openSnackInviteError}
        autoHideDuration={6000}
        onClose={handleCloseInvite}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {Object.values(
            inviteApi?.data?.clientMessage || inviteApi?.data?.message || {}
          ).join('')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackInviteSent}
        autoHideDuration={6000}
        onClose={handleCloseInvite}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">Invitation Sent Successfully</Alert>
      </Snackbar>
    </>
  )
}

Invite.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default Invite
