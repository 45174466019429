import { fieldVisible, IAutoComplete, ISelect, IText } from './utils'
import chemicalAPI from '../../../api/chemical'

const step1 = [
  {
    key: 'Chemical Name',
    visible: fieldVisible,
    children: [new IText('name', 'Chemical Name', '', ['required'])],
  },
  {
    key: 'Chemical UN Number',
    visible: fieldVisible,
    children: [new IText('unNumber', 'Chemical UN Number', '', ['required'])],
  },
  {
    key: 'Hazard Class',
    visible: fieldVisible,
    children: [new IText('hazardClass', 'Chemical Hazard Class', '')],
  },
]

export default step1
