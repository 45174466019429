import client from '../../hooks/useClient'

const tenantAPI = {}

tenantAPI.inviteTenant = async data => {
  return client.post('/tenants?active=true', data)
}

tenantAPI.resendInvite = async data => {
  return client.post(
    `/tenants/invitations/${data.tenantInvitationId}/reinvite`,
    data.tenantInvitationId
  )
}

tenantAPI.updateInvite = async (data, tenantInvitationId) => {
  return client.put(`/tenants/invitations/${tenantInvitationId}`, data)
}

tenantAPI.invitations = async data => {
  return client.get('/tenants/invitations', data)
}

tenantAPI.invitationsDetails = async data => {
  return client.get(
    `/tenants/invitations/${data.tenantInvitationId}`,
    data.tenantInvitationId
  )
}

tenantAPI.tenants = async data => {
  return client.get('/tenants', data)
}

tenantAPI.tenantDetails = async data => {
  return client.get(`/tenants/${data.tenantId}`)
}

tenantAPI.deleteTenant = async tenantId => {
  return client.delete(`/tenants/${tenantId}`)
}

tenantAPI.tenantInfoUpdate = async (tenantId, data) => {
  return client.put(`/tenants/${tenantId}`, data)
}

tenantAPI.uploadTenantLogo = async data => {
  return client.patch(`/tenants/${data.tenantId}/logo`, data.image)
}

tenantAPI.tenantLocation = async (tenantId, data) => {
  return client.patch(`/tenants/${tenantId}/primary-location`, data)
}

tenantAPI.getTenantLogo = async data => {
  return client.get(
    `/tenants/${data.tenantId}/logo`,
    {},
    {
      data: {},
      headers: { accept: 'image/jpeg', responseType: 'arraybuffer' },
    }
  )
}

tenantAPI.updateTenantStatus = (data, tenantId) => {
  return client.patch(
    `/tenants/${tenantId}/status?activeStatus=${data?.activeStatus}&comment=${data?.comment}`
  )
}

tenantAPI.publicBuyers = data => {
  return client.get(`/tenants/public-buyers?tenantName=${data.tenantName}`)
}

tenantAPI.publicSellers = data => {
  return client.get(`/tenants/public-sellers?tenantName=${data.tenantName}`)
}

export default tenantAPI
