import React from 'react'

import Loading from '../../../../Loading'

import Fixed from '../../../../Reusable/Fixed'
import Scrollable from '../../../../Reusable/Scrollable'
import TableBody from '../../../../Reusable/TableBody'
import Container from '../../../../Reusable/Container'
import TableHead from '../../../../Reusable/TableHead'

const columns = [
  {
    label: 'ID',
    value: e => e?.partner?.tenant_id.slice(0, 5),
  },
  {
    label: 'Tenant Name',
    value: e => e?.partner?.tenant_name,
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'City',
    value: e => e?.partner?.primary_location?.city,
  },
]

const TenantModalList = ({ apiResult, selectable, onSelectRows }) => {
  const [selectedAll, setSelectedAll] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState([])

  React.useEffect(() => {
    setSelectedRows(prev => prev.map(e => selectedAll))
  }, [selectedAll])

  React.useEffect(() => {
    if (apiResult.isResolved)
      setSelectedRows(apiResult?.data?.results?.map(e => false))
  }, [apiResult.isResolved])

  return (
    <Container>
      <Fixed>
        <TableHead columns={columns} selectable onSelected={setSelectedAll} />
      </Fixed>
      <Scrollable>
        {apiResult.isPending && <Loading m={4} />}
        <TableBody
          // rowOnClick={handleRowClick}
          selectable
          onSelectRows={onSelectRows}
          selectedRows={selectedRows}
          columns={columns}
          data={apiResult.data?.results}
        />
      </Scrollable>
    </Container>
  )
}

export default TenantModalList
