
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { Box } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import NoImage from '../../assets/noImage.jpeg'
import ROUTES from '../../routes/route_contants'
import useAPILocal from '../../hooks/useAPILocal'
import fileAPI from '../../api/file'
import Loading from '../Loading'

const useStyles = makeStyles(theme => ({
  root: {
    width: 345,
    background: '#F1F5F9',
    borderRadius: '10px',
    border: '0px solid #F1F5F9',
    '& .MuiCardHeader-title': {
      fontSize: '15px',
      lineHeight: '18px',
      fontFamily: 'Lato',
      fontWeight: 700,
      color: '#1A1A1A',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: 'Lato',
      fontWeight: 500,
      color: '#60B077',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageSingle: {
    width: '75px',
    height: '75px',
    borderRadius: '8px',
    paddingLeft: '6px',
  },
  comment: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#1A1A1A',
    marginTop: '10px',
  },
  content: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#1A1A1A',
    marginTop: '10px',
  },
  date: {
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#A3A3A3',
    marginTop: '50px',
  },
}))


export default function OrderImageCard({
  profilePic,
  comment,
  title,
  userName,
  date,
  role,
  commentId,
  pictureIndex,
  images,
}) {
  const classes = useStyles()
  const history = useHistory()
  const [photo, setPhoto] = useState()

  const gotoPhotoAndView = picIndex => {
    history.push(`${ROUTES.PHOTOS_COMMENTS_VIEW}/${commentId}`)
  }

  const getFile = useAPILocal(fileAPI.getFileMiniature)

  useEffect(() => {
    if (images.length > 0)
      getFile.request(images[0]?.id).then(res => setPhoto(res?.data))
  }, [])

  return (
    <Card
      className={classes.root}
      onClick={() => gotoPhotoAndView(pictureIndex)}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            src={`data:image/jpeg;base64,${profilePic}`}
          >
            {userName ? userName.slice(0, 1).toUpperCase() : null}
          </Avatar>
        }
        title={userName || 'Anonymous '}
        subheader={role}
        style={{ borderBottom: '1px solid rgb(183, 183, 183,0.2)' }}
      />

      <CardContent>
        <Box>
          <Typography component="p" className={classes.comment}>
            {title}
          </Typography>
          {getFile.isPending ? (
            <Loading />
          ) : (
            <img
              src={photo || NoImage}
              style={{ width: '200px', height: '200px' }}
            />
          )}

          <Typography className={classes.content}>{comment}</Typography>
          <Typography className={classes.date}>
            {date ? new Date(date).toUTCString().substr(0, 16) : ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
