import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import Dashboard from './component/Dashboard/Dashboard'
import theme from './theme/index'

const BASEPATH = process.env.REACT_APP_HOMEPAGE || '/'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router basename={BASEPATH}>
        <Dashboard />
      </Router>
    </ThemeProvider>
  )
}

export default App
