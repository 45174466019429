import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { SecondaryTab, SecondaryTabs } from '../../../Reusable/SecondaryTabs'
import Container from '../../../Reusable/Container'
import Fixed from '../../../Reusable/Fixed'
import Scrollable from '../../../Reusable/Scrollable'
import TabPanel from '../../../Reusable/TabPanel'
import connectionsAPI from '../../../../api/connections'
import withPaginationLocal from '../../../../HOC/withPaginationLocal'
import ConnectionRequestList from './ConnectionRequestList'
import MyRequestsList from './MyRequestsList'

export default function ConnectionRequest() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const IncomingRequestList = useMemo(
    () =>
      withPaginationLocal(
        connectionsAPI.incomingRequests,
        {}
      )(ConnectionRequestList),
    []
  )

  const MyRequestList = useMemo(
    () =>
      withPaginationLocal(connectionsAPI.outgoingRequests, {})(MyRequestsList),
    []
  )

  return (
    <>
      <Container>
        <Fixed>
          <Box className="flex-row" justifyContent="space-between">
            <Box flex={1}>
              <SecondaryTabs value={value} onChange={handleChange}>
                <SecondaryTab label="Incoming Requests" />
                <SecondaryTab label="My Requests" />
              </SecondaryTabs>
            </Box>
          </Box>
        </Fixed>
        <Scrollable mt={2}>
          <TabPanel value={value} index={0}>
            <IncomingRequestList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MyRequestList />
          </TabPanel>
        </Scrollable>
      </Container>
    </>
  )
}
