import client from '../../hooks/useClient'

const locationAPI = {}

locationAPI.createBulkTank = async ({ blob_id, file_type }) => {
  return client.post(`/location.bulk.create`, { blob_id, file_type })
}

locationAPI.createNewLocation = async data => {
  return client.post(`/locations`, data)
}

locationAPI.updateLocation = async (locationId, data) => {
  return client.put(`/locations/${locationId}`, data)
}

locationAPI.getLocation = async locationId => {
  return client.get(`/locations/${locationId}`)
}

locationAPI.createLocation = async data => {
  return client.post(`/locations`, data)
}

locationAPI.updateLocation = async (locationId, data) => {
  return client.put(`/locations/${locationId}`, data)
}

locationAPI.deleteLocation = async locationId => {
  return client.delete(`/locations/${locationId}`)
}

locationAPI.list = async data => {
  return client.get(
    `/locations?filter=${data.filter !== undefined ? data.filter : ''}&search=${
      data.search !== undefined ? data.search : ''
    }&limit=${data.limit || 12}&page=${data.page || 0}&location_type=${
      data.location_type || 'tenant_location'
    }`
  )
}

export default locationAPI
