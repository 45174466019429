import { downloadFile } from './downloadQR'

let JsZip = null
const init = () => {
  const exists = [...document.scripts]
    .map(e => e.src)
    .includes('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.6.0/jszip.min.js')
  if (exists) {
    return
  }

  const script = document.createElement('script')
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.6.0/jszip.min.js'
  document.head.appendChild(script)
  script.onload = () => {
    JsZip = window.JSZip
  }
}
export const downloadZip = (files, config = { folder: 'data' }) => {
  if (!JsZip) return
  const zip = new JsZip()
  const img = zip.folder(config.folder)
  files.forEach(file => {
    img.file(file.name, file.data, { base64: true })
  })
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    const url = URL.createObjectURL(content)
    downloadFile(url, 'labels.zip')
  })
}

init()
