import React, { useState } from 'react'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useForm from '../../hooks/useForm'
import userAPI from '../../api/user'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import TextInput from '../Reusable/Widgets/TextInput'
import useAPILocal from '../../hooks/useAPILocal'
import useStateValue from '../../hooks/useStateValue'
import { Alert, Snackbar } from '@mui/material'

const ChangePassword = () => {
  const [error, setError] = useState('')
  const history = useHistory()
  const [{ user }] = useStateValue()
  const { user_info } = user
  const updatePassword = useAPILocal(userAPI.changePassword)

  const init = {
    newPassword: '',
    confirmPassword: '',
  }

  const validationSchema = {
    newPassword: ['required', 'password'],
    confirmPassword: ['required', 'password'],
  }

  const { errors, handleOnChange, values, handleSubmit } = useForm(
    init,
    validationSchema,
    async result => {
      if (result.newPassword !== result.confirmPassword) {
        setError('New password and confirm password does not match')
        return
      }
      updatePassword
        .request({
          userId: user_info?.user_id,
          newPassword: result.newPassword,
        })
        .then(response => {
          if (response?.ok) {
            history.goBack()
          }
        })
    }
  )

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title="Change Password" />
        </PageHeading>
      </Fixed>

      <Scrollable mt={2}>
        <Box display="flex">
          <Box flex="20%"></Box>
          <Box flex="80%" height="100%">
            <Box overflow="auto" height="100%"></Box>
            <Box flex="1" mr={2} px={1} pb={2} overflow="auto" height="100%">
              <Box display="flex" className="form-row">
                <TextInput
                  flex="0.4"
                  label="Enter New Password"
                  name="newPassword"
                  type="password"
                  {...additionalProps('newPassword')}
                />
                <TextInput
                  flex="0.4"
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  {...additionalProps('confirmPassword')}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={error}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">{error || 'Something went wrong'}</Alert>
        </Snackbar>

        <Snackbar
          open={updatePassword.isResolved}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => updatePassword.reset()}
        >
          <Alert onClose={() => updatePassword.reset()} severity="success">Password updated successfully</Alert>
        </Snackbar>
      </Scrollable>

      <Fixed>
        <Box pb={5} pr={1} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={updatePassword.isPending}
          >
            {updatePassword.isPending ? (
              <CircularProgress size={20} mode="indeterminate" color="white" />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Fixed>
    </Container>
  )
}

export default ChangePassword
