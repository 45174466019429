import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Box, Typography, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import chemicalAPI from '../../api/chemical'
import routes from '../../routes/route_contants'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import { PaletteColors } from '../../config/colors'

import { formatContactNo, formatLocation } from '../../helpers/util'
import TableHead from '../Reusable/TableHead'
import TableBody from '../Reusable/TableBody'
import Loading from '../Order/Tabs/Loading'
import useAPILocal from '../../hooks/useAPILocal'

const fields = [
  { label: 'Chemical Name', value: e => e?.name },
  { label: 'UN Number', value: e => e?.unNumber },
  { label: 'Hazard Class', value: e => e?.hazardClass },
  // { label: 'Extra Fields', value: e => e?.extraFields },
]

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

const columns = [
  {
    label: 'Tank No./Name',
    value: e => e.tankRegistrationNo,
  },
  {
    label: 'Parnter Details',
    value: e => (e.customer ? e.customer.customer_name : '-'),
  },
  {
    label: 'Location',
    value: e =>
      formatLocation(
        e?.location,
        'location_name postal_address city country postal_code'
      ),
  },
  {
    label: 'Contact No.',
    value: e =>
      e.location?.phone_no ? formatContactNo(e.location?.phone_no) : '-',
  },
]

export default function ChemicalDetailsPage() {
  const [chemicalInfo, setChemicalInfo] = useState([])
  const history = useHistory()
  const path = useParams()
  const chemicalInfoApi = useAPILocal(chemicalAPI.info)
  const chemicalDeleteApi = useAPILocal(chemicalAPI.delete)
  const getChemicalInfo = () => {
    chemicalInfoApi.request(path.id).then(res => setChemicalInfo(res.data))
  }

  const editChemicalInfoData = () => {
    history.push({
      pathname: `${routes.CHEMICAL_ADD}`,
      state: {
        mode: 'update',
        details: chemicalInfo,
      },
    })
  }

  const deleteChemical = () => {
    chemicalDeleteApi
      .request(path.id)
      .then(data => {
        if (data.status <= 201) {
          setTimeout(() => {
            history.push(routes.CHEMICAL_LIST)
          }, 1000)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (path.id) getChemicalInfo()
  }, [path.id])

  if (chemicalInfoApi.isPending) {
    return <Loading fullScreen />
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title={chemicalInfo?.name} />
          <Box className="flex-row">
            <Button
              variant="outlined"
              color="primary"
              onClick={editChemicalInfoData}
            >
              Edit Details
            </Button>
            <Box mr={1} />
            <Button variant="outlined" color="error" onClick={deleteChemical}>
              Delete Chemical
            </Button>
          </Box>
        </PageHeading>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="productbox">
            <div className="productboxin">
              {chemicalInfoApi.isPending ? (
                <Loading m={4} />
              ) : (
                <>
                  {fields.map(field => (
                    <Box key={field.label}>
                      <Item
                        label={field.label}
                        value={field.value(chemicalInfo)}
                      />
                    </Box>
                  ))}
                  {Object.entries(chemicalInfoApi?.data?.extraFields || {}).map(
                    field => (
                      <>
                        <Box key={field.label}>
                          <Item label={field[0]} value={field[1]} />
                        </Box>
                      </>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
        <div style={{ marginTop: '50px', marginBottom: '24px' }}>
          <div className="containingtanks">Containing Tanks</div>
        </div>
        <TableHead columns={columns} />
        {chemicalInfo?.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={chemicalInfo?.tanks}
            rowOnClick={row =>
              history.push(
                `/tanks&products/tank-details/${row.tankRegistrationNo}`
              )
            }
          />
        )}
      </Scrollable>
      <Snackbar
        open={chemicalDeleteApi?.isRejected}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {Object.values(chemicalDeleteApi?.error || {})}
        </Alert>
      </Snackbar>
      <Snackbar
        open={chemicalDeleteApi?.isResolved}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success"> Chemical deleted succesfully </Alert>
      </Snackbar>
    </Container>
  )
}
