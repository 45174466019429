import client from '../../hooks/useClient'

const chemicalAPI = {}

chemicalAPI.newList = async (params = {}) => {
  return client.get(`/chemicals/list`, { ...params })
}

chemicalAPI.basic = async (params = {}) => {
  return client.get(`/chemicals/basic`, { ...params })
}

chemicalAPI.newCreate = async data => {
  return client.post(`/chemicals/create`, { ...data })
}

chemicalAPI.update = async (chemicalId, params = {}) => {
  return client.patch(`/chemicals/${chemicalId}`, { ...params })
}

chemicalAPI.info = async chemicalId => {
  return client.get(`/chemicals/${chemicalId}`)
}

chemicalAPI.delete = async chemicalId => {
  return client.delete(`/chemicals/${chemicalId}`)
}

export default chemicalAPI
