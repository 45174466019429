


import { Box, Button, Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import PageTitle from '../Reusable/PageTitle'
import PageHeading from '../Reusable/PageHeading'
import TabPanel from '../Reusable/TabPanel'

import Explore from './Tabs/Explore'
import ConnectionRequest from './Tabs/ConnectionRequest'
import InviteCompanyModal from './Tabs/Explore/InviteCompanyModal'
import MyCompany from './Tabs/MyCompany'
import PartnerList from './Tabs/Partners/PartnerList'

const tabs = [
  {
    label: 'Partners',
    hash: '#partners',
  },
  {
    label: 'Explore',
    hash: '#explore',
  },
  {
    label: 'My Company',
    hash: '#my-company',
  },
  {
    label: 'Connection Request',
    hash: '#requests',
  },
]

function Connections(props) {
  const params = useParams()
  const [value, setValue] = React.useState(0)

  const history = useHistory()

  useEffect(() => {
    const currentTab = tabs.findIndex(e => e.hash === window.location.hash)
    if (currentTab !== -1) setValue(currentTab)
  }, [window.location.hash])

  // let url
  useEffect(() => {
    const currentTab = tabs.findIndex(e =>
      window.location.hash.startsWith(e.hash)
    )
    if (currentTab !== -1) setValue(currentTab)
  }, [params.order_id])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [isOpenInvitation, setIsOpenInvitation] = useState(false)

  const toggleModalInvitation = isInvite => () => {
    setIsOpenInvitation(!isOpenInvitation)
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Connections" />
          {value === 1 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsOpenInvitation(true)}
              >
                Invite Partner
              </Button>
            </Box>
          )}
        </PageHeading>
        <Box mt={2}>
          <Tabs value={value} onChange={handleChange} indicatorColor="white">
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                onClick={() => history.push(tab.hash)}
                label={tab.label}
                style={
                  index === value
                    ? {
                        color: 'white',
                        backgroundColor: '#3298FF',
                        height: '100%',
                        borderRadius: '30px',
                      }
                    : {}
                }
              />
            ))}
          </Tabs>
        </Box>
      </Fixed>
      <Scrollable mt={2}>
        <TabPanel value={value} index={0}>
          <PartnerList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Explore />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MyCompany />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ConnectionRequest />
        </TabPanel>
      </Scrollable>

      <InviteCompanyModal
        isOpen={isOpenInvitation}
        toggleModal={toggleModalInvitation}
      />
    </Container>
  )
}

export default Connections
